﻿.dealer-rates-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    min-height: 900px;



    .dealer-rates {
        background-color: #e6e6e6;
        margin: 0px;
        padding: 10px;
        font-size: 14px;
        min-height: 900px;


        #gridDataEntryRateMarkUps th.k-header.align-right {
            text-align: center;
        }

        #gridDataEntryOverAllowance th.k-header.align-right {
            text-align: center;
        }

        #gridDataEntryUsedRates th.k-header.align-right {
            text-align: center;
        }


        .tab-help-label {
            color: #7A8084;
            margin-left: 15px;
        }

        .k-detail-row {
            background-color: #a6d1ee;
        }
        /* https://docs.telerik.com/kendo-ui/third-party/using-kendo-with-twitter-bootstrap */
        .kendo-angularjs {
            /* reset everything to the default box model */
            *, :before, :after {
                -webkit-box-sizing: content-box;
                -moz-box-sizing: content-box;
                box-sizing: content-box;
            }
            /* set a border-box model only to elements that need it */
            .form-control, /* if this class is applied to a Kendo UI widget, its layout may change */
            .container,
            .container-fluid,
            .row,
            .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
            .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
            .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
            .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
            .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
            .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
            .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
            .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
            .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
            .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
            .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
            .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            /* reset everything to the default box model */
        }

        .nounderline {
            text-decoration: none;
        }

        .noRecordsAvailable {
            font-size: small;
        }

        .k-grid-norecords {
            margin-top: 20px;
        }


        .tab-header {
            margin-left: 15px;
        }

        .edit-box {
            border: 1px solid #ccc;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            height: 20px;
            font-size: 12pt;
            padding: 2px 8px;
            outline: 0;
            text-align: right;
            -webkit-appearance: none;
        }

        .edit-box-error {
            border: 1px solid red;
            -moz-border-radius: 10px;
            -webkit-border-radius: 10px;
            border-radius: 10px;
            height: 20px;
            font-size: 12pt;
            padding: 2px 8px;
            outline: 0;
            text-align: right;
            -webkit-appearance: none;
        }
    }
}
