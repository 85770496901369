﻿@import '../../styles/variables';

$font-size: 11pt;
$frame-background-color: #E7E7E7;

.admin-report-subscription-wrapper {
    font-size: $font-size;
    background-color: $frame-background-color;
    padding-left: 15px;
    padding-right: 15px;
    height: calc(100vh - 190px);
    min-height: 500px;

    .page-title {
        margin: 15px 0px 0px 5px;
        padding: 10px 0 0 10px;
        font-size: 18pt;
        color: #444B4F;
        float: left;
    }

    icon.-small svg {
        margin-bottom: 5px;
    }

    .admin-report-subscription {
        margin: 25px 0 0 0;
        min-height: 320px;

        .panel-heading {
            padding: 7px 15px;
        }

        .pando-group-selector-group {
            min-width: 270px;
        }

        .pando-group-selector-group > .pando-group-selector {
            margin-left: 3px;
        }

        .report-name {
            margin-top: 8px;
            margin-left: 40px;
        }

        .-height36 {
            height: 36px;
        }

        .form-control {
            display: inline;
            width: auto;
        }


        .pando-group-selector-error {
            border-color: red !important;
        }

        .information-tip {
            font-weight: 100;
            font-size: 13px;
        }
    }

    label, .form-control {
        font-size: $font-size;
    }

    .table > tbody > tr > th,
    .table > tbody > tr > td {
        text-align: center;
        border-top: 0px;
    }


    .float-right {
        float: right;
        padding-right: 15px;
    }

    .padding-left {
        padding-left: 20px;
    }

    .margin-left {
        margin-left: 10px;
    }

    .page-info {
        /*margin: 15px 0px 0px 15px;*/
    }

    .title-dealer {
        color: black;
        margin-top: 18px;
        float: left
    }

    .dealer-select {
        padding: 10px 0 0 10px;
        float: left
    }

    hr {
        border-top: 1px solid #E7E7E7;
    }

    hr:not(.margin-top) {
        margin-top: 10px;
    }

    .padding-bottom {
        padding-bottom: 10px;
    }

    .error {
        color: Red !important;
    }
}
