﻿
.redactor_toolbar li a.re-button {
    background-image: url('~/images/insert-button.png');
}
.redactor_toolbar li a.re-button:hover { 
    background-image: url('~/images/insert-button-hover.png');
}

.re-icon.re-layout.redactor-btn-image {
    background-image: url('~/images/insert-layout.png');
}

.re-icon.re-layout.redactor-btn-image:hover { 
    background-image: url('~/images/insert-layout-hover.png');
}