﻿
x-data-entry-prices {

    .prices-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    input.ng-invalid.ng-dirty {
        border-color: #cccccc;
    }


    #gridDataEntryPrices th.k-header.align-center {
        text-align: center;
    }

    #gridDataEntryPrices th.k-grid-content.align-center {
        text-align: center;
    }
}
