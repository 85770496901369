﻿$font-size: 11pt;
$frame-background-color: #E7E7E7;

@import '../../styles/aa-variables';

@mixin svg($url, $width: 1em, $height: $width) {
    background: url($url) no-repeat #337ab7;
    width: $width;
    height: $height;
    display: inline-block;
    line-height: 1;
}

.admin-settings-wrapper {
    font-size: 10pt !important;
    background-color: $frame-background-color;
    padding: 15px;

    .single-line-control {
        float: left;
        margin-right: 10px;
    }

    .override-state {
        width: 200px;
    }

    .override-state-label {
        margin: 5px 10px 0 0;
    }

    input[type="checkbox"] {
        margin-bottom: 1px;
        vertical-align: middle;
    }

    input[type="radio"] {
        margin-bottom: 3px;
        vertical-align: middle;
    }

    input {
        border: solid 1px #ccc;
    }

    label {
        color: #444a50;
        font-size: 12px;
    }

    select {
        font-size: 12px !important;
        height: 29px;
    }

    .textbox {
        font-size: 12px;
        height: 22px;
    }

    .textboxSm {
        font-size: 12px;
        height: 22px;
        width: 80px;
    }

    .textboxMed {
        font-size: 12px;
        height: 22px;
        width: 80px;
    }

    .admin-settings {
        margin: 15px 15px 10px 15px;
        background-color: white;
        border-radius: 10px 10px;
        min-height: 320px;
    }

    .adminSettingsTabContainer {
        margin-top: 5px;
        padding: 0 5px 0 5px;
    }

    .form-control {
        font-size: 14px;
    }

    .settingHeader {
        font-weight: bold;
        color: #444a50;
        margin-bottom: 5px;
    }

    .settingTitle {
        clear: both;
        font-weight: bold;
        margin: 10px 0 0 20px;
        float: left;
    }

    .settingItem {
        clear: both;
        padding: 10px 0 0 35px;
    }

    .settingCheckboxText {
        margin-left: 10px;
    }

    .actionButtonContainer {
        clear: both;
        height: 35px;
        margin-bottom: 5px;
    }

    .bold {
        font-weight: bold
    }
    /* MultiSelect Styles */
    .c-btn {
        background: #fff;
        border: 1px solid #ccc;
        color: #333;
        font-size: 12px;
    }

    .selected-list .c-btn {
        padding: 5px;
    }

    .selected-list .c-list .c-token {
        background: #337ab7;
    }

    .selected-list .c-list .c-token .c-label {
        color: #fff;
    }

    .selected-list .c-list .c-token .c-remove svg {
        fill: #fff;
    }

    .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
        fill: #333;
    }

    .dropdown-list ul li {
        padding: 5px 0 0 10px;
    }

    .dropdown-list ul li:hover {
        background: #f5f5f5;
    }

    .arrow-up, .arrow-down {
        border-bottom: 15px solid #fff;
    }

    .arrow-2 {
        border-bottom: 15px solid #ccc;
    }

    .list-area {
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: 0px 1px 5px #959595;
    }

    .select-all {
        border-bottom: 1px solid #ccc;
    }

    .list-filter {
        border-bottom: 1px solid #ccc;
    }

    .list-filter .c-search svg {
        fill: #888;
    }

    .list-filter .c-clear svg {
        fill: #888;
    }

    .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
        border-color: #337ab7;
        background-color: #f2f2f2;
    }

    .pure-checkbox input[type="checkbox"] + label {
        color: #000;
    }

    .pure-checkbox input[type="checkbox"] + label:before {
        color: #337ab7;
        border: 2px solid #337ab7;
    }

    .pure-checkbox input[type="checkbox"] + label:after {
        background-color: #337ab7;
    }

    .pure-checkbox input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc;
    }

    .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc;
    }

    .pure-checkbox input[type="checkbox"] + label:after {
        border-color: #ffffff;
    }

    .pure-checkbox input[type="radio"]:checked + label:before {
        background-color: white;
    }

    .pure-checkbox input[type="checkbox"]:checked + label:before {
        background: #337ab7;
    }

    .selected-item {
        background: #e9f4ff;
    }

    .btn-iceblue {
        background: #337ab7;
        border: 1px solid #ccc;
        color: #fff;
    }

    .btn-up {
        margin-top: 5px;
        @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-chevron-up-vanilla.svg', 20px, 20px);
    }

    .btn-down {
        margin-top: 5px;
        @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-chevron-down-vanilla.svg', 20px, 20px);
    }

    .invalid {
        border-color: #ff0000;
    }

    .tooltipContainer p {
        font-size: 12px;
        float: left;
    }

    .popoverContainer {
        min-width: 400px;
    }

    .userManagement {
        .popoverContainer {
            min-width: 450px;
        }
    }

    .creditConvertFilters, .crmLeadsDelivery, .crmLeadOpportunity {
        .popoverContainer {
            min-width: 500px;
        }
    }

    .tooltipContainer ul {
        list-style: none;
        padding-left: 0;
    }

    .tooltipContainer ul li:not(:last-child) {
        margin-bottom: 8px;
    }

    .hidden {
        display: none;
    }

    .c-btn {
        border-radius: 20px;
        display: inline-block;
        overflow: hidden;
        border: 1px solid #cccccc;
    }

    .selected-list .c-angle-down, .selected-list .c-angle-up {
        width: 10px;
        height: 10px;
    }

    .icon-class svg, icon svg {
        width: 12px;
        height: 12px;
    }

    .sectionSpacer {
        height: 20px;
    }

    .smallButton {
        font-size: 12px;
    }
}

.modal-wrapper {
    // modal styles
    .modal-header {
        background: linear-gradient(to right, #172751, #1f8bd5) fixed;
        color: #ffffff;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left;
        margin: 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: block;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        padding: 10px;
    }

    .invalidField {
        color: Red;
        margin-left: 0px
    }

    .sourceSelectContainer {
        margin-top: 10px
    }

    .startWithDefaultLabel {
        margin-left: 6px;
    }

    .startWithParentContainer {
        margin-top: 10px
    }

    .fieldInvalid {
        border-color: red;
    }

    .close {
        //color: #FFF !important;
        color: #FFF;
        opacity: 1;
    }

    .popoverHeader {
        font-weight: bold
    }

    .tooltipContainer {
        float: left;
    }

    .txtEmail {
        width: 320px;
    }

    .sourceSelect {
        width: 220px;
        height: 32px
    }

    label {
        color: #444a50
    }

    .vhrDisclaimer p {
        font-size: 14px;
    }

    .vhrDisclaimer {
        width: auto;
        min-height: 0px;
        height: 200px;
        overflow-x: hidden;
    }
}