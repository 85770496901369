﻿@import "../../styles/aa-variables";

.col-sm-6.no-padding {
    padding: 0;
}

deal-sheet {
    .rounded-top-left {
        border-top-left-radius: 10px;
     }
    .rounded-top-right {
        border-top-right-radius: 10px;
    }
    .rounded-bottom-left {
        border-bottom-left-radius: 10px;
    }
    .rounded-bottom-right {
        border-bottom-right-radius: 10px;
    }
    facebook-profile-action {
        display: inline-block;
        vertical-align: top;
        float: right;
        margin-right: 125px;
    }
}

.user-avatar {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-size: 24px 27px;
    background-position: center center;
    vertical-align: middle;
    line-height: 24px;
    box-shadow: inset 0 0 1px #999, inset 0 0 10px rgba(0,0,0,.2);
    align-self: flex-end;
}

.user-avatar.no-user-avatar {
    background-image: url('/images/avatar.png');
    background-size: 24px 27px;
    background-position: center center;
}

.process-status {
    font-weight: bold;
    text-transform: uppercase;
}

.process-status-new {
    color: $brightBlue; // Lake
}

.process-status-in-progress {
    color: $navyBlue; // OceanDeep
}

.process-status-completed {
    color: $appointment-color; // Lime
}

.process-status-overdue {
    color: #ED2124; // Apple
}

.process-status-waiting {
    color: $labelColor; // Steel
}

.process-highlight {
    color: $brightBlue; // Steel
}

.process-bold {
    font-weight: bold;
}


.alert-active {
    background: $light-grey;

    .aa-icon-arrowfilledin {
        display: block !important;
    }

    .aa-icon-arrowoutline {
        display: none;
    }
}

.alert-wrapper {
    padding: 5px;
}

.disclaimer {
    color: red;
    font-size: 10px;
    margin-top: 5px;
}

.header-modal {
    z-index: 100000 !important;
}

strong {
    font-weight: 700 !important;
}

.dsowner {
    background: rgba(91, 192, 222, 0.17);
    display: inline-block;
    width: 100%;
}

customer, dealsheet, trim-select, stock-vehicle, sale-type-select, monthly-mileage {
    display: block;
}

delivery-monthly-mileage monthly-mileage {
    display: inline;
}

.replacement-contract-details {
    .text-uppercase {
        display: block;
        margin-bottom: 5px;
    }

    sale-type-select {
        margin-bottom: 10px;
    }

    trim-select, stock-vehicle {
        margin: 10px 0;
    }

    msrp-type-select label, sale-type-select label {
        margin-right: 10px;
    }

    .field {
        // width: 200px;
        margin-right: 10px;
    }

    .msrp, .payments {
        margin-right: 20px;
    }
}

alerts, tags {
    display: inline-block;
}

/*alerts icon {
    margin-right: 5px;
}*/

.current-contract-summary-header money-factor {
    margin-right: -3px;

    .field {
        display: inline;
    }
}

/*current-contract-summary label::after {
    content: ":"
}*/

span.inline.right {
    margin-right: 3px;
}

span.inline.left {
    margin-left: 3px;
}

// talk over with Robert
.ds-tab-body {
    min-height: 365px !important;
    background-color: white;
}

.inline .field {
    display: inline-block;
}

.current-contract-details .inline.left {
    // margin-left: 3px;
}

.current-contract-details label {
    margin-bottom: 0;
}

.base-fuel {
    color: $body-base-color;
}

.down-fuel {
    color: $service-color;
    &.h5 {
        color: $service-color;
    }
}

.up-fuel {
    color: $body-base-color;
}

.alert:not(.over-allowance) {
    color: red;
}

.down-fuel span:hover,
.up-fuel span:hover,
.base-fuel span:hover {
    text-decoration: underline;
}

.service, .appointment {
    color: $service-color;
}

.mileage {
    color: $mileage-color;
}

.flex:not(.over-allowance) {
    color: $flex-alert-color;
}

.over-allowance {
    background-color: $SectionBorder;
}

.warning-text {
    color: red;
    font-weight: bold;
}

.contract-end {
    color: $contract-end-color;
}

.warranty, .warranty {
    color: $warranty-color;
}

.current-contract-details {
    .edit-icon {
        position: relative;
        top: 1px;
    }

    span.adjustment {
        font-weight: normal;
    }
}

annual-percentage-rate.inline .field label {
    color: inherit;
    text-align: left;
    width: auto;
    margin-right: 0;
    text-transform: none;
    margin-bottom: 0;
}

.ds-header {
    // This variable determines when the header breaks from side-by-side to stacked
    $header-breakpoint: $sm-breakpoint;
    background: transparent;

    icon.icon {
        color: $brightBlue;
    }

    .ds-user-name {
        position: relative;
        width: 100%;
        overflow: hidden; // Hides the overflow of .angled-edge

        // Sometimes the browser decides to round the bottom edge of this
        // element UP by 1px, so there's a transparent line across the
        // entire deal sheet. Moving this element down 1 px prevents that.
        // I hate this solution, but it seems to work.
        bottom: -1px;

        @media only screen and (min-width : $header-breakpoint) {
            display: table;
            table-layout: fixed;
        }

        .ds-user-name-row {

            @media only screen and (min-width : $header-breakpoint) {
                display: flex;
                flex-flow: row wrap;
                height: 100%;
            }
        }

        .ds-header-left {
            @media only screen and (min-width : $sm-breakpoint) {
                vertical-align: top;
                min-height: 80px;
                width: 40%;
            }
            @media only screen and (min-width : $md-breakpoint) {
                width: 35%;
            }
            @media only screen and (min-width : $lg-breakpoint) {
                width: 30%;
            }

            width: 100%;
            position: relative;
            background: $body-bg;
            background-color: #ffffff;
            padding: 10px 7px 11px 22px;
            isolation: isolate;

            @media only screen and (min-width : $header-breakpoint) {
                padding-bottom: 15px;
            }

            .contents {
                // This block exists to have a z-index greater than that
                // of .angled-edge so the text doesn't get clipped.
                position: relative;
                z-index: 2;
                width: 67%;
            }

            .angled-edge-container {
                .angled-edge {
                    position: absolute;
                    right: 0;
                    top: 0;
                    bottom: -2px; // Due to rounding fractional pixels (especially in IE11) this block tends to render a few pixels too short
                    width: 100px;
                    transform-origin: top;
                    transform: skewX(20deg);
                    @media only screen and (min-width : $md-breakpoint) {
                        width: 128px;
                    }
                }
                .score-label-placeholder {
                    width: 30px;
                    background-color: $dark-label-background;
                    height: 100%;
                }
                .score-label {
                    display: inline-block;
                    transform: rotate(69deg);
                    position: absolute;
                    top: 28px;
                    right: 48px;
                    color: white;
                    @media only screen and (min-width : $md-breakpoint) {
                        right: 76px;
                    }
                }
                .score-value-placeholder {
                    position: absolute;
                    left: 28px;
                    width: 80px;
                    background-color: $brightBlue;
                    height: 100%;
                    @media only screen and (min-width : $md-breakpoint) {
                        width: 100px;
                    }
                }
                .score-value-container {
                    width: 100px;
                    height: 100%;
                    display: inline-block;
                    text-align: center;
                    position: absolute;
                    top: 0;
                    right: -32px;
                    @media only screen and (min-width : $md-breakpoint) {
                        right: -12px;
                    }
                }
                .score-value {
                    color: white;
                    font-size: 44px;
                    font-weight: bold;
                    line-height: 80px;
                }
            }

            .customer-name {
                color: $brightBlue;
                margin: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;

                @media only screen and (min-width : 768px) {
                    max-width: 125px;
                }

                @media only screen and (min-width : 1024px) {
                    max-width: 105px;
                }

                @media only screen and (min-width : 1224px) {
                    max-width: 155px;
                }

                @media only screen and (min-width : 1424px) {
                    max-width: 205px;
                }

                @media only screen and (min-width : 1624px) {
                    max-width: 255px;
                }

                @media only screen and (min-width : 1824px) {
                    max-width: 305px;
                }

                &:not(.suffix) {
                    padding-bottom: 2px;
                }
            }

            .customer-name-display {
                display: inline-block;
            }

            .dealer-name {
                color: $brightBlue;
                font-size: 12px;
                font-weight: normal;
                margin: 0;
            }
        }

        .ds-header-right {
            @media only screen and (min-width: $sm-breakpoint) {
                vertical-align: top;
                width: 60%;
            }
            @media only screen and (min-width: $md-breakpoint) {
                width: 65%;
            }
            @media only screen and (min-width: $lg-breakpoint) {
                width: 70%;
            }

            width: 100%;
            margin-top: 12px;
            padding-top: 3px;
            padding-bottom: 3px;
            background: -webkit-linear-gradient(to top, #192c54 0%,$light-blue 100%);
            background: linear-gradient(to top, #192c54 0%,#192c54 100%);
            background: linear-gradient( $light-blue, #192c54);
            color: #fff;

            .group-row {
                padding: 2px;
                padding-left: 27px; // avoid clipping the slanted edge
                margin: 5px 0 0 0;
            }

            .ds-owner {
                text-align:center;
                position: relative;

                .owner-name {
                    cursor: pointer;
                }

                .owner-name.read-only {
                    cursor: auto;
                }

                .header-modal-style {
                    position: absolute;
                    top: 17px;
                    left: 14px;
                    z-index: 2;
                }

                .active-users {
                    margin: 0;
                    list-style: none;
                    background: #fff;
                    padding: 5px;
                    overflow-y: scroll;
                    max-height: 235px;

                    li {
                        .currently-selected-owner {
                            background: $light-grey;
                            display: inline-block;
                            width: 100%;
                        }

                        span {
                            color: $aTagColor;
                            cursor: pointer;

                            &:hover {
                                background: $light-grey;
                                display: inline-block;
                                width: 100%;
                            }
                        }
                    }
                }

                .button-span {
                    text-align: right;
                }

                .process-claim-info {
                    
                    font-size:16px;
                    font-weight:normal;
                    .item-gap {
                      margin-right:30px;
                    }
                    
                    .item-gap-small {
                        margin-right:7px;
                    }



                }


            }

            .deal-sheet-status {
                display: block;

                .ds-status {
                    text-transform: capitalize;
                }

                a.send {
                    border-bottom-right-radius: 10px !important;
                    border-top-right-radius: 10px !important;
                    border-radius: 0;
                    padding: 3px 5px;
                }

                .dropdown {
                    display: inline-block;

                    .dropdown-toggle {
                        border-bottom-left-radius: 10px;
                        border-top-left-radius: 10px;
                        color: #0099ff;
                        background: #fff;
                        border: none;
                    }
                }
            }
        }
    }



    .ds-user-info {
        background: $body-bg;
        padding: 0px;
        margin: 0;
        color: $body-base-color;
        

        &.row {
            margin: 0;
        }

        &.accordian-tab-layout {
            text-align:right;
            height:32px;
            padding-right:10px;
            padding-bottom: 5px;
        }

        div.panel-group {
            padding: 0px;
            background-color: red;
            border: 0px;
            margin: 0px;
        }

        .panel {
            padding-bottom: 0px;
        }

        .panel-heading {
            padding: 0px;
            background-color: green;
            border: 0px;
            margin: 0px;
        }

        ul.customer-dropdown {
            margin: 0;
            padding: 0;
            list-style: none;
            display: inline-block;
            position: relative;

            .caret {
                cursor: pointer;
            }

            li {
                background: #fff;
                position: absolute;
                right: 11px;
                top: 15px;
                padding: 2px;
                z-index: 1;
                border: 1px solid $light-grey;
                box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
            }
        }

        a {
            color: $brightBlue;
            display: inline-block;
            font-weight: 500;
        }

        .work-phone {
            color: red;
        }

        .pipes {
            padding: 0 4px;
        }

        .tags {
            margin-left: 10px;
        }

        .customer-contact-group {
           padding-top:0px;
        }
    }



    .ds-customer-connect {
        background: $body-bg;
        border-bottom: 0px #1f8bd5 solid;
        border-top: 0px #1f8bd5 solid;
        padding: 0px;
        margin: 0;
        color: $body-base-color;
        z-index: 100000;


        span {
            color: gray;

            &.error {
                color: red;
            }
        }

        label {
            color: $brightBlue;
            font-size: 16px;
        }

        div.panel-body {
        }

        div.panel-group {
            border-top: 10px red solid;
            padding: 0px;
            border: 0px;
            margin: 0px;
        }

        div.row {
            margin: 0;
        }

        .panel {
            padding-bottom: 0px;
            border: 0px;
        }

        .panel-heading {
            padding: 0px;
            border: 0px;
            margin: 0px;
        }

        .panel-body {
            padding: 5px;
            border: 0px;
            margin: 0px;
            border-top: 2px $brightBlue solid;
        }

        .action-bar {
            background-color:#1f8bd5;
            color: #ffffff;
            font-weight: 500;
            vertical-align:middle;
        }

        .action-menu {
            border-bottom: 1px $gray-lighter solid;
            padding: 10px;
            padding-bottom: 0px;
        }

        .selectedbar {
            border-bottom: 4px solid #008be8;
        }

        .timeTicker {
            margin-top: 35px;
        }

        .bottom-column
        {
            margin-top: 50px;
            /*vertical-align: bottom;*/
        }

        .bottom-column-schedule
        {
            margin-top: 100px;
            /*vertical-align: bottom;*/
        }

        .top-buffer {
            padding-top: 6px;
        }

        .comm-tools {
            display: inline-block;
            margin-left:15px;
            icon.icon {
                top: 0;
            }

            &:hover {
                .caption {
                    text-decoration: none;
                }
            }

            .wide-tools {
                padding-right: 0px;
                color: $brightBlue;

                input {
                    vertical-align: middle;
                }

                label {
                    color: $brightBlue;
                    font-size: .85em;
                    margin-bottom: 0px;
                }
            }            
        }

        .do-not-contact-options {
            vertical-align: middle;
        }
    }
}

.dealsheet-body {
    background: $body-bg;
    //border-bottom-left-radius: 10px;
    .contract-header {
        border-left-width: 30px;
        border-left-style: solid;
        border-left-color: $brightBlue;

        @media only screen and (max-width : 1024px) {
            height: auto;
        }
    }

    .hot-trade-text {
        vertical-align: text-top;
        font-size: large;
    }

    .contract {
        flex: 0 1 auto;
        //border: 1px solid pink;
        //font-size: 13px;
        a {
            img {
                width: auto;
                height: 17px;
                margin: 10px 10px 5px 0;
            }
        }

        .car-fax-image {
            img {
                width: auto;
                height: 17px;
                margin: 10px 10px 5px 0;
            }
        }

        img {
            float: left;
            width: 150px;
            margin: 0 10px 13px 0;
        }

        .oasis {
            img {
                margin-bottom: 0;
            }
        }

        .vehicle-info {
            margin-bottom: 18px;
            min-height: 92px;
        }

        .contract-info {
            margin-bottom: 18px;
        }

        .vehicle-info {
            .used {
                color: $usedOrange;
            }

            .cpo {
                color: $usedOrange;
            }
        }

        .type-label {
            line-height: 18px;
        }

        .bold {
            font-weight: bold;
        }

        .new {
            color: $brightBlue;
        }

        .used {
            color: $usedOrange;
        }

        .conquest {
            color: $service-color;
        }

        .alert {
            padding: initial;
            border: initial;
            border-radius: initial;
            color: red;

            // .alert is a class from Bootstrap, but we aren't trying to use it.
            // Add this class to disable the Bootstrap styles.
            &.alert-inline-only {
                padding: inherit;
                margin: inherit;
            }
        }

        .clickable {
            cursor: pointer;
        }

        .edit-icon {
            position: relative;
            top: 1px;
        }
    }

    .carcomparison {
        display: flex;
        flex-flow: row wrap;
        border-top: 6px solid $brightBlue;
        border-bottom: 8px solid $brightBlue;

        > .carcomparisonie11flexfix {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: auto;

            @media only screen and (max-width : 1024px) {
                width: 100%;
                // height: auto;
            }
        }

        .current-contract-summary, .replacement-contract-summary {
            width: 100%;
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: stretch;
        }

        &.row {
            margin: 0;
        }

        .new-header {
            border-left-color: $brightBlue;
        }

        .used-header, .cpo-header {
            border-left-color: $usedOrange;
        }

        .conquest-header {
            border-left-color: $service-color;
        }
    }

    .ds-tab-area {
        // border-top: 10px $brightBlue solid;
        /*height: 400px;
        overflow-y: scroll;*/
        // talk over with Robert
        &.row {
            margin: 0;
            background-color: #002b49;
            border-bottom-left-radius: inherit;
        }

        .ds-tab-nav {
            padding-left: 0;
            padding-right: 0;
            background: $navyBlue;

            li {
                margin-top: 0;
                //border-bottom: 1px solid $brightBlue;
            }

            .nav > li {
                display: block;
            }

            a {
                color: #fff;
                border-bottom: transparent 5px solid;
                font-size: 16px;

                &:hover {
                    color: #c1c2c4 !important;
                    background: -webkit-linear-gradient(to bottom, #192c54 0%,$light-blue 100%);
                    background: linear-gradient(to bottom, #192c54 0%,#192c54 100%);
                    background: linear-gradient(#192c54, $light-blue);
                    border-bottom: #2d89c9 5px solid;
                }

                &.tab-active {
                    background: #fff;
                    color: #0099ff;
                }
            }
        }
    }
}
.larger-number {
    font-size: 22px;
    font-weight: bold;
    color: $service-color;
}
.dealsheet-toggle a {
    color: #fff;
    position: absolute;
    top: 0;
    right: 16px;
    z-index: 0;
    text-decoration: none;

    &:hover, &:visited, &:focus {
        color: #fff;
    }
}

.do-no-call {
    color: red;
    text-decoration: line-through;
}

.modal-tools {
    text-align: right;

    icon.icon {
        color: #fff;
        top: 0;
        font-size: 25px;
        margin-left: 20px;
    }

    icon.icon.aa-icon-core-print-icon-000000 {
        font-size: 30px;
        top: 1px;
    }
}

.comm-tools {
    display: inline-block;

    icon.icon {
        top: 0;
    }

    &:hover {
        .caption {
            text-decoration: underline;
        }
    }

    .wide-tools {
        padding-left: 10px;
        padding-right: 10px;
        color: $brightBlue;

        label {
            color: $brightBlue;
        }
    }

    .accordian-tabs {
        height: 30px;
        margin-left: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        background-color: #1f8bd5; /*#1f8bd5;*/
        &:hover {
        }

        &.icon-only {
            padding-left:8px;
            padding-right:8px;
        }
    }

    .badge {
        color:#ffffff;
    }

    .unread-message-badge {
        background-color:red;
        margin-left:-8px;
        margin-top:6px;
        font-size:7pt;
    }

    .svg-icon {
        margin-bottom: 9px;
        padding:0px;

    }
}

.opportunities {
    list-style: none;
    padding-left: 5px;
    max-width: 385px;

    .opportunity {
        display: inline-block;

        .row {
            margin: 0;
        }

        &.stacked {
            cursor: pointer;
            display: block;
            margin-bottom: 5px;
        }
    }

    .aa-icon-arrowoutline {
        color: $brightBlue;
    }

    .aa-icon-arrowfilledin {
        color: $brightBlue;
        display: none;
    }
}

ul.credit-inquiries {
    vertical-align: top;
    list-style: none;
    display: inline-block;
    padding-left: 0;

    li {
        display: inline-block;
    }
}

.scripts-target {
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
    padding: 5px;

    &.row {
        margin: 0;
    }
}

.tags {
    padding: 0;
    list-style: none;
    .tag {
        display: inline-block;
        span {
            color: #000;
            background-color: #F5F5DC;
            margin: 0 5px 3px 0;
            padding: 0 5px;
            border-radius: 3px;
            font-size: 13px;
            line-height: 18px;
            vertical-align: middle;
            cursor: default;
            border: #62A8EC 1px solid;
        }
    }
    .popover {
        max-width: 580px;
    }
}

.vinRecall {
    padding: 0px 10px 10px 10px;
    color: #444B4F;
    outline-color: #7A8084;

    .title {
        font-size: 16px;
        font-weight: bold;
    }

    .body {
        font-size: 12px;
    }

    div {
        padding-top: 10px;
    }

    div.header {
        padding-top: 0;
    }

    h3 {
        margin-top: 10px;
    }
}

.ford.aa-icon-cc-badge {
    background: url('/images/pre-approved.png')  0 4px no-repeat;
    background-size: 166px 18px;
    border: none;
    display: block;
    width: 166px;
    &:before {
        display: none;
    }
}

.ford.aa-icon-pq-badge {
    background: url('/images/pre-qualified.png')  0 4px no-repeat;
    background-size: 166px 18px;
    border: none;
    display: block;
    width: 166px;
    &:before {
        display: none;
    }
}

.google-maps-icon {
    background: url('/images/google-maps.png') no-repeat;
    background-size: 18px 18px;
    border: none;
    display: inline-block;
    width: 18px;
    height: 18px;
    cursor: pointer;
    margin-bottom: 4px;
    margin-left: 10px;
}

#ds-customer-edit {
    width: 97%;
    height: 97%;
    z-index: 99900;
    position: absolute;
    top: 30px;
    left: 0px;
    padding-left: 50px;
    padding-right: 100px;
}

customer-edit {
    .large-checkbox {
        position: relative;
        top: 4px;
        transform: scale(1.25);
    }
    .plan-logo {
        width: 150px;
    }
}

.top-buffer {
    margin-top: 2px;
    vertical-align: middle;
}

.phone-dropdown-anchor {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 4px;
    font-size: 12px;
    line-height: 1.42857;
    border-radius: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

customer-contact ul.dropdown-menu {
    font-size: 12px;
}

.label-top-buffer {
    padding-top: 6px;
    padding-bottom: 5px;
    vertical-align: middle;
    height: 30px;
}

@mixin rotate ($degrees, $x, $y) {
    display: inline-block;
    -webkit-transform: rotate($degrees);
    -webkit-transform-origin: $x $y;
    -moz-transform: rotate($degrees);
    -moz-transform-origin: $x $y;
    -ms-transform: rotate($degrees);
    -ms-transform-origin: $x $y;
}

.current-vehicle-label {
    @include rotate(-90deg, 53px, 93px);
    color: white;

    span {
        position: relative;
        top: 13px;
        left: -10px;
    }
}

.replacement-vehicle-label {
    @include rotate(-90deg, 40px, 80px);
    color: white;
    position: relative;
    width: 118px;
    span {
        position: absolute;
        top: 14px;
        right: 0;
    }
}

.scripts-log-calls {
    &.row {
        padding: inherit;
    }

    .bar-seperator {
        padding-left:10px;
        padding-right:10px;
    }

    .aa-icon-core-phone-icon-000000 {
        position: relative;
        top: 4px;
    }
}

.modal-aa-lg.modal-aa-lg-dealsheet {
    .modal-content {
        background: transparent !important;
        padding-top: 15px;
        min-height: 300px;
    }
}


.form-control.no-border,
input.no-border {
    border: none;
    box-shadow: none;
}

.form-control[disabled].display-enabled,
.form-control[readonly].display-enabled,
fieldset[disabled].display-enabled .form-control.date-type {
    background-color: white !important;
}

.vehicle-vin {
    font-size:12px;
}

//print styles

@media print {
    body * {
        visibility: hidden;
    }

    #aa-header {
        display: none;
    }

    a[href]:after {
        content: none !important;
    }

    label {
        font-size: 12px !important;
    }

    span:not(.no-style) {
        font-size: 14px !important;
    }

    input[type="text"] {
        font-size: 14px !important;
        text-align: left;
        margin-left: -5px;
        height: 18px;
    }

    .section-to-print, .section-to-print * {
        visibility: visible;
    }

    .section-to-print {
        width: 100% !important;
        position: absolute !important;
        left: 0;
        top: 0;
    }

    .vehicle-vin {
        font-size: 9px;
    }

    .aa-feature-comparison {

        .table {
            td {
                font-size: 11px;
            }

            .data-item span {
                font-size: 11px !important;
            }

            td.row-label {
                font-weight: 300 !important;
                color: $labelColor !important;
                font-size: 12px !important;
            }
        }
    }

    .aa-feature-comparison .combined-mileage {
        font-size: 20px;
    }

    .row {
        margin-left:0;
        margin-right:0;
    }

    .dealsheet-body .car-comparison {
        border-bottom: none;
    }

    .field-label {
        margin-bottom: 5px !important;
        width: 30% !important;
    }

    .edit-icon {
        display: none;
    }

    input {
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }

    .ds-header-left {
        display: inline-block;
    }

    .ds-header-right {
        display: inline-block;
        top: 0px !important;
    }

    .ds-tab-nav {
        display: none;
    }

    .ds-tab-body {
        .opportunities {
            display: none;
        }
    }

    .contract-info {
        display: inline-block;
    }

    .customer-name.suffix {
        visibility: hidden !important;
    }

    .deal-sheet-status a {
        display: none;
    }

    .replacement-contract-summary {
        vertical-align: top;
    }

    .current-contract-details.ng-isolate-scope.ng-hide,
    service-history.ng-isolate-scope.ng-hide,
    activity-list.ng-isolate-scope.ng-hide,
    .replacement-contract-details.ng-isolate-scope.ng-hide {
        display: block !important;
    }

    service-history {
        display: block !important;
    }

    .activity:not(:nth-child(-n+25)) {
        display: none;
    }

    activity-list a {
        display: none;
    }

    msrp-type-select, sale-type-select {
        display: none;
    }

    .comm-tools-wrapper {
        display: none;
    }

    .current-vehicle-label {
        @include rotate(0deg, 0, 0);

        span {
            position: relative !important;
            color: black !important;
            border-bottom: 1px solid !important;
            top: 0;
            left: 0;
        }
    }

    .replacement-vehicle-label {
        @include rotate(0deg, 0, 0);

        span {
            position: relative !important;
            color: black !important;
            border-bottom: 1px solid !important;
            top: 0;
            left: 0;
        }
    }

    .contract-header {
        border-left-color: transparent !important;
    }

    button, .print-upgrade-proposal, .aa-icon-core-edit-icon-000000 {
        display: none !important;
    }

    #autoalertiframe {
        display: none;
    }

    .modal,
    .modal * {
        overflow: visible !important;
    }
    .modal {
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
    }
    .modal-dialog {
        width: 100% !important;
        margin: 0;
    }
    .modal-backdrop {
        background-color: white;
        opacity: 1 !important;
    }

    .modal {
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        min-height: 550px;
        visibility: visible;
        overflow: visible !important;
    }

    .modal-dialog {
        visibility: visible !important;
        overflow: visible !important;
    }

    .replacement-logo-svg {
        display: none !important;
    }

    .current-contract-summary, .replacement-contract-summary, .contract-header {
        height: auto !important;
    }

    .no-print {
        display: none !important;
    }
}


a.reopen {
    color: white;
    &:hover, &:focus, &:active, &:visited {
        color: $light-grey;
    }
}

.prescreen-processing {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 6px 12px;
    span, icon.icon.lg {
        color: #7a8084;
    }
}

.prescreen-spinner {
    border: 7px solid transparent;
    height: 35px;
    .cg-busy {
        left: 35%;
    }
}

.proposal-spinner {
    .cg-busy {
        .cg-busy-default-spinner {
            left: 50px !important;
            top: 5px !important;
        }
    }
}

.related-opportunities-by-vin {
    margin-top: 10px;
    ul {
        margin-bottom: 0px;
    }
}

.related-opportunities-message {
    color: #444a50;
}

.form-group label {
    padding-right:5px;
}

.report-discrepancy-header{
    margin: 11px 0;
}

report-discrepancy {
    ul.dropdown-menu-section{
        list-style-type: none;
        padding: 0;
    }
}
.aa-padding-right {
    padding-right: 10px;
}

.value-table {
    overflow-x: auto;
}

/*.current-contract-summary, .replacement-contract-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}*/

.poweredby-blackbook {
    label {
        margin:10px -30px 5px 30px;font-size:8pt;
    }

    img {
        height:20px;
        margin:10px 10px 5px  10px;
    }

    img.galves {
        min-height:20px;
        max-height:25px;
        height: unset;        
    }

    img.black-book-canada {
        min-height:20px;
        max-height:30px;
        height: unset;        
    }
}
.vehicle-reports {
    flex: 0 1 auto;
    align-self: flex-end;
    display:inline-block;
    img {
        height: 17px;
        margin: 10px 10px 5px 0;
    }
}

.replacement-bottom {
    flex: 0 1 auto;
    align-self: flex-end;
}

.customer-edit-info {
    margin-top:10px;
}

.replacement-vehicle-offers {
    @include rotate(-90deg, 131px, 161px);
    button {
        width: 145px;
        border: none;
        padding-top: 5px;
    }
    .selected-offer {
        background-color: #e7e7e7;
        color: $brightBlue;
        height: 30px;
        outline: 0;
        .triangle {
            width: 0;
            height: 0;
            border-left: 23px solid transparent;
            border-right: 23px solid transparent;
            border-top: 12px solid #e7e7e7;
            margin: auto;
        }
    }

    button:not(.selected-offer) {
        background-color: transparent;
        color: white;
        .triangle {
            display: none;
        }
    }
}

activity-actions {
    display: inline-block;
}

facebook-photo {
    display: inline-block;
    vertical-align: top;
    border-radius: 5px;
    margin-right: 5px;
}

.tab-header {
    display: inline-block;
    margin-right: 15px;
}

.activity-list-header {
    display: inline-block;
    margin-right: 15px;
    font-size: 18px;
    margin-top:5px;
    margin-bottom:5px;
}

.default-tab-indicator {
    padding: 0;
    span {
        font-weight: 300;
        color: #7a8084;
        font-size: 14px;
    }
}

.default-tab-icon {
    color: $brightBlue;
}

.modal-content {

    input[type=number] {
        -moz-appearance: textfield;
    }
    input[type=number]:not(.show-spinner)::-webkit-inner-spin-button,
    input[type=number]:not(.show-spinner)::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

#third-party-report-iframe {
    width: 100vw;
    height: 100vh;
    border: none;
    display: block;
}

#dealsheetiframe {
    height: calc(100vh - 60px) !important;
}

pricing-plan-client {
    display:inline-block;
    padding: 2px 10px 2px 80px;
    > .logo {
        display:inline-block;
        > img {
            width: 150px;
        }
        > customer-edit-action {
            vertical-align: middle;
        }
    }
}

vehicle-select {
    .planLabel {
        font-size: 10px;
        color: $brightBlue;
    }

     .planLabelTransparent {
        font-size: 10px;
        color: transparent;
    }


}

alternate-replacement-vehicle-offers {

    > .offercontainer {
        display: flex;
        flex-direction: row;
        font-size: 14px; 
        align-items: stretch;

        > .offeritem.offertitle {
            flex: 0 1 auto;
        }
        > .offeritem.offerlink.single {
            > .offertext {
                > .offertextleft {
                    flex: 0 1 auto;
                    padding-right: 5px;
                }
                > .offertextright {
                    flex: 0 1 auto;
                }
            }
        }
        > .offeritem {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            padding: 6px 16px;
            flex: 1 1 0%;


            > .offertext {
                display: flex;
                align-items: center;
                flex-direction: row;

                > .offertextleft {
                    flex: 1 1 0%;
                }

                > .offertextright {
                    flex: 1 0 0%;

                    > .offerimage {
                        width: auto;
                        height: 56px;
                        margin: 0 4px;
                        float: none;
                    }
                }
            }
        }

        > .offerlink:not(.ng-hide) + .offerlink  {
            border-left: 1px solid #757575;
        }

        > .offertitle {
            background-color: $brightBlue;
            color: white;
            font-weight: 500;
        }

        > .offerlink {
            border-top: 1px solid #757575;

            flex: 1 1 0%;
            cursor: pointer;
        }

        > .offerlink:hover {
            background-color: whitesmoke;
        }

    }
}

.process-wrapper {

    .c-btn {
        background: #fff;
        border: 1px solid #ccc;
        color: #333;
        font-size: 12px;
    }

    .selected-list .c-list .c-token {
        background: #337ab7;
    }

    .selected-list .c-list .c-token .c-label {
        color: #fff;
    }

    .selected-list .c-list .c-token .c-remove svg {
        fill: #fff;
    }

    .selected-list .c-angle-down svg, .selected-list .c-angle-up svg {
        fill: #333;
    }

    .dropdown-list ul li {
        padding: 5px 0 0 10px;
    }

    .dropdown-list ul li:hover {
        background: #f5f5f5;
    }

    .arrow-up, .arrow-down {
        border-bottom: 15px solid #fff;
    }

    .arrow-2 {
        border-bottom: 15px solid #ccc;
    }

    .list-area {
        border: 1px solid #ccc;
        background: #fff;
        box-shadow: 0px 1px 5px #959595;
    }

    .select-all {
        border-bottom: 1px solid #ccc;
    }

    .list-filter {
        border-bottom: 1px solid #ccc;
    }

    .list-filter .c-search svg {
        fill: #888;
    }

    .list-filter .c-clear svg {
        fill: #888;
    }

    .pure-checkbox input[type="checkbox"]:focus + label:before, .pure-checkbox input[type="checkbox"]:hover + label:before {
        border-color: #337ab7;
        background-color: #f2f2f2;
    }

    .pure-checkbox input[type="checkbox"] + label {
        color: #000;
    }

    .pure-checkbox input[type="checkbox"] + label:before {
        color: #337ab7;
        border: 2px solid #337ab7;
    }

    .pure-checkbox input[type="checkbox"] + label:after {
        background-color: #337ab7;
    }

    .pure-checkbox input[type="checkbox"]:disabled + label:before {
        border-color: #cccccc;
    }

    .pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
        background-color: #cccccc;
    }

    .pure-checkbox input[type="checkbox"] + label:after {
        border-color: #ffffff;
    }

    .pure-checkbox input[type="radio"]:checked + label:before {
        background-color: white;
    }

    .pure-checkbox input[type="checkbox"]:checked + label:before {
        background: #337ab7;
    }

    .selected-item {
        background: #e9f4ff;
    }

    .c-btn {
        border-radius: 20px;
        display: inline-block;
        overflow: hidden;
        border: 1px solid #cccccc;
    }

    .selected-list .c-angle-down, .selected-list .c-angle-up {
        width: 10px;
        height: 10px;
    }

    .bs-timepicker-field {
        font-size: 14px;
    }

    timepicker {
        .btn {
            font-size: 14px;
        }
    }

    .tooltipContainer p {
        font-size: 12px;
        float: left;
    }
}

.cln-after {
    padding-right: 5px;
    &:after {
        content: ':';
    }
}

