﻿.page-title {
    margin: 15px 0px 0px 5px;
    padding: 0px 0 0 5px;
    font-size: 18pt;
    color: #444B4F;
}

.divider {
    border-top: 1px solid #1F8BD5;
    margin-bottom: 10px;
    margin-top: 10px !important;
}
