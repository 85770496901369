﻿@import "../../styles/aa-variables";

service-drive-offers-component {

    .cg-busy {
        top: -20px;
    }

    p.input-group.datepicker-right-align {
        margin-bottom: 0;
    }

    hr {
        margin-top: 20px;
    }
}

#alerts-dropdown-button {
    min-width: 100px;
}

.row-offer-header {
    height: 40px;
}

icon {
    &.glyphicon-remove {
            font-size: 14px;
        }
    &.aa-icon-filter {
            color: $brand-primary;
        }
}

.span-filter-icon{
    margin-right: 10px;
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
}

.filter-reset {
    vertical-align: top;
    top: 7px;
    position: relative;
}

/*.btn-delete {
    border: 1px solid #ccc !important;
    border-left: 0px !important;
    height: 34px;
    width: 45px;
}*/

.btn-delete {
    position: absolute;
    top: 4px;
    right: 18px;
    height: 26px;
    background-color: transparent;
}

.row-entities {
    min-height: 50px;
}

#printOfferIframe {
    min-height: 768px;
    width: 100%;
}