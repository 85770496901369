﻿@charset "UTF-8";

@font-face {
  font-family: "autoalert-icons";
  src:url("../fonts/autoalert-icons.eot");
  src:url("../fonts/autoalert-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/autoalert-icons.woff") format("woff"),
    url("../fonts/autoalert-icons.ttf") format("truetype"),
    url("../fonts/autoalert-icons.svg#autoalert-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

/*[data-icon]:before {
  font-family: "autoalert-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

[class^="aa-icon-"]:before,
[class*=" aa-icon-"]:before {
  font-family: "autoalert-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.aa-icon-circle:before {
  content: "\30";
}
.aa-icon-chevron-down:before {
  content: "\31";
}
.aa-icon-chevron-up:before {
  content: "\32";
}
.aa-icon-thumbs-down:before {
  content: "\36";
}
.aa-icon-thumbs-up:before {
  content: "\37";
}
.aa-icon-menu:before {
  content: "\23";
}
.aa-icon-info:before {
  content: "\78";
}
.aa-icon-ban:before {
  content: "\25";
}
.aa-icon-icon-channels:before {
  content: "\2c";
}
.aa-icon-icon-trash:before {
  content: "\62";
}
.aa-icon-refresh:before {
  content: "\64";
}
.aa-icon-1-2-1-icon-46:before {
  content: "\74";
}
.aa-icon-star-icon-off:before {
  content: "\75";
}
.aa-icon-star-icon-on:before {
  content: "\76";
}
.aa-icon-top-nav:before {
  content: "\7a";
}
.aa-icon-core-settings-icon-000000:before {
  content: "\41";
}
.aa-icon-core-recentdealsheet-icon-000000:before {
  content: "\42";
}
.aa-icon-core-savedsearches-icon-000000:before {
  content: "\43";
}
.aa-icon-recently-viewed:before {
  content: "\44";
}
.aa-icon-recently-viewed-1:before {
  content: "\45";
}
.aa-icon-recently-viewed-2:before {
  content: "\46";
}
.aa-icon-core-search-icon-000000:before {
  content: "\47";
}
.aa-icon-core-print-icon-000000:before {
  content: "\48";
}
.aa-icon-core-reports-icon-000000:before {
  content: "\49";
}
.aa-icon-core-search-icon-000000-1:before {
  content: "\4a";
}
.aa-icon-arrowfilledin:before {
  content: "\4b";
}
.aa-icon-arrowoutline:before {
  content: "\4c";
}
.aa-icon-phone-message-icon:before {
  content: "\4d";
}
.aa-icon-phone-scripts-04:before {
  content: "\4e";
}
.aa-icon-play-icon-01:before {
  content: "\4f";
}
.aa-icon-core-arrow2-icon-000000:before {
  content: "\51";
}
.aa-icon-core-arrow-icon-000000:before {
  content: "\52";
}
.aa-icon-core-calendar-icon-000000:before {
  content: "\53";
}
.aa-icon-core-chat-icon-000000:before {
  content: "\54";
}
.aa-icon-core-dealsheet-icon-000000:before {
  content: "\55";
}
.aa-icon-core-email-icon-000000:before {
  content: "\57";
}
.aa-icon-core-flag-icon-000000:before {
  content: "\58";
}
.aa-icon-core-location-icon-000000:before {
  content: "\59";
}
.aa-icon-core-phone-icon-000000:before {
  content: "\5a";
}
.aa-icon-core-send-icon-000000:before {
  content: "\33";
}
.aa-icon-core-settings-icon-000000-1:before {
  content: "\34";
}
.aa-icon-core-share-icon-000000:before {
  content: "\35";
}
.aa-icon-core-takenote-icon-000000:before {
  content: "\68";
}
.aa-icon-core-uparrow-icon-000000:before {
  content: "\39";
}
.aa-icon-close-icon:before {
  content: "\21";
}
.aa-icon-recalculate:before {
  content: "\22";
}
.aa-icon-core-schedule-phone-call-000002:before {
  content: "\24";
}
.aa-icon-electric:before {
  content: "\67";
}
.aa-icon-flag-filled:before {
  content: "\6c";
}
.aa-icon-gasoline:before {
  content: "\3a";
}
.aa-icon-logout:before {
  content: "\3b";
}
.aa-icon-payments:before {
  content: "\3c";
}
.aa-icon-refresh-1:before {
  content: "\3d";
}
.aa-icon-star-filled:before {
  content: "\3e";
}
.aa-icon-unified-header-03:before {
  content: "\3f";
}
.aa-icon-view-offer-letter:before {
  content: "\40";
}
.aa-icon-down-arrow:before {
  content: "\39";
}
.aa-icon-filter:before {
  content: "\50";
}
.aa-icon-flag:before {
  content: "\6e";
}
.aa-icon-diesel:before {
  content: "\54";
}
.aa-icon-cc-badge-outline:before {
  content: "\5b";
}
.aa-icon-cc-badge:before {
  content: "\5d";
}
.aa-icon-benchmark:before {
  content: "\5e";
}
.aa-icon-aa-core-icons-copy-47:before {
  content: "\56";
}
.aa-icon-star:before {
  content: "\38";
}
.aa-icon-1-2-1-icon-46-1:before {
  content: "\7b";
}
.aa-icon-pando-icons-copy-31:before {
  content: "\26";
}
.aa-icon-opportunities:before {
  content: "\27";
}
.aa-icon-finance-insurance-manager:before {
  content: "\28";
}
.aa-icon-activities:before {
  content: "\29";
}
.aa-icon-find-buyer:before {
  content: "\2a";
}
.aa-icon-search:before {
  content: "\2b";
}
.aa-icon-service-drive:before {
  content: "\2d";
}
.aa-icon-conquest:before {
  content: "\2f";
}
.aa-icon-view-as-icon:before {
  content: "\61";
}
.aa-icon-view-as-icon-1:before {
  content: "\77";
}
.aa-icon-aa-core-icons-copy-48:before {
  content: "\63";
}
.aa-icon-aa-core-icons-copy-49:before {
  content: "\65";
}
.aa-icon-core-edit-icon-000000:before {
  content: "\66";
}
.aa-icon-ellipsis-v:before {
  content: "\69";
}
.aa-icon-toggle-off:before {
  content: "\6a";
}
.aa-icon-toggle-on:before {
  content: "\6b";
}
.aa-icon-pandoicon-1:before {
    content: "\6d";
}
.aa-icon-rounded-square:before {
  content: "\6f";
}
.aa-icon-chevron-right:before {
  content: "\70";
}
.aa-icon-icon-move:before {
  content: "\71";
}