﻿@charset "UTF-8";

#aa-productpages {
    .aa-productpages-bg-blue {
        background-color: #1fc2e6;
    }

    .aa-productpages-bg-ltblue {
        background-color: #1f8bd6;
    }

    .aa-productpages-bg-mdblue {
        background-color: #34669e;
    }

    .aa-productpages-bg-dkblue {
        background-color: #182752;
    }

    .aa-productpages-bg-orange {
        background-color: #f26522;
    }

    .aa-productpages-bg-red {
        background-color: #ED1B34;
    }

    .aa-productpages-bg-grey {
        background-color: #444a50;
    }

    .aa-productpages-bg-aluminum {
        background-color: #e8e8e8;
    }

    .aa-productpages-spacer {
        height: 50px;
        width: 100%;
    }

    .aa-productpages-spacer-float {
        height: 50px;
        width: 100%;
        float: left;
    }

    .aa-productpages-head, .aa-productpages-spotlight {
        width: 85%;
        margin: 0 auto;
        text-align: center;
        padding: 50px 0 50px 0;
    }

    .aa-productpages-head img {
        width: 100%;
        max-width: 546px;
        min-width: 100px;
    }

    .aa-productpages-bg-aura {
        background-image: url('~/images/error/unauthorized/showcase-pando.jpg');
        backgrond-attachment: fixed;
        background-position: center center;
        background-size: cover;
        text-align: center;
    }

    .aa-productpages-divide {
        background: none repeat scroll 0 0 #34669e;
        display: block;
        height: 2px;
        margin: 0 auto;
        width: 100px;
        margin-bottom: 40px;
    }

    .aa-productpages-h1 {
        font-weight: 200;
        color: #34669c;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.5;
        font-size: 32px;
    }

    .aa-productpages-h2 {
        font-size: 24px;
        font-weight: 400;
        color: #34669e;
    }

    .aa-productpages-h3 {
        font-size: 24px;
        text-transform: uppercase;
    }

    .aa-productpages-p {
        font-size: 16px;
        color: #34669e;
        line-height: 1.5;
    }

    .aa-productpages-h1-im {
        color: #fff;
        text-transform: uppercase;
        font-weight: 200;
        line-height: 1.5;
    }

    .aa-productpages-h3-im {
        color: #fff;
        font-weight: 200;
        font-size: 26px;
    }

    .aa-productpages-h4-im {
        color: #fff;
        font-weight: 200;
        font-size: 22px;
        margin-top: 30px;
    }

    .aa-productpages-p-im {
        color: #fff;
        font-weight: 400;
        font-size: 16px;
    }

    .aa-productpages-ul {
    }

    .aa-productpages-li {
        color: #34669e;
        font-size: 16px;
        line-height: 2;
    }

    .aa-productpages-white {
        color: #fff;
    }

    .aa-productpages-textwrap {
        width: 65%;
        margin: 0 auto;
        padding: 50px 0 50px 0;
    }

    .aa-productpages-icon {
        text-align: right;
        margin-top: 13px;
        padding-top: 50px;
    }

    .aa-productpages-icon-match {
        padding-top: 50px;
    }

    .aa-productpages-room {
        margin-bottom: 50px;
    }

    .aa-productpages-form {
        padding: 50px 0 50px 0;
    }

    .aa-productpages-hero {
        width: 85%;
        margin: 0 auto;
        text-align: center;
    }

    .aa-productpages-img-im {
        margin-top: 15px;
        text-align: center;
    }

    .aa-productpages-hero-img {
        width: 100%;
        max-width: 1445px;
        min-width: 150px;
    }

    .aa-productpages-form {
        width: 80%;
        margin: 0 auto;
    }

    input, input:focus {
        width: 100%;
        height: 35px;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid #34669e;
        outline: none;
        color: #34669e;
    }

    label {
        color: #34669e;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 400;
        width: 100%;
    }

    .aa-productpages-form-buffer {
        margin-top: 40px;
    }

    .aa-productpages-form-submit {
        clear: both;
        text-align: center;
        padding-top: 40px;
    }

    a.aa-productpages-form-button {
        text-align: center;
        background-color: #34669e;
        line-height: 35px;
        padding: 10px 30px 10px 30px;
        color: #fff;
        font-weight: 600;
        text-transform: uppercase;
    }

    a.aa-productpages-form-button:hover {
        text-decoration: none;
    }

    .aa-productpages-logo-lg {
        width: 100%;
        max-width: 670px;
        min-width: 100px;
    }
}
