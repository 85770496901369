﻿@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "../../styles/_aa-variables";

dashboard-body {
    display: block;
    margin-top: 10px;
}

tagged-customers {
    width: 100%;
}

dashboard {
    /*.panel {
        margin-bottom: 10px;
        padding-bottom: 0;
    }

    .panel-body {
        padding: 4px 15px;
    }*/
    .panel-default > .panel-heading {
        background: #172751;

        &.collapsedPanel-heading {
            border-bottom-right-radius: 9px;
            border-bottom-left-radius: 9px;
            border-bottom: 0;
        }
    }

    .panel {
        padding-bottom: 10px;

        &.darksolid {
            background: #172751;
        }
    }

    .panel-body {
        padding: 4px 15px;
    }

    hr {
        border: 0;
        border-top: 1px solid #000;
        margin-left: -15px;
        margin-right: -15px;
    }

    .form-control {
        height: 32px
    }

    .dashboardtabnames {
        margin: 10px 0;
        padding: 0;
        list-style: none;

        > .dashboardtab {
            display: inline-block;
            // Spacing between tabs
            margin-left: 20px;
            padding: 6px 10px 4px;

            &:first-child {
                margin-left: 0;
                padding-left: 0;
            }

            &.active {
                border-bottom: solid 3px $brightBlue;
            }

            > .dashboardtablink {
                font-size: 18px;
                font-weight: $bold-font-weight;
                color: white;
                text-decoration: none;
            }
        }
    }

    .cg-busy-widget-container {
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .dealersSelectorContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-bottom: 10px;
    }

    .dealersSelect {
        margin-top: 10px;
        width: 235px
    }

    .dealersLabel {
        margin: 18px 10px 0 0
    }

    .quick-links {
        background: linear-gradient(to top, #172751, #2d89c9); /* Standard syntax */
        .quick-links-header {
            color: white;
            padding: 10px;
        }

        .quick-link-row {
            white-space: nowrap;
            cursor: pointer;
            padding-top: 3px;
            padding-bottom: 3px;
            border-bottom: transparent 5px solid;

            &:focus {
                color: #c1c2c4 !important;
                background: -webkit-linear-gradient(to bottom, #192c54 0%,#00548f 100%);
                background: linear-gradient(to bottom, #192c54 0%,#192c54 100%);
                background: linear-gradient(#192c54, #00548f);
                border-bottom: #2d89c9 5px solid;
            }

            &:hover {
                color: #c1c2c4 !important;
                background: -webkit-linear-gradient(to bottom, #192c54 0%,#00548f 100%);
                background: linear-gradient(to bottom, #192c54 0%,#192c54 100%);
                background: linear-gradient(#192c54, #00548f);
                border-bottom: #2d89c9 5px solid;
            }

            &.disabled {
                cursor: default;

                label, icon {
                    cursor: default;
                    opacity: 0.4;
                }

                &:hover {
                    background: none;
                    border-bottom: transparent 5px solid;
                }
            }
        }

        .quick-link-description {
            color: white;
            font-size: 14px;
            position: relative;
            bottom: 6px;
            cursor: pointer;
        }

        icon {
            color: white;
            font-size: 35px;
        }
    }

    container {
        display: table;
        height: 100%;
        width: 100%;
        //margin-top: -50px;
        //   padding: 50px 0 0 0; /*set left/right padding according to needs*/
        //    box-sizing: border-box;
        > .row {
            height: 100%;
            display: table-row;
        }

        > .row .no-float {
            display: table-cell;
            float: none;
            vertical-align: top;
        }
    }



    .opp-block {
        display: inline-block;
        vertical-align: top;
    }

    h5.opp-block-count {
        font-family: 'Roboto', verdana, arial, sans-serif;
        margin: 0;
        color: white;
        line-height: 12px;
        font-size: 15px;
    }

    .opp-block-description {
        color: white;
        padding: 4px 8px 4px 0px;
        width: 88px;
    }

    dashboard-agenda .table > tbody > tr > td {
        vertical-align: middle;
        padding: 6px;
    }
}

dashboard-manager {
    > .datacontrols {
        margin: 0 20px;
        @include clearfix;
    }

    > .datacontrols > .datacontrol {
        float: right;

        &, label {
            color: $reverse-text-color;
            font-size: $font-size-base;
        }
    }
}

dashboard-widget {

    .widgetheadericon {
        display: inline-block;
        &:not(:empty) {
            padding-right: 4px;
        }
    }

    .dashboard-widget-header-item {
        padding-left: 10px;
    }

    .widgetbody {
        padding: 13px;
        min-height: 75px;
        max-height: 306px;
        overflow-x: auto;

        &.search-preset-panel {
            max-height: 450px;
            .table {
                margin-bottom: 15px;
            }

            padding-bottom: 2px;
        }
    }

    .widgetbody > .widgetpreamble {
        display: block;
        color: $subtle-text-color;
        font-size: $subtle-text-size;
    }

    .icon {
        color: #fff;
        &.schedule {
            color: #1f8bd6;
            font-size: 30px;
        }
    }

    .collapseMode {
        padding-bottom:0;
    }
}

dashboard-opportunities-table {
    .entityscore {
        font-weight: $double-bold-font-weight;
    }
    .engagementcount {
        color: $subtle-text-color;
        font-size: $subtle-text-size;
        white-space: nowrap;
    }
}

.opportunity-total {
    display: inline-block;
    color: white;
    margin-top: 5px;
    div.opportunity-total-count {
        height: 75px;
    }
    span.opportunity-total-count {
        display: block;
        text-align: left;
        padding: 0px 0;
        font-size: 15px;
    }
    .opp-block-description {
        width: 130px;
    }
}
