﻿$font-size: 11pt;
$frame-background-color: #E7E7E7;

.admin-roles-wrapper {
    font-size: 10pt !important;
    min-height: 900px;
    background-color: $frame-background-color;
    padding: 10px;

    .admin-roles {
        margin: 15px 15px 10px 15px;
        background-color: white;
        border-radius: 10px 10px;
        min-height: 320px;
    }

    .adminRoleTabContainer {
        margin-top: 5px;
        padding: 0 5px 0 5px;
    }

    .form-control {
        font-size: 14px;
    }

    .roleSelectContainer {
        clear: both;
        font-size: 12pt;
    }

    .roleLabel {
        float: left;
        margin: 6px 0 0 10px
    }

    .roleSelect {
        float: left;
        margin: 0 0 0 5px;
        width: 200px;
    }

    .roleEditButton {
        float: left;
        margin: 0 0 0 10px;
        width: 15px;
        cursor: pointer
    }

    .boldItalics {
        font-weight: bold;
        font-style: italic
    }

    .roleSelectNote {
        float: left;
        margin: 0 0 0 10px;
    }

    .roleSelectNotePleaseEdit {
        float: left;
        margin: 0 0 0 10px;
        color: red;
    }

    .roleAddNew {
        float: right;
        margin: 8px 25px 0 0;
        cursor: pointer;
    }

    .bold {
        font-weight: bold
    }

    .bolditalic {
        font-weight: bold;
        font-style: italic;
    }
}
