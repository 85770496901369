﻿

// START Bootstrap overrides of !default values
$link-hover-color:          #002b49;
$font-family-sans-serif:    'Roboto', sans-serif;
$font-size-base:            16px;
$font-size-small:           12px;
$border-radius-base:        10px;
$border-radius-large:       10px;
$border-radius-small:       10px;
$modal-backdrop-bg:         #192c54;
// END Bootstrap overrides

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

//use $name-more naming convention please!
//i.e. dashes instead of camelCase

$gradient-dark-color: #172751;
$gradient-light-color: #1f8bd5;

@mixin background-gradient($start, $end, $from-color, $to-color) {
    background: -webkit-gradient(linear, $start, $end, from($from-color), to($to-color)) fixed;
    background: gradient(linear, $start, $end, from($from-color), to($to-color)) fixed;
    background: linear-gradient(to $end, $from-color, $to-color) fixed;
}

$dark-blue-background: #192c54;
$brightBlue: #1f8bd5;
$pandoBlue: #15c3e7;
$usedOrange: #f26522;
$navyBlue: #002b49;
$aTagColor: #34699e;
$hoverColor: #002b49;
$brightBlueThirty: rgba(0, 153, 255, .3);
$alert-color: #f53142;
$service-color: #42b93f;
$contract-end-color: #226be6;
$mileage-color: #fe6e1a;
$warranty-color: #7a5ec8;
$flex-alert-color: #824119;
$appointment-color: #257F31;
$in-market-color: #CC8800;
$in-market-engaged: #FCAF26;
$modal-background-color: rgba(23, 39, 81, .5);
$body-base-color: #444a50;
$tableBorder: rgb(221,221,221);
$labelColor: #7a8084;

$main-text-color: #444a50;
$main-background-color: #ffffff;
$reverse-text-color: #ffffff;
$light-text-color: #7a8084;

$subtle-text-color: lighten($main-text-color, 20%);
$subtle-text-size: $font-size-small;

$accent-color: #2d89c9;

$header-hover-background-color: #eee;
$header-hover-text-color: #000;

// "frames" are the dull background that you can
// see around the pure-white backgrounds
$frame-background-color: #e7e7e7;

$tab-background-color: #f0f0f0;
$tab-active-background-color: #172751;
$tab-text-color: $brightBlue;
$tab-active-text-color: white;

$control-background-color: #337ab7;
$alert-info-background-color: #e7e7e7;

$light-font-weight: 300;
$bold-font-weight: 500;
$double-bold-font-weight: 900;

$SectionTitleBlue: #336699;
$SectionTitleStroke: #a6c9e2;
$SectionLabel: #333;
$SectionValue: #555;
$SectionBorder: #ccc;
$SectionInputInvalid: #f53142;
$SectionBorderInvalidFocus: rgba(245, 49, 66, 0.8);
$SectionBorderFocus: rgba(82, 168, 236, 0.8);
$SectionBorderBoxShadow: rgba(82, 168, 236, 0.6);
$SectionBorderInvalidShadow: rgba(245, 49, 66, 0.6);
$SectionBackground: #fff;

$kendoGridFontFamily: "Roboto";
