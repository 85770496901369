@import "../../styles/aa-variables";

deal-sheet-vci {
    .header.row {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    hr.header {
        border-top: 1px solid $body-base-color;
        margin-bottom: 5px;
    }
    .data-block {
        margin-bottom: 10px;
    }
    .data-section {
        padding-bottom: 20px;
    }
    .boxed-section-container {
        padding-top: 10px;
    }
    .boxed-section {
        border: 1px solid $alert-info-background-color;
    }
    .section-title {
        color: $brightBlue;
        font-size: 18px;
        font-weight: bold;
    }
    .cursor-pointer {
        cursor: pointer;
    }
    .section-title-block {
        padding-bottom: 5px;
    }
    .separatorLine {
        border-top: 1px solid $SectionBorder;
        margin-bottom: 5px;
    }
    .collapseWidget {
        color: $brightBlue;
    }
    .data-item {
        padding-bottom: 5px;
    }
    .vci-data-section {
        min-height: 100px;
    }
    .pad-left {
        padding-left: 20px;
    }
}

deal-sheet-vci-eula-modal {
    .vci-eula {
        embed {
            height: 70vh;
            width: 100%;
        }
    }
}