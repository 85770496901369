﻿//
// These are a hodge-podge of styles for things scattered around the
// application.
//
// Please avoid adding styles here, try to scope them to the component where
// they are used by creating a foo.component.scss file alongside the component.
//

@import "aa-variables";

@import "header";
@import "styles";

body {
    /*color: #ffffff;*/
    background: -webkit-gradient(linear, left top, right bottom, from($navyBlue), to(#1f8bd5)) fixed;
    /*background: gradient(linear, left top, right bottom, from($navyBlue), to(#1f8ad5)) fixed;*/
    color: #444a50;
}

aa-header {
    // This element appears as follow:
    //
    //   <body>         <-- stacking context begins here
    //     <header-wrapper>
    //       <div class="aa-modal">(this is the modal backdrop)</div>
    //       <aa-header>...</aa-header>
    //     </header-wrapper>
    //   </body>
    //
    // Since .aa-modal is positioned with z-index 3, aa-header needs
    // a z-index so modal windows stack above their backdrops.
    display: block;
    position: relative;
    z-index: 4;
}




.truncate-text {
    @include text-overflow();
}

.strike-out {
    text-decoration: line-through;
    color: #7a8084;
}
// I wish I knew a correct way to combine this rule with the one above.
a.strike-out:hover, a.strike-out:focus {
    text-decoration: line-through;
}

.strike-through-red {
    text-decoration: line-through;
    color: red;
}

.iframe-pando {
    padding: 0;
    margin: 0;
    margin-top: 0px;
    border: 0;
    height: 600px;
}

.iframe-autoalert {
    position: absolute;
    padding: 0;
    margin: 0;
    margin-top: 0px;
    border: 0;
    z-index: 1;
    visibility: collapse;
    top: 0;
    left: auto;
}

opportunity-block {
    margin: 5px 0;
    display: block;
}

[class^="aa-icon-"].invert:before,
[class*=" aa-icon-"].invert:before {
    -moz-transform: rotateX(180deg);
    -ms-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
    display: inline-block;
}

//alert-logos
@mixin alerts ($alertColor) {
    border-radius: 3px;
    background: $alertColor !important;
    width: 24px;
    height: 24px;
    line-height: 24px !important;
    text-align: center;
    margin-bottom: 3px;
}

@mixin alerts-letter ($alert-letter) {
    content: $alert-letter;
    color: #fff;
    font-family: 'Roboto', verdana, arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

//alert-logos
@mixin alerts-lg ($alertColor) {
    border-radius: 4px;
    background: $alertColor;
    width: 45px;
    height: 45px;
    text-align: center;
    margin-right: 12px;
    /*padding-left: 14px;*/
    margin-bottom: 0px;
    cursor: pointer;
}

@mixin alerts-sm ($alertColor) {
    border-radius: 3px;
    background: $alertColor;
    width: 18px;
    height: 18px;
    line-height: 18px !important;
    text-align: center;
    margin-top: 0px;
    margin-right: 3px;
}

@mixin alerts-letter-lg ($alert-letter) {
    content: $alert-letter;
    color: #fff;
    display: block;
    font-family: 'Roboto', verdana, arial, sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
}

.alerts-letterblock-lg {
    color: #fff;
    font-family: 'Roboto', verdana, arial, sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 45px;
    margin-bottom: 5px;
}


.alerts-letterblock-sm {
    color: #fff;
    font-family: verdana, arial, sans-serif;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

.alerts-letterblock {
    color: #fff;
    font-family: 'Roboto', verdana, arial, sans-serif;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
}

.alert.remove-bootstrap-alert {
    margin-bottom: inherit;
}

/*Opportunities*/
.fa {
    display: inline-block;
    margin: 0px;
    //font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*margin-right: 5px;*/
}

.table {
    td.td-fd {
        white-space: nowrap;
    }
    /*.fa {
        margin-right: 5px;
    }*/
}

.aa-center {
    text-align: center;
}

.aa-right {
    text-align: right;
}

.aa-iblock {
    display: inline-block;
}

.aa-upper {
    text-transform: uppercase;
}

.table-v-align {
    vertical-align: middle !important;
    text-align: center !important;
}

.a-no-underline {
    &:hover, &:focus {
        text-decoration: none;
    }
}

.a-def-color {
    color: inherit;
}

.live-now {
    padding: 1px 3px 1px 3px;
    color: $service-color;
    float: right;
}

.fa-sm {
    display: inline-block;
    font: normal normal normal 18px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 12px;
}

.fa-lg {
    display: inline-block;
    font: normal normal normal 72px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 12px;
}

.aa-audio-link-container {
    display: inline-block;
    top: -10px;
    padding-left: 10px;
    position: relative;
}

credit-inquiries {
    icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-bottom: 3px;
        font-size: 26px;
        color: #333;
    }
}

.stock-number-search {
    position: relative;

    .aa-icon-core-search-icon-000000 {
        position: absolute;
        top: 5px;
        right: 0;
        padding-right: 5px;
        color: $navyBlue;
    }
}

.custom-search-input {
    position: relative;
    padding-left: 10px;
    // Hide the extra controls IE adds because we have a button overlapping it
    input::-ms-clear {
        display: none;
    }

    .form-control {
        display: inline-block;
        width: auto;
    }

    .search-wrapper {
        font-weight: normal;
        position: relative;

        button {
            position: absolute;
            right: 0;
            top: -3px;
            padding-right: 7px;

            .aa-icon-core-search-icon-000000 {
                color: $navyBlue;
            }
        }
    }

    .recent-search {
        position: absolute;
        top: 25px;
        right: 0;
        width: 600px;
        z-index: 2;
        text-align: left;

        h5.predefined {
            background: none;
            color: #34699e;
            padding: 5px 5px 5px 10px;
            margin: 10px 0;
            font-weight: bold;
        }

        hr {
            margin: 5px 0;
        }

        ul {
            margin-bottom: 0;
            list-style: none;
            padding-left: 20px;

            li {
                padding: 7px 0;

                a {
                    background: none;
                    border-bottom: none;
                    line-height: normal;
                    padding: 6px 0;
                    color: #34699e;

                    &:hover {
                        color: #23527c !important;
                        text-decoration: underline;
                        background: none;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

span.light {
    color: #fff;
}

.aa-modal-recent-deal-sheet {
    background: transparent;
    opacity: 0.5;
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3; // Stack above <aa-header>, but below modal windows
}

.aa-modal-preset-visible {
    background: transparent;
    opacity: 0.5;
    width: 100%;
    height: 1000px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; // Stack above <aa-header>, but below modal windows
}

.chat img {
    display: none;
}

.recentDealSheets-container {
    position: relative;
    cursor: pointer;
    margin-left: 20px;

    .recentDealSheets {
        position: absolute;
        top: 10px;
        right: 0;
        z-index: 3;
        color: #000;
        width: 365px;

        ul {
            padding-left: 10px;

            li {
                list-style: none;
            }
        }
    }
}

.ui-select-multiple input.ui-select-search {
    width: 100% !important;
}

.ui-select-bootstrap.ui-select-multiple > div {
    overflow: unset !important;
}

.aa-icon-core-savedsearches-icon-000000:before {
    font-size: 18px;
}

icon.icon {
    &.aa-icon-core-search-icon-000000 {
        font-size: 21px;
    }
}


.svg-icon {
    width: 32px;
    height: 32px;
    padding: 2px;
    margin-bottom: 1px;
    margin-top: 1px;

    &.sm {
        width: 22px;
        height: 22px;
        padding: 1px;
        margin-bottom: 1px;
        margin-top: 1px;
    }
}

aa-header .aa-icon-pando-icons-copy-31 {
    padding-right: 5px;
}

ul.opportunity {
    margin: 3px 0;

    li {
        display: inline-block;
        margin-left: 5px;
    }
}

.deal-sheet-list {
    margin: 20px;

    .deal-sheet-item {
        //line-height: 10px;
        margin-top: 10px;

        a {
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            -ms-text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            text-overflow: ellipsis;
        }

        .opportunities {
            display: inline-block;
            position: relative;
            top: -7px;
            /*.opportunity {
                padding-right: 5px;
                display: inline-block;
            }*/
        }
    }
}

.spinner {
    height: 30px;
}

.faalert {
    @include alerts($alert-color);

    &.faalert-lg {
        @include alerts-lg($alert-color);
    }

    &.faalert-sm {
        @include alerts-sm($alert-color);
    }
}

.faservice {
    @include alerts($service-color);

    &.faservice-lg {
        @include alerts-lg($service-color);
    }

    &.faservice-sm {
        @include alerts-sm($service-color);
    }
}

.facontractEnd {
    @include alerts(#226be6 );
}

.facontractend {
    @include alerts($contract-end-color);

    &.facontractend-lg {
        @include alerts-lg($contract-end-color);
    }

    &.facontractend-sm {
        @include alerts-sm($contract-end-color);
    }
}

.famileage {
    @include alerts($mileage-color);

    &.famileage-lg {
        @include alerts-lg($mileage-color);
    }

    &.famileage-sm {
        @include alerts-sm($mileage-color);
    }
}

.fawarranty {
    @include alerts($warranty-color);

    &.fawarranty-lg {
        @include alerts-lg($warranty-color);
    }

    &.fawarranty-sm {
        @include alerts-sm($warranty-color);
    }
}

.faflexalert {
    @include alerts($flex-alert-color);

    &.faflexalert-lg {
        @include alerts-lg($flex-alert-color);
    }

    &.faflexalert-sm {
        @include alerts-sm($flex-alert-color);
    }
}

.faappointment {
    @include alerts(#257F31 );

    &.faappointment-lg {
        @include alerts-lg($mileage-color);
    }

    &.faappointment-sm {
        @include alerts-sm($mileage-color);
    }
}

.fainmarket {
    @include alerts($in-market-color);

    &.fainmarket-lg {
        @include alerts-lg($in-market-color);
    }

    &.fainmarket-sm {
        @include alerts-sm($in-market-color);
    }
}

.fainmarketengaged {
    @include alerts($in-market-engaged);

    &.fainmarketengaged-lg {
        @include alerts-lg($in-market-engaged);
    }

    &.fainmarketengaged-sm {
        @include alerts-sm($in-market-engaged);
    }
}

/* deal sheet popup*/

#dealsheetWindow {
    position: absolute;
    padding-bottom: 32px;
    padding-left: 8px;
    padding-right: 8px;
    overflow: hidden;
    /*resize: both;*/
    z-index: 99999;
    background: rgba(23, 39, 81, .5);
}


.panel-heading {
    /*padding: 0px;*/
    /*background: rgba(23, 39, 81, .5);*/
}

.panel {
    padding-bottom: 10px;

    &.dark {
        background: rgba(52, 105, 158, .3);

        span {
            color: white;
        }
    }
}

.dealsheet-panel-body {
    /*background: rgba(23, 39, 81, .5);*/
    height: 100%;
}

.resizable {
    overflow: hidden;
    resize: both;
}

.draggable {
    position: absolute;
    z-index: 99999;
}

.draggable-handler {
}

.dragging {
    cursor: move;
    z-index: 99999 !important;
}

.win-size-grip {
    position: absolute;
    width: 16px;
    height: 16px;
    padding: 4px;
    bottom: 0;
    right: 0;
    cursor: nwse-resize;
    background: url("../images/wingrip.png") no-repeat;
}

//bs overwrides
label {
    font-weight: 300;
    color: #7a8084;
    font-size: 14px;
}

label.light {
    color: #fff;
}

.field-data {
    font-weight: $bold-font-weight;
}

.value {
    // .value replaces the previous style that was applied to "span" directly
    // There are probably better classes like .field-data that are more semantic,
    // but those other fields have other styles like padding, float, etc. applied
    // to them, so we can't use it consistently unless we remove those styles.
    font-weight: $bold-font-weight;
}

// For compact forms where the label is above each field
.form-vertical {
    label {
        margin-bottom: 0;
    }

    input, select {
        margin-bottom: 0.5em;
    }
}

// Override a Bootstrap style
// Checkboxes and radio buttons should line up with the text next to them
input[type=radio],
input[type=checkbox] {
    margin-top: 0;
    margin-bottom: 0;
}

// This copies the Bootstrap style for ".dropdown-menu > li > a"
// allowing it to be applied to something other than <a>
.dropdown-menu > li > .dropdown-item {
    /*display: block;*/
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    white-space: nowrap;
}

//data grid labels here
.equity-label,
.current-contract-details .field label,
.current-contract-summary-body .field label,
.replacement-contract-summary-body .field label,
.replacement-contract-details .field label {
    /*width: 90px;*/
    margin-bottom: 10px;
    
    @media only screen and (max-width: 768px) {
        max-width: calc(100vw - 30px);
        margin-top: 5px;
        margin-bottom: -5px;
    }
}

.current-contract-summary {
    .field-label {
        @extend .col-sm-5;
        @extend .col-md-4;
        @extend .col-lg-3;
    }

    .field-data {
        @extend .col-sm-7;
        @extend .col-md-8;
        @extend .col-lg-9;
    }

    @media only screen and (max-width: 768px) {
        .field-data {
            display: block;
        }
    }

}

.replacement-contract-summary-body {
    .field-label {
        icon {
            padding-left: 5px;
        }
    }
}

.replacement-contract-summary {
    .field-label {
        @extend .col-sm-7;
        @extend .col-md-6;
        @extend .col-lg-5;
    }

    .field-data {
        @extend .col-sm-5;
        @extend .col-md-6;
        @extend .col-lg-7;
    }

    @media only screen and (max-width: 768px) {
        .field-data {
            display: block;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 992px) {
        .field-label {
            width: 41.66667%;
        }

        .field-data {
            width: 58.33333%;
        }
    }
    
    @media only screen and (min-width: 992px) and (max-width: 1024px) {
        .field-label {
            width: 33.33333%;
        }

        .field-data {
            width: 66.66667%;
        }
    }
}

.aa-suffix-symbol {
    padding-left: 3px;
}

.aa-short-lables-section {
    .message-item {
        @extend .col-md-10;
        margin-bottom: 10px;
    }

    .field-label {
        @extend .col-md-2;
        @extend .col-sm-3;
        @extend .col-xs-6;
        margin-bottom: 10px;
    }

    .field-data {
        @extend .col-md-10;
        @extend .col-sm-9;
        @extend .col-xs-6;
    }
}

.current-contract-details {
    .field-label {
        @extend .col-sm-3;
        @extend .col-lg-2;
    }

    .field-data {
        @extend .col-sm-9;
        @extend .col-lg-10;
    }
}

.replacement-contract-details {
    .field-label {
        @extend .col-sm-3;
    }

    .field-data {
        @extend .col-sm-7;
    }

    .replacement-color {
        .field-label {
            display: inline-block;
            width: 125px;
        }

        .field-data {
            padding-left: 0;
        }
    }

    stock-vehicle {
        .field-label {
            display: inline-block;
            width: 125px;
        }

        #stock {
            display: block;
        }

        .stock-vehicle-info {
            display: inline-block;
        }

        .field-data {
            display: inline-block;
        }
    }
}

contract-select {
    .closest {
        background-color: #fbec88;
    }

    .selected {
        background-color: #bec1c2;
    }
}

user-select .list-group {
    li:hover {
        background-color: #f5f5f5;
    }

    .default {
        background-color: rgba(91,192,222,.17);
    }
}

.modal-dialog .list-group {
    overflow-x: hidden;
    overflow-y: auto;
    height: 500px;
}

.view-email-modal .modal-dialog {
    width: 100%;

    @media only screen and (min-width : $sm-breakpoint) {
        width: 90%
    }

    @media only screen and (min-width : $md-breakpoint) {
        width: 67%
    }
}

.user-select-modal .modal-dialog {
    width: 400px;
}

.inventory-search .modal-dialog {
    width: 850px;
}

.service-detail-modal .modal-dialog {
    width: 1000px;

    .detail-table {
        min-height: 100px;
        max-height: 350px;
        overflow-x: auto;
    }
}

.search-preset-select-modal .modal-dialog {
    width: 900px;
}

.vauto-modal {
    width: 700px;
}
.vincue-modal {
    width: 700px;
}
.dms-push-modal {
    width: 1000px;
}
.dms-push-confirm-label {
    width: 130px;
}
.score-info-modal {
    width: 600px;
}
.nlo-info-modal {
    width: 900px;
}


.credit-soft-pull-result-modal-component {
    .customer-name,
    .field-label {
        @extend .col-sm-6;
    }

    .field-value {
        @extend .col-sm-6;
    }
}

vehicle-type-select {
    label {
        margin-right: 10px;
    }
}
//class="form-control input-sm editable
.editable {
    height: 25px;
    width: 75px;
    padding-left: 2px;
    display: inline-block;
    border-radius: 10px;
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
    line-height: 2px;
}



.table {
    font-size: 14px;

    th {
        font-weight: 300;
        color: #7a8084;
    }

    td {
        font-weight: $bold-font-weight;
    }
    /*icon {
        margin-bottom: 0px;
    }*/
}

tr.light td {
    font-weight: $light-font-weight;
    color: $light-text-color;
}

.aa-dealsheet-busy:not(.aa-dealsheet-busy-full-screen) {
    position: static !important;
}

.aa-dealsheet-busy .cg-busy-default-wrapper {
    background-color: $modal-background-color;
}

.aa-dealsheet-busy .cg-busy-default-sign {
    background-color: #e9eeee;
    top: 50%;
    border-radius: 10px;
}

.aa-dealsheet-busy-full-screen .cg-busy-default-sign {
    top: 35%;
}

textarea {
    resize: none;
}
//bs end global
/*.table > thead > tr > th {
color: #7a8084;
        font-size: 12px;
}*/
h4, .h4 {
    font-size: 16px;
}

h5, .h5 {
    font-size: 18px;
}

.horizontal-line-with-title {
    margin: 10px 0 10px;
    width: 100%;
    text-align: left;
    border-bottom: 1px solid #eeeeee;
    line-height: 0.1em;

    > span {
        background: #fff;
        color: grey;
    }
}
//acitivity icons
.activity {
    display: table;
    width: 100%;
}

.activity-icon {
    display: table-cell;
    width: 35px;
    position: relative;
    vertical-align: middle;
    padding-right: 15px;
    padding-left: 15px;
}

.activity-data {
    display: table-cell;
    width: 100%;
}

.activity-filters {
    margin-bottom: 15px;
}

.activity-buttons {
    padding-bottom: 28px;
    margin-left: 49px;
}

.activity-scheduled {
    margin-top: 16px;
}

p {
    font-size: 16px;
}

.input-sm {
    font-size: 16px;
}

@mixin svg($url, $width: 1em, $height: $width) {
    background: url($url) no-repeat;
    width: $width;
    height: $height;
    display: inline-block;
    line-height: 1;
}

.alert-blocks-svg {
    @include svg('../images/icons/alert-blocks.svg');
}

.replacement-logo-svg {
    @include svg('../images/icons/replacement-search.svg', 25px);
    padding-right: 35px;
}

.recall-tag-svg {
    @include svg('../images/icons/recall-tag.svg');
    cursor: pointer;
    width: 125px;
    height: 25px;
}

.text-and-email-icon-blue {
    @include svg('../images/icons/icon-messaging-email-sms-lake.svg', 30px, 25px);
    margin-bottom: 4px;
}

.text-and-email-icon-white {
    @include svg('../images/icons/icon-messaging-email-sms-white.svg', 30px, 25px);
    margin-bottom: 4px;
}

.marketing-mailer-icon-blue {    
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-marketing-mailer-lake.svg', 30px, 36px);
    margin-bottom: 4px;
}

.marketing-mailer-icon-white {
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-marketing-mailer-vanilla.svg', 30px, 36px);
    margin-bottom: 4px;

    &.-twenty-two-square {
        height: 22px;
        width: 22px;
    }
}

.geo-alert-icon-blue {    
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-geoalert-lake.svg', 30px, 36px);
    margin-bottom: 4px;

    &.sm {
        width: 22px;
        padding: 1px;
        margin-bottom: 1px;
        margin-top: 1px;
    }
}

.geo-alert-icon-white {
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-geoalert-vanilla.svg', 30px, 36px);
    margin-bottom: 4px;

    &.-twenty-two-square {
        height: 22px;
        width: 22px;
    }
}

.im-offer-arrow-icon-white {
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-im-offer-arrow-vanilla.svg');    
}

.im-offer-arrow-icon-blue {
    @include svg('https://assets.autoalert.com/img/autoalert-icons/icon-im-offer-arrow-lake.svg');    
    
    &.margin-bottom { 
        margin-bottom: 5px
    };
}

.aa-well {
    position: relative;
    top: 10px;
    background-color: #eee;
    border-color: #ddd;
    border-width: 1px;
    margin: 10px;
    padding: 5px;
    height: calc(100vh - 210px); // header height: 150px    footer height: 40px    top: 10px    margin: 10px
    display: table;
    width: calc(100vw - 20px); // margin: 2x10px
}

.row-form-inline {
    padding: 5px 0 5px 0;
}

icon {
    &.icon {
        &.lg {
            font-size: 35px;
            color: $brightBlue;
        }

        &.sm {
            font-size: 25px;
            color: $brightBlue;
        }

        &.icon-green {
            color: forestgreen;
        }

        &.icon-lightgreen {
            color: #36A83E;
        }

        &.icon-white {
            color: #FFFFFF;
        }
    }
}

.btn {
    border: 0px;
    padding: 6px 15px;
}

/*.comm-tools-wrapper .comm-tools .btn {
    padding: 6px 6px;
}*/


activity-list {
    .svg-icon {
        fill: #337ab7;
    }

    .active .svg-icon {
        fill: #fff;
    }
}

modal > .modal-title-bar > .modal-title-bar-container > .modal-header-text.end-auto-assist-modal-title {
    flex: initial;
}

.btn-link:hover {
    icon {
        text-decoration: none !important;
    }
}

.opportunities {
    /*table{
        border-color: transparent;
    }*/
    .table > tbody + tbody {
        border-top: transparent;
    }

    .table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
        border: none;
        outline: none;
        cursor: pointer;
        vertical-align: middle;
    }

    .table-body-border {
        margin-top: 10px;
        border-top: 2px solid #eee !important;
    }
}

table ul.opportunities {
    margin-bottom: 0px;
}

ul.opportunities {
    display: inline-block;

    .opportunity {
        padding-right: 5px;
    }
}

.static-spinner {
    position: static !important;
}

.selectable {
    cursor: pointer;
}

hr:not(.margin-top) {
    margin-top: 0;
}

.editor {
    height: 500px;
    overflow: auto;
}


modal {
    > .modal-title-bar {
        @include background-gradient(to right, right, $gradient-dark-color, $gradient-light-color);
        color: $reverse-text-color;
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        text-align: left;
        margin: 0;
        display: flex;
        flex-direction: column;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            // IE 11 only, column flex boxes seem to collapse unless you set a height
            // See: https://caniuse.com/#search=flex
            height: 2.5em;
        }

        > .modal-title-bar-container {
            flex: 1;
            flex-direction: row;
            align-items: center;
            display: flex;
        }

        > .modal-title-bar-container > .modal-header-text {
            flex: 1;
        }

        > .modal-title-bar-container > .modal-header-extras {
            flex: 1;
            text-align: center;
        }

        > .modal-title-bar-container > .modal-header-icon,
        > .modal-title-bar-container > .modal-header-buttons {
            flex: 0 1.5em; // IE 11 requires flex-shrink to have a unit or else the box collapses
            &:empty {
                flex: 0 1; // But don't leave a gap if it's empty
            }
        }

        > .modal-title-bar-container > .modal-header-icon {
            padding: 0px 7px;
            line-height: 1;
        }

        > .modal-title-bar-container > .modal-header-buttons {
            color: #ffffff;
            padding: 5px 10px;
            cursor: pointer;
        }
    }

    > .modal-body {
        padding: 25px 25px 10px 25px !important;
    }
}

.modal-body {
    padding: 25px 25px 10px 25px !important;

    .readonly-template {
        border: 1px solid rgba(0, 0, 0, .15);
        background: #fff;
        font-family: 'Times New Roman', serif;
        font-size: 18px;
        line-height: 1.2em;
        overflow: auto;
        height: 500px;

        p {
            font-size: 18px;
        }
    }

    .template-letter-head {
        width: 100%;
        margin-bottom: 30px;
    }

    financials-lease-sale {
        .calculation {
            padding-left: 4px;
            text-decoration: underline;
        }
    }

    add-financial-info {
        .null-date {
            border-color: red;
            outline-color: red;
        }
    }

    .table-sortable {

        &.no-table-margin {
            margin-bottom: -1px;
        }

        th .sortable {
            position: relative;
            /*padding-right: 0px;
        padding-left: 0px;
        text-align: left;*/
            padding-right: 10px;
            color: #000;

            .sorted-ascending::after,
            .sorted-descending::after {
                font-family: "autoalert-icons";
                font-size: 10px;
                /*position: absolute;*/
                bottom: 0.25em;
                margin-left: 0px;
                padding-left: 0px;
            }

            .sorted-ascending::after {
                content: "\32"; // aa-icon-chevron-up
            }

            .sorted-descending::after {
                content: "\31"; // aa-icon-chevron-down
            }
        }
    }

    vehicle-select {
        .vehicle-count {
            margin-top: 10px;
            text-align: left;
        }

        section {
            position: relative;
            border: 1px solid #fff;
            padding-top: 37px;
            background: #fff;
        }

        section.positioned {
            position: absolute;
            top: 100px;
            left: 100px;
            width: 800px;
            box-shadow: 0 0 15px #333;
        }

        .instock-result {
            overflow-y: auto;
            height: 200px;
        }

        table {
            border-spacing: 0;
            width: 100%;

            th {
                height: 0;
                line-height: 0;
                padding-top: 0;
                padding-bottom: 0;
                color: transparent;
                border: none;
                white-space: nowrap;
            }
        }

        td + td {
            border-left: 1px solid #fff;
        }

        td, th {
            border-bottom: 1px solid #eee;
            background: #fff;
            color: #000;
            padding: 10px 25px;
        }



        th div {
            position: absolute;
            background: transparent;
            color: #7a8084;
            padding: 9px 25px;
            top: 0;
            margin-left: -25px;
            line-height: normal;
        }

        th:first-child div {
            border: none;
        }
    }
}

.h5-modal {
    background: #34699e;
    color: #fff;
    border-radius: 5px;
    text-align: left;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 10px;
    min-height: 30px;
}

.modal-header-buttons {
    float: right;
    color: #ffffff;
    padding: 5px 10px;
    cursor: pointer;
}

.panel {
    border: none;
}
//TODO: Refactor panel header
.panel-clear-heading {
    @extend .panel-heading;
    color: #fff;
}

.panel-default > .panel-heading {
    background: linear-gradient(to right, #172751, #1f8bd5);
    border-color: transparent;

    span {
        color: #fff;
    }
}

.panel-default.dark .table {
    th, tr {
        color: white;
    }
}

.cursor {
    cursor: pointer;
}
//new large (dealsheet) modal styles
.modal-aa-lg {
    background: $modal-background-color;

    .modal-dialog {
        width: 95% !important;
        margin: 10px auto;

        @media only screen and (max-width : 768px) {
            margin: 0 auto;
        }
    }
}

payments {
    .alert {
        padding: 0;
    }
}

.warranty-tools {
    margin-top: 5px;
}

.aa-alert-color {
    color: $alert-color;
}

.pr-5 {
    padding-right: 5px;
}

.pr-7 {
    padding-right: 7px;
}

.pr-15 {
    padding-right: 15px;
}

.comm-tools-wrapper {
    text-align: right;
    margin-top: 18px;

    @media only screen and (max-width : 991px) {
        text-align: left;
        margin-top: initial;
    }

    .comm-tools {
        .btn {
            padding: 6px 6px;
        }
    }
}

.pad {
    padding-bottom: 3px;
}

.btn-primary:focus {
    outline: none;
}

.upgrade-proposal-modal .modal-dialog {
    width: 95%;
    padding-right: 17px;

    iframe {
        height: 700px;
    }
}

.print-template-modal .modal-dialog {
    width: 850px;

    iframe {
        height: 700px;
    }
}

.large-modal .modal-dialog {
    width: 60%;
}

.send-email-modal .modal-dialog {
    width: 750px;
}

.send-email-template-modal .modal-dialog {
    width: 1000px;
}

.wish-list-matches-modal .modal-dialog {
    width: 1400px;
}


/* The starting CSS styles for the enter animation */
.slide-frame {
    position: relative;
    height: 422px;
    overflow: hidden;
}

.slide-frame > div {
    width: 100%;
}
/* modal and step animations */
.wave.ng-enter, .wave.ng-leave {
    -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    -moz-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    -o-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.5s;
}

.wave.ng-enter {
    position: absolute;
    left: -100%;
}

.wave.ng-enter-active {
    left: 0;
}

.wave.ng-leave {
    position: absolute;
    left: 0;
}

.wave.ng-leave-active {
    left: 100%;
}

.print-upgrade-proposal {
    display: block;
    margin-bottom: 10px !important;
}

.print-upgrade-proposal-icon {
    margin-right: 5px;
}


stock-vehicle {
    &.rate-type-select-new {
        @extend .col-sm-12;
    }

    &.rate-type-select-used {
        @extend .col-sm-6;
    }
}

rate-type-select {
    margin: 10px 0;
}

.rates-applied-padding {
    padding-right: 10px;
}

.opp-block-description {
    font-size: 12px;
}

.no-bottom-margin {
    margin-bottom: 0;
}

payoff-input input {
    font-weight: normal;
}

.aa-border-radius-left {
    border-top-left-radius: 10px;
}

.aa-border-radius-right {
    border-top-right-radius: 10px;
}

.aa-parentheses {
    &:before {
        content: "(";
    }

    &:after {
        content: ")";
    }
}

.aa-feature-comparison {
    hr {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .image-block {
        min-height: 117px;
        display: inline-block;
        vertical-align: top;
    }

    img {
        margin: 0 16px 5px 0;
        display: inline-block;
        width: 150px;
    }

    .trim-info {
        display: inline-block;
        text-align: left;
        max-width: calc(100% - 180px);
    }

    .vehicle-summary {
        text-align: left;
    }

    .section-head {
        color: #1f8bd6;
        font-size: 16px;
    }

    .vehicle-title {
        font-size: 16px;
    }

    .you-save-text {
        font-size: 14px;
    }

    .label, .row-label {
        font-weight: 300;
        color: $labelColor;
        font-size: 14px;
    }

    .new {
        color: $brightBlue;
    }

    .used {
        color: $usedOrange;
    }

    .cpo {
        color: $usedOrange;
    }

    .conquest {
        color: $service-color;
    }

    icon.icon:not(.default-tab-icon) {
        font-size: 14px;
        color: $brightBlue;
        position: relative;
        top: 2px;
    }

    icon.icon.fuel-icon {
        font-size: 20pt;
        color: $body-base-color;
        top: 10px;
    }

    .combined-mileage {
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
        text-align: left;
    }

    .separate-mileage-block {
        display: inline-block;

        &.highway {
            padding-left: 10px;
        }
    }

    .fuel-label {
        color: $labelColor;
    }

    .activity-group-lable-selected {
        color: #ffffff;
        font-size: 8pt;
    }

    .row-mileage {
        padding-bottom: 5px;
    }

    .row-mileage .mileage-pad {
        padding-left: 10px;
    }

    .rzslider {
        width: calc(100% - 83px);
        margin: 32px 0 15px 4px;
    }

    .rzslider .rz-bar {
        width: calc(100% + 80px);
        left: -80px;
    }

    .rzslider .rz-ticks {
        margin-left: 9px;
    }

    .rzslider .rz-pointer {
        background-color: $brightBlue;
        outline: none;
        margin-left: 9px;
    }

    .rzslider .rz-pointer:after {
        background-color: $navyBlue;
    }

    .rzslider .rz-pointer.rz-active:after {
        background-color: $navyBlue;
    }

    .aa-rzslider-label {
        display: inline-block;
        /*background: cyan;*/
        width: 75px;
        font-weight: $bold-font-weight;
        font-size: 15px;
        vertical-align: top;
        height: 55px;
    }
    /* to remove the default positioning */
    /*.rzslider .rz-pointer {
      width: 8px;
      height: 16px;
      top: auto; 
      bottom: 0;
      background-color: #333;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }*/
    .icon-placeholder {
        width: 19px;
        display: inline-block;
    }

    tr td:not(:first-child) {
        border-left: 1px solid $tableBorder;
    }

    tr th:not(:first-child) {
        border-left: 1px solid $tableBorder;
    }

    .key-item {
        display: inline-block;
        padding-right: 10px;
    }

    .key-item span {
        font-size: 14px;
        font-weight: initial;
    }

    .legend {
        padding-bottom: 5px;
    }

    input[type="checkbox"]:focus {
        outline: initial;
        outline-offset: initial;
    }

    .bold {
        font-weight: bold;
    }

    .table {
        .data-item {
            text-align: left;
        }

        .data-item.existing {
            padding-left: 20px;
        }

        .data-item span {
            font-size: 14px;
        }

        .selector-item {
            vertical-align: middle;
        }

        td.label {
            display: table-cell;
            text-align: initial;
        }

        td.aa-fe-slider-row {
            padding: 0;

            div.aa-fe-slider-legend {
                background-color: $tableBorder;
                text-align: center;
                padding: 3px 0;
            }
        }

        tr.segmented-row {
            border-bottom: 1px solid #ddd;
        }

        & > thead > tr > th {
            border-bottom: none;
            border-top: none;
        }

        & > thead > tr > td {
            border-bottom: none;
            border-top: none;
        }

        & > tbody + tbody {
            border-top: none;
        }

        & > tbody > tr > th {
            border-bottom: none;
            border-top: none;
        }

        & > tbody > tr > td {
            border-bottom: none;
            border-top: none;
        }
    }
}


select.form-control:not([multiple]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    background: url('../images/dropdown.png') right / 20px no-repeat #fff;
    padding-right: 20px;
}

select.form-control::-ms-expand {
    display: none;
}

.script-language-options {
    margin: 11px 0;
}

a.script-language-active {
    font-weight: bold;
    text-decoration: none;
    color: #337ab7;

    &:active, &:visited, &:hover, &:focus {
        font-weight: bold;
        text-decoration: none;
        color: #337ab7;
    }
}

.nav-sub .sub-menu .dropdown {
    a.active-menu-item {
        color: #c1c2c4 !important;
        background: -webkit-linear-gradient(to bottom, #192c54 0%, #00548f 100%);
        background: linear-gradient(to bottom, #192c54 0%, #192c54 100%);
        background: linear-gradient(#192c54, #00548f);
        border-bottom: #2d89c9 5px solid;
    }

    a.disabled {
        cursor: default;
        color: $SectionBackground !important;
        opacity: 0.4;
        background: none;
        border-bottom: transparent 5px solid;
    }
}

.mark-as-sold {
    font-weight: $bold-font-weight;
    font-size: 16px;
    color: #444a50;
}
//todo: refactor to possibly use @extend of nested class
.dropdown-menu-section {
    // Links within the dropdown menu
    > li > a {
        display: block;
        padding: 3px 30px;
        clear: both;
        font-weight: normal;
        line-height: $line-height-base;
        color: $dropdown-link-color;
        white-space: nowrap; // prevent links from randomly breaking onto new lines
        // Hover/Focus state
        &:hover,
        &:focus {
            text-decoration: none;
            color: $dropdown-link-hover-color;
            background-color: $dropdown-link-hover-bg;
        }
    }
    // Active state
    > .active > a {
        &,
        &:hover,
        &:focus {
            color: $dropdown-link-active-color;
            text-decoration: none;
            outline: 0;
            background-color: $dropdown-link-active-bg;
        }
    }
}

.aa-italics-warning {
    color: red;
    //font-style: italic;
}

.aa-italics-warning-ddl {
    color: red;
    //font-style: italic;
    font-size: 12px !important;
}

.spinner.dealer-search-spinner {
    position: static !important;
}

.change-log-wrapper {
    overflow-y: scroll;
    height: 350px;
}

.change-log-selection {
    padding: 0 25px 0 5px;
}

.dropdown-menu {
    min-width: 210px;

    .disabled {
        color: red;
    }
}
/*.aa-settings-section {
    min-height: calc(100% - 127px);
    background-color: $SectionBackground;

    h5 {
        color: $SectionTitleBlue;
        font-size: 18px;
        font-weight: bold;
    }
    hr {
        background-color: $SectionTitleStroke;
        height: 1px;
    }

    label {
        min-width: 100px;
        margin-right: 10px;
        color: $SectionLabel;
    }
    .input-group {
        padding-bottom: 10px;
    }
    .error-message {
        display: inline-block;
        color: $SectionInputInvalid;
        padding-left: 10px;
    }

    .multiSelect, 
    select, 
    textarea, 
    input[type="text"], 
    input[type="password"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="date"], 
    input[type="month"], 
    input[type="time"], 
    input[type="week"], 
    input[type="number"], 
    input[type="email"], 
    input[type="url"], 
    input[type="search"], 
    input[type="tel"], 
    input[type="color"], 
    .uneditable-input {
        display: inline-block;
        color: $SectionValue;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        min-width: 300px;
    }
    textarea, 
    input[type="text"], 
    input[type="password"], 
    input[type="datetime"], 
    input[type="datetime-local"], 
    input[type="date"], 
    input[type="month"], 
    input[type="time"], 
    input[type="week"], 
    input[type="number"], 
    input[type="email"], 
    input[type="url"], 
    input[type="search"], 
    input[type="tel"], 
    input[type="color"], 
    .uneditable-input {
        background-color: $SectionBackground;
        border: 1px solid $SectionBorder;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
        -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
        -o-transition: border linear 0.2s, box-shadow linear 0.2s;
        transition: border linear 0.2s, box-shadow linear 0.2s;
        &.ng-invalid {
            border-color: $SectionInputInvalid;
        }
    }
    textarea, 
    input[type="text"]:focus, 
    input[type="password"]:focus, 
    input[type="datetime"]:focus, 
    input[type="datetime-local"]:focus, 
    input[type="date"]:focus, 
    input[type="month"]:focus, 
    input[type="time"]:focus, 
    input[type="week"]:focus, 
    input[type="number"]:focus, 
    input[type="email"]:focus, 
    input[type="url"]:focus, 
    input[type="search"]:focus, 
    input[type="tel"]:focus, 
    input[type="color"]:focus, 
    .uneditable-input:focus {
        border-color: $SectionBorderFocus;
        outline: 0;
        outline: thin dotted \9;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderBoxShadow;
        -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderBoxShadow;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderBoxShadow;
        &.ng-invalid {
            border-color: $SectionInputInvalid;
            outline: 0;
            outline: thin dotted \9;
            -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderInvalidShadow;
            -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderInvalidShadow;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $SectionBorderInvalidShadow;
        }
    }

}*/
//add this class v align text on the same row as an input field.
.special-line-height {
    line-height: 35px;
}

.user-notifications-edit {
    .form-control {
        display: inline-block;

        &.short {
            width: 55px;
        }

        &.medium {
            width: 65px;
        }

        &.large {
            width: auto;
        }
    }

    .phone-number {
        label {
            padding-left: 15px;
        }

        .carrier-label {
            margin-left: 125px;
        }
    }
}
// Colors shared by all sized sliders
%slider {
    $handle-disabled-color: #d8e0f3;
    $handle-bg-color: $control-background-color;
    $handle-inner-color: $SectionBackground;
    $handle-hover-color: $SectionBackground;
    $handle-active-color: #451aff;

    $bar-normal-color: $SectionTitleStroke;
    $bar-fill-color: $handle-bg-color;
    $bar-disabled-fill-color: #8b91a2;

    $label-text-color: #55637d;
    $limit-label-text-color: $label-text-color;
    $ticks-color: $bar-normal-color;
    $selected-ticks-color: $bar-fill-color;

    &[disabled] {
        .rz-pointer {
            background-color: $handle-disabled-color;
        }

        .rz-selection {
            background: $bar-disabled-fill-color;
        }

        .rz-tick {
            &.rz-selected {
                background: $bar-disabled-fill-color;
            }
        }
    }

    .rz-bar {
        background: $bar-normal-color;
    }

    .rz-selection {
        background: $bar-fill-color;
    }

    .rz-pointer {
        background-color: $handle-bg-color;

        &:after {
            background: $handle-inner-color;
        }

        &:hover:after {
            background-color: $handle-hover-color;
        }

        &.rz-active {
            &:after {
                background-color: $handle-active-color;
            }
        }
    }

    .rz-bubble {
        color: $label-text-color;

        &.rz-limit {
            color: $limit-label-text-color;
        }
    }

    .rz-tick {
        background: $ticks-color;

        &.rz-selected {
            background: $selected-ticks-color;
        }
    }
}

.sm-slider.rzslider {
    @extend %slider;
    // These are the same variables as those used in this file:
    // https://github.com/angular-slider/angularjs-slider/blob/master/src/rzslider.less
    // To change the dimensions of the slider, you should be able to just
    // change the variables at the top and all the elements will adjust accordingly.

    $ticks-width: 6px;
    $ticks-height: 6px;
    $ticks-value-position: -30px;
    $ticks-legend-position: 24px;
    $ticks-value-position-on-vertical: 24px;

    $handle-size: 16px;
    $handle-pointer-size: 4px;
    $bubble-padding: 1px 3px;
    $bar-dimension: 2px;

    $with-legend-margin: 40px;

    $label-font-size: 0px;

    // -- END OF VARIABLES -- //
    height: $bar-dimension;
    width: 100%;
    margin: ($handle-size / 2 + $label-font-size) 0 ($handle-size / 2) 0;

    @mixin rounded($radius) {
        border-radius: $radius;
    }

    &.with-legend {
        margin-bottom: $with-legend-margin;
    }

    .rz-bar-wrapper {
        left: 0;
        margin-top: -$handle-size / 2;
        padding-top: $handle-size / 2;
        width: 100%;
        height: $handle-size;
    }

    .rz-bar {
        left: 0;
        width: 100%;
        height: $bar-dimension;
        z-index: 1;
        @include rounded($bar-dimension/2);
    }

    .rz-selection {
        z-index: 2;
        @include rounded($bar-dimension/2);
    }

    .rz-pointer {
        width: $handle-size;
        height: $handle-size;
        top: -$handle-size/2 + $bar-dimension/2;
        @include rounded($handle-size/2);

        &:after {
            width: $handle-pointer-size;
            height: $handle-pointer-size;
            position: absolute;
            top: $handle-size/2 - $handle-pointer-size/2;
            left: $handle-size/2 - $handle-pointer-size/2;
            @include rounded($handle-pointer-size/2);
        }
    }

    .rz-bubble {
        display: none;
        bottom: $handle-size/2;
        padding: $bubble-padding;
        font-size: $label-font-size;
    }

    .rz-ticks {
        width: 100%;
        height: 0;
        position: absolute;
        left: 0;
        top: -($ticks-height - $bar-dimension) / 2;
        margin: 0;
        z-index: 1;
        list-style: none;
    }

    .rz-ticks-values-under {
        .rz-tick-value {
            top: initial;
            bottom: $ticks-value-position - 2;
        }
    }

    .rz-tick {
        text-align: center;
        cursor: pointer;
        width: $ticks-width;
        height: $ticks-height;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        margin-left: $handle-size / 2 - $ticks-width / 2; // for centering
    }

    .rz-tick-value {
        position: absolute;
        top: $ticks-value-position;
        transform: translate(-50%, 0);
    }

    .rz-tick-legend {
        position: absolute;
        top: $ticks-legend-position;
        transform: translate(-50%, 0);
        max-width: 50px;
        white-space: normal;
    }
}

.customer-contact {
    .email-list {
        display: inline-block;

        .dropdown-menu {
            margin-left: 30px;
        }
    }
}

.modal-footer-button {
    margin-right: 9px;
}

.selection label {
    color: #444a50;
}

.checkbox .label {
    padding: 0;
}

.label {
    color: #444a50;
}

.inventory-search-component {
    .reset-button {
        padding-right: 0;
    }

    .trim-select-wrapper {
        position: relative;
        padding-right: 55px;

        button {
            position: absolute;
            top: 0px;
            right: 0px;
        }
    }
}

trim-select {
    .form-control {
        display: inline;
        width: auto;
    }

    label {
        margin-right: 5px;
    }

    select {
        margin-right: 20px;
    }

    .trim-select-dropdowns {
        display: inline-block;

        select {
            margin-bottom: 5px;
        }
    }
}



edit-contact {
    .align-with-text-input {
        padding-top: 7px;
    }
}

sale-type-select label {
    margin-right: 10px;
}

#third-party-report-iframe {
    background-color: white;
}

.discrepancy-input-modal .modal-dialog {
    width: 800px;
}

[uib-alert].alert {
    &.alert-info {
        color: inherit; // Eliminate the red we put on `.alert`
        background-color: $alert-info-background-color;
        border-color: darken($alert-info-background-color, 10%);
    }
}

tagged-customers {
    display: inline-block;

    .tag-wrapper {
        display: inline-block;

        h4.light {
            color: transparent;

            &.firstTag {
                color: #fff;
            }
        }

        .tagTypeOne {
            background: #1F8BD5;
        }

        .tagTypeTwo {
            background: #172751;
        }
    }

    .tag-container {
        background: #2d89c9;
        text-align: center;
        border-radius: 10px;
        color: #fff;
        padding: 0 10px;
        margin: 0px 10px 0px 0px;
        width: 140px;
        height: 70px;
        cursor: pointer;
        display: table;

        .tag-int {
            font-size: 30px;
            padding-top: 10px;
        }

        .description-wrapper {
            display: table;
            height: 30px;
            width: 100%;
            position: relative;
            top: -5px;

            .tag-description {
                font-size: 12px;
                display: table-cell;
                vertical-align: middle;
                line-height: 14px;
            }
        }
    }

    .tag-panel {
        padding: 20px 15px 15px 0px;
    }
}

.facebook-logo {
    width: 24px;
    height: 24px;
}

.transparent, .transparent:focus {
    background-color: transparent;
    border: none;
    outline: none;
}

.tag-label {
    display: inline-block;

    span {
        color: #fff;
        display: block;
        margin-bottom: 5px;
        font-size: 13px;
    }
}

customer-email-list, customer-email {
    display: inline-block;
}

customer-email-list .dropdown-menu {
    margin-left: 15px;
}

customer-email-list, customer-phone-list {
    .dropdown-menu .aa-italics-warning {
        margin-left: 20px;
    }
}

.customer-contact-list-item {
    display: inline-block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: $line-height-base;
    white-space: nowrap;

    &:hover {
        text-decoration: none;
        color: #262626;
        background-color: #f5f5f5;
    }
}

.placeholder {
    visibility: hidden;
}

.customer-phone-numbers {
    display: inline;
}

input.ng-invalid.ng-dirty {
    border-color: $SectionInputInvalid;
}

customer-edit-action {
    display: inline-block;
    vertical-align: top;
}

.financial-edit-modal .modal-dialog {
    width: 715px;
}

reports-header dealer-make-select {
    .dealer-select, .make-select {
        padding-left: 0;
        @extend .col-sm-6;
    }
}

reports-header pando-survey-select {
    .survey-family-select, .survey-select {
        padding-left: 0;
        padding-bottom: 10px;
        @extend .col-sm-6;
    }
}

report-subscription-edit dealer-make-select {
    .dealer-select, .make-select {
        padding-bottom: 10px;
        @extend .col-sm-6;
    }
}

announcement-modal {
    a:focus {
        outline: none;
        color: #337ab7;
        text-decoration: none;
    }

    .checkbox {
        margin-left: 30px;
    }

    input[type="checkbox"] {
        margin-top: 5px;
    }
}

open-modal {
    cursor: pointer;
}

.available-search-presets-wrapper {
    height: 500px;
    overflow-y: scroll;
}

select[multiple].available-search-presets {
    height: 425px;
    overflow-y: auto;
    font-size: 14px;
}

search-preset-select {
    .alert {
        margin-bottom: 0px;
    }

    optgroup {
        padding-top: 3px;
        padding-bottom: 3px;
    }
}

.selected-search-presets {
    font-size: 14px;
    height: 425px;
    border: solid 1px lightgrey;
    border-radius: 10px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    padding: 5px;

    &:focus {
        border-color: #66afe9;
        outline: 0;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }

    .svg-icon {
        margin-right: 7px;
        margin-top: 4px;
        cursor: pointer;
        float: right;

        &:before {
            line-height: 30px;
        }

        &.sm {
            width: 25px;
            height: 25px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 2px;
            margin-right: 10px;
        }
    }

    .aa-icon-pandoicon-1 {
        margin-right: 7px;
        margin-top: 1px;
        cursor: pointer;
        float: right;

        &:before {
            line-height: 30px;
        }
    }

    .aa-icon-close-icon {
        margin-right: 2px;
        cursor: pointer;
        float: right;

        &:before {
            line-height: 30px;
        }
    }

    .as-sortable-item {
        line-height: 30px;
        border-radius: 8px;
        margin-bottom: 10px;
    }

    .aa-icon-icon-move {
        display: inline-block;
        color: $brightBlue;
        margin-left: 2px;
    }
}

.search-preset-count-tooltip {
    color: black;
}

.search-preset-count-na {
    vertical-align: middle;
}

.search-preset-count-list {

    .dealerSelect {
        padding: 5px 0 5px 8px;
        font-size: 14px;
    }

    .dealerSelect select {
        font-size: 14px;
        height: 32px;
        display: inline;
        width: auto;
        margin-left: 10px;
    }

    select {
        color: #444a50;
    }

    .table td.left, .table th.left {
        text-align: left;
    }

    .table td, .table th {
        text-align: right;
        vertical-align: middle;
    }

    .action {
        font-size: 12px;
    }

    .cg-busy-default-wrapper {
        background: -webkit-gradient(linear, left top, right bottom, from(#002b49), to(#1f8bd5)) fixed;
    }

    /*.count {
        font-size: 22px;
        font-weight: bold;

        &.highlight {
            background-color: #1f8bd5;
            border-radius: 8px;
            width: 85px;
            padding: 0 5px;
            float: right;
            color: white;
        }
    }

    .nocount {
        font-size: 16px;
        font-weight: bold;
    }*/

    .search-preset-count {
        margin-bottom: 4px;
    }

    .aa-icon-core-settings-icon-000000 {
        display: inline-block;
    }

    .search-condition-name {
        margin-left: 10px;
    }

    .table {
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    border-top: 1px solid #ddd;
                }
            }
        }
        // Bottom align for column headings
        > thead > tr > th {
            border-bottom: 2px solid #ddd;
        }
    }
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #005290;
    font-family: "Arial", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290;
}

contract-select .icon-pad {
    padding: 4px 6px 0 0;
}

quick-search {
    display: inline-block;
    margin-right: 10px;
}

template-email, email-unsubscribe, template-email-preview {

    .row {
        margin-left: 0px;
        margin-right: 0px;

        &.template-wrapper {
            width: 800px;
            margin: 0 auto;
        }
    }

    .loading {
        width: 100%;
        height: 60px;
        overflow-y: no-display;
    }

    .templatebody {
        background-color: white;
        height: 750px;
        padding: 20px;
        margin-top: 3%;
        overflow-y: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        &.unsubscribe {
            height: 350px;
        }

        .redactor-layout-table .col-1 {
            width: 626px;
        }

        .redactor-layout-table .col-2 {
            width: 313px;
        }

        .redactor-layout-table .col-3 {
            width: 208px;
        }


        &.dealer-user-preview {
            box-shadow: none;

            .redactor-layout-table .col-1 {
                width: 626px;
            }

            .redactor-layout-table .col-2 {
                width: 313px;
            }

            .redactor-layout-table .col-3 {
                width: 208px;
            }

            #dealer-info, #unsubscribe {
                background-color: #f2f2f2;
                padding: 0px;
                margin: 0px;
                font-size: 10pt;
            }
        }


        &.preview {
            padding: 0px;
            height: auto;

            .has-letterhead {
                img {
                    width: 670px;
                }
            }

            .email-wrapper {
                width: 100%;
            }
        }

        .emailheader {
            img {
                width: 670px;
            }
        }
    }

    .emailfooter {
        text-align: left;
        background-color: #f2f2f2;
        text-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 3%;
        font-size: 10pt;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

        span {
            margin-right: 2px;
        }

        &.dealer-user-preview {
            box-shadow: none;
        }
    }
}

.templatebody .redactor-button td,
.readonly-template .redactor-button td {
    padding: 15px !important;
    display: block;
    background-color: #5bb75b;
    border: none
}


email-unsubscribe {
    text-align: left;
}

lms-transcript h5 {
    color: #1f8bd5;
    margin-bottom: 5px;
}

lms-transcript hr {
    margin-bottom: 5px;
    border: 1px solid #e5e5e5
}



toggle-button {
    $selected-bg-color: #1f8bd5;

    > .btn {
        display: inline-block;
        background-color: transparent;
        border: 2px solid #1f8bd5;
        margin-left: 5px;
        padding: 6px 15px;
        height: 34px;
        color: #1f8bd5;
        @include no-btn-outline(); /* it is defined in header */
    }

    &.tab-icon > .btn {
        padding-bottom: 2px;
        padding-left: 8px;
        padding-right: 8px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &.tab > .btn {
        padding-bottom: 2px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    &.tab > .btn:active {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        outline: none;
    }

    &.-small > .btn {
        font-size: 12px;
    }

    > .btn:active {
        outline: none;
    }

    > .btn.active {
        outline: none;
        background-color: $selected-bg-color;
        color: #fff;
    }

    > .btn.active:active {
        background-color: darken($selected-bg-color, 15%);
    }
}
// pagination color settings
$page-forecolor: #34699E;
$page-border: #BDC0C2;
$page-disabled: #BDC0C2;
$page-active: #03C0E6;

// base control display - general
.aa-pagination {
    @extend .pagination;

    li {
        a:hover {
            @extend .aa-page-link;
            color: $page-forecolor;
        }


        a.ng-binding.aa-page-link {
            @extend .aa-page-link;
            border-color: $page-border;
        }

        a.aa-page-link {
            @extend .aa-page-link;
            border-color: $page-border;
        }
    }
}
// base control display - smaller
.aa-pagination-sm {
    @extend .pagination-sm;
    font-size: 10pt;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-radius: 0px;
    padding-bottom: 0px;
    height: 18px;
    border: 0px;

    li:last-child > a, li:last-child > span {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
    }

    li:first-child > a, li:first-child > span {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
    }

    li {
        // selected pages
        a.ng-binding.aa-active {
            @extend .aa-active;
        }

        a.aa-disabled {
            @extend .aa-disabled;
        }
    }
}
// Pagination styles
.aa-page-link .page-link {
    padding-top: 3px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 3px;
    // All page numbers
    color: $page-forecolor;
    background-color: white;
    z-index: 3;
    border: 1px;
    border-style: solid;
    border-color: $page-border;
    font-size: 10pt;
}

.aa-page-item {
    border: 0px;
    background-color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 28px;
}
// disabled pagination arrows
.aa-disabled {
    @extend .disabled;
    color: $page-disabled;
    cursor: not-allowed;
    border-color: $page-border;
}

.pagination > .active > a, .aa-pagination > .active > a {
    // selected pages
    .aa-active {
        @extend .active;
        border: 2px;
        border-style: solid;
        border-color: $page-active;
        border-width: 2px;
        // use these settings with border 2px
        padding-top: 4px;
        padding-left: 9px;
        padding-right: 9px;
        padding-bottom: 4px;
        color: $page-forecolor;
        z-index: 4;
    }
}
// -------- Modal Footer -------------------
.aa-page-nav {
    height: 35px;
    line-height: 29px;
    text-align: center;
}

.modal {
    #CustomerEngagementsPanel {
        .modal-footer {
            padding: 3px;
        }
    }
}

.body-wrapper {
    display: table;
    height: calc(100vh - 190px); // header height: 150px    footer height: 40px
    width: 100%;
}
// -------- Portal Footer -------------------
portal-footer {
    .footer-wrapper {
        position: relative;
        bottom: 0;
        left: 0;
        margin-top: 0;
        width: 100%;
        padding-top: 10px;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 10px;
        border-top: 2px solid $brightBlue;
        background-color: #FFFFFF;
    }


    .footer {
        width: 100%;
        color: $brightBlue;
        font-size: 13px;
    }

    .footer-left {
        float: left;
    }

    .footer-right {
        float: right;
    }

    .footer-link {
        color: $brightBlue;
        text-decoration: none;

        &:active, &:hover, &:focus {
            text-decoration: none;
            color: $aTagColor
        }
    }

    .footer-spacer {
        padding-left: 12px;
        padding-right: 8px;
        color: $brightBlue;
    }
}


@media screen and (max-width: 1024px) { //1024px tablet landscape
    .footer-left, .footer-right {
        width: 100%;
        text-align: center;
    }

    .body-wrapper {
        height: calc(100vh - 373px); // header height: 252px    footer height: 80px
    }
}

#template-email-modal-footer {
    label {
        font-size: 11pt;
        text-align: left;
        width: 350px;
        color: #444a50;
        vertical-align: middle;

        .modified {
            width: 300px;
        }

        input {
            vertical-align: central;
        }
    }
}

// override for bootstrap uib-popover
.popover {
    font-size: $font-size-small;
}

.wishlist-popover {
    font-size: 14px;
    max-width: 500px;
}

.display-none {
    display: none !important;
}

.embeddedServiceHelpButton {
    position: relative !important;
}

.embeddedServiceHelpButton .helpButton {
    position: relative !important;
    height: 30px !important;
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #1f8bd5 !important;
    font-family: "Arial", sans-serif !important;
    height: 30px !important;
    border-radius: 0 0 8px 8px !important;
    max-width: 15em !important;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290 !important;
}

.embeddedServiceHelpButton .message {
    /*background-color: #ffc;
    border-style: solid;
    border-width: 1px;
    color: #000;*/
    padding: 3px 4px 3px 3px;
    /*margin: 4px 20px;*/
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    background-color: #1f8bd5;
    color: #fff;
}

.embeddedServiceSidebarHeader {

    h2.headerText {
        margin: 0;
    }

}
