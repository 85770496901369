﻿
x-data-entry-alerts {

    .alerts-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    input.ng-invalid.ng-dirty {
        border-color: #cccccc;
    }

    .override {
        font-weight: bold;
    }

    #gridDataEntryAlerts th.k-header.align-center {
        text-align: center;
    }

    #gridDataEntryAlerts th.k-grid-content.align-center {
        text-align: center;
    }
}
