﻿
x-data-entry-rebates {
	.dealer-rebate-wrapper{
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;	
	}
	
    input.ng-invalid.ng-dirty {
        border-color: #cccccc;
    }

}