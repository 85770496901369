﻿@import 'variables';
@import '~@progress/kendo-ui/css/web/kendo.common.css';
@import '~@progress/kendo-ui/css/web/kendo.blueopal.css';
/*@import '~@progress/kendo-theme-default/dist/all.css';*/
/*@import '~@progress/kendo-ui/css/web/kendo.bootstrap.css';*/

// KENDO GRID GLOBAL COLOR CHANGES
// calendar component ------------------------------

    /*
    .k-popup {
        display: normal;
        flex-direction: column;
        align-items: stretch;
        margin: 0;
        padding: 0 0;
        border-width: 1px;
        border-style: solid;
        font-size: 14px;
        line-height: 1.4285714286;
        box-sizing: content-box;
        z-index: 500;
    }

    .k-prev-view {
        margin-left: 5px;
        width: auto;
        white-space: nowrap;
    }

    .k-next-view {
        width: auto;
        white-space: nowrap;
    }

    .k-calendar {
        position: relative;
        display: inline-block;
        width: auto;
        overflow: hidden;
        border-width: 1px;
        border-style: solid;
        line-height: 1.4285714286;
    }

    .k-calendar.k-calendar-range.k-calendar-infinite > .k-button {
        border-radius: 0;
        width: 44px;
    }

    .k-calendar.k-calendar-range.k-calendar-infinite > .k-calendar-view {
        border-width: 0 1px;
        border-style: solid;
        border-color: inherit;
    }

    .k-calendar.k-calendar-range.k-calendar-infinite table > tbody {
        display: inline-block;
        vertical-align: top;
    }

    .k-calendar table {
        margin: 0;
        border-width: 0;
        border-color: inherit;
        border-spacing: 0;
        border-collapse: separate;
        table-layout: fixed;
        outline: 0;
        position: relative;
        z-index: 1;
    }

    .k-calendar th {
        font-size: 12px;
    }

    .k-calendar .k-content, .k-calendar .k-content th {
        text-align: center;
    }

    .k-calendar.k-calendar-range.k-calendar-infinite table > tbody + tbody {
        margin-left: 32px;
    }

    .k-calendar.k-calendar-range .k-range-mid {
        background-color: #f5f5f5;
    }

    .k-calendar.k-calendar-range .k-range-start, .k-calendar.k-calendar-range .k-range-end {
        background-color: #0099ff;
    }

    .k-calendar .k-content .k-link {

    }

    kendo-calendar-horizontal {

        kendo-calendar-header {
            margin: 10px;
            margin-left: 2px;
            margin-right: 5px;
            background-color: inherit;
            align-items: center;
            font-size: 18px;
        }
    }*/

    //---- header and toolbar-----
.k-toolbar {
    background-image: none;
    background-color: #172751;
    border-color: #172751;

    position: relative;
    display: block;
    vertical-align: middle;
    padding: .28571428em 0;
    overflow: hidden;
}

    .k-toolbar.k-widget {
        border-color: #172751;
    }

    #report-toolbar {
        label {
            color: #fff !important;
            font-family: "Roboto";
        }
    }

    .k-header {
        background-image: none;
        background-color: #E4E4E4;
        border-color: #BDC0C2;
    }

    .k-grid-header {
        background-image: none;
        background-color: #E4E4E4;
        border-color: #BDC0C2;
        border-bottom: 1px solid #444b4f;
    }

    #report-wrapper {
        .k-grid-header {
            border-color: #BDC0C2;
        }

        a {
            .customer-name {
                color: #1F8BD5;
                display: inline-block;
                vertical-align: middle;
                line-height: 24px;
            }

            .trim-name {
                color: #1F8BD5;
            }
        }
    }

    .k-widget {
        border-color: #BDC0C2;
    }

    .k-grid-header-wrap {
        border-color: #BDC0C2;
    }
    // ---- footer and pager -----
    .k-pager-wrap {
        background-image: none;
        background-color: #E4E4E4;
        color: #00435e;
    }

    .k-grid-footer {
        border-color: #E4E4E4;

        td {
            border-color: #BDC0C2;
        }
    }

    .k-grid-footer-wrap {
        border-color: #E4E4E4;
    }
    // --- pager number buttons and nav buttons
    .k-pager-numbers .k-state-selected {
        background-color: #1F8BD5;
        background-image: none;
        border-radius: 0;
    }

    .k-pager-numbers .k-link:hover {
        background-color: #e6e6e6;
        border-color: #BDC0C2;
        border-radius: 0;
    }
    // converts inner grid pager numbers to square
    .k-pager-numbers .k-link {
        border-radius: 0;
    }

    .k-pager-wrap .k-link:hover {
        background-color: #f2f2f2;
        border-color: #BDC0C2;
        border-radius: 0;
    }

    .k-link.k-pager-nav {
        border-color: #BDC0C2;
        border-radius: 0;
    }

    .k-link.k-pager-nav.k-state-disabled {
        border-color: #cccccc;
    }
    // ---- buttons and inputs  -----


#grid-toolbar, #report-toolbar {
    .k-button {
        color: #1F8BD5;
        background-color: #ffffff !important;
        border-color: #1F8BD5 !important;
    }

    .k-button:active {
        color: #1F8BD5;
    }

    .k-button:hover {
        background-color: #ffffff;
    }

    .k-button:focus {
        box-shadow: none;
        border-color: #1F8BD5;
    }

    .k-button.k-state-active {
        color: #fff;
        background-color: #1F8BD5 !important;
        box-shadow: none;
        background-image: none;
    }

    .k-button.k-state-active:hover {
        color: #fff;
        background-color: #1F8BD5;
        box-shadow: none;
    }
}
    // ----- text input  ----------------
    .k-autocomplete.k-input {
        border-color: #1F8BD5;
    }

    .k-autocomplete.k-state-focused {
        border-color: #1F8BD5;
    }

    .k-popup-dropdowntree.k-list-container {
        color: #333333;
        border-color: rgba(0, 0, 0, 0.2);
        background-color: #fff;
    }


    .k-popup .k-treeview .k-item > .k-group {
        color: #333333;
    }

    .k-popup-dropdowntree {
        .k-block,
        .k-widget,
        .k-popup,
        .k-content,
        .k-toolbar,
        .k-dropdown .k-input {
            color: #333333;
        }

        .k-list-container {
            color: #333333;
        }

        .k-state-hover,
        .k-state-hover:hover,
        .k-splitbar-horizontal-hover:hover,
        .k-splitbar-vertical-hover:hover,
        .k-list > .k-state-hover,
        .k-scheduler .k-scheduler-toolbar ul li.k-state-hover,
        .k-pager-wrap .k-link:hover,
        .k-dropdown .k-state-focused,
        .k-filebrowser-dropzone,
        .k-mobile-list .k-item > .k-link:active,
        .k-mobile-list .k-item > .k-label:active,
        .k-mobile-list .k-edit-label.k-check:active,
        .k-mobile-list .k-recur-view .k-check:active,
        .k-listbox .k-item:hover:not(.k-state-disabled) {
            color: #333333;
            background-color: #ebebeb;
            border-color: #aeaeae;
        }
    }

    #active-tag-div {

        .k-input,
        input.k-textbox,
        textarea.k-textbox,
        input.k-textbox:hover,
        textarea.k-textbox:hover,
        .k-textbox > input,
        .k-multiselect-wrap {
            background-color: #fff;
            color: #333333;
        }

        .k-button {
            border-radius: 4px;
            border-color: #cccccc;
            color: #333333;
            background-color: #fff;
            background-position: 50% 50%;
            background-image: unset;
        }

        .k-autocomplete > .k-i-close,
        .k-multiselect-wrap > .k-i-close {
            right: 55px;
        }

        .k-dropdowntree {
            width: 100%;
            margin-bottom: 5px;
        }
        // Kendo puts font size directly on the element so overriding with important here
        .k-popup-dropdowntree {
            font-size: 16px !important;
        }

        .k-widget.k-dropdowntree {
            padding-left: 0;
            padding-right: 15px;
            max-height: 75px;
            cursor: pointer;
        }

        .k-dropdowntree-clearable .k-multiselect-wrap {
            padding-right: 5px;
        }

        .k-widget.k-treeview {
            color: unset;
        }

        .k-treeview .k-in.k-state-hover {
            color: unset;
        }

        .k-multiselect-wrap .k-button {
            background-color: #337ab7;
            color: #fff;
            font-size: 12px;
            margin-top: 7px;
            margin-bottom: 7px;
            margin-right: 5px;
        }

        .k-multiselect-wrap .k-button:hover {
            background-color: #34699E;
            color: #fff;
        }

        .k-multiselect-wrap {
            display: block;
            width: 98%;
            min-height: 36px;
            max-height: 75px;
            overflow-x: hidden;
            overflow-y: auto;
            font-size: 16px;
            line-height: 1.42857;
            color: #555555;
            background-color: #fff;
            background-image: none;
            border: 1px solid #ccc;
            border-radius: 10px;
            box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        }

        .k-multiselect-wrap > .k-i-close {
            top: 10px;
        }

        .k-multiselect-wrap .k-input {
            padding-top: 5px;
            padding-bottom: 5px;
        }

        label {
            color: #444b4f;
            font-size: 0.66em;
            margin-bottom: 0;
        }
    }
    // ---- grid  rows and columns -----
    .k-grid tr:hover {
        background-color: #E7E7E7;
    }

    .k-grid td {
        border-color: #E7E7E7;
        border-bottom: 1px solid #E7E7E7;
        /*a {
        color: #1F8BD5;
    }*/
    }

    .k-alt {
        background-color: #ffffff;

        td {
            border-color: #E7E7E7;
            border-bottom: 1px solid #E7E7E7
        }
    }

    .k-detail-row {
        td {
            border-color: #E7E7E7;
            border-bottom: 1px solid #E7E7E7
        }
    }

    .k-master-row {
        border-color: #E7E7E7;
        background-color: #ffffff;

        td {
            border-color: #E7E7E7;
            border-bottom: 1px solid #E7E7E7
        }
    }



    .k-grid, .k-button .k-link {
        font-family: $kendoGridFontFamily;
    }
    // -- grid header border
    .k-grid {
        border-top: 1px solid #444b4f;
    }

