﻿@import '../../styles/variables';

$font-size: 11pt;
$frame-background-color: #E7E7E7;

wish-list {
    font-size: $font-size;
    // Overrides for font-sizes we've put elsewhere
    label, .form-control {
        font-size: $font-size;
    }
    /*    > .-formcontainer {
        background-color: $frame-background-color;
    }
*/
    .table > thead > tr > th,
    .table > tbody > tr > th {
        text-align: center;
        font-weight: bold;

        &.th-group {
            border-bottom: solid 1px $gradient-light-color;
            color: $gradient-light-color;
            vertical-align: bottom;
        }
    }

    .table > tbody > tr > th,
    .table > tbody > tr > td {
        text-align: center;

        &.align-left {
            text-align: left;
        }

        &.align-right {
            text-align: right;
        }
    }

    td {
        padding-bottom: 5px;
    }

    .float-right {
        float: right;
        padding-right: 15px;
    }

    .padding-left {
        padding-left: 20px;
    }

    .margin-left {
        margin-left: 10px;
    }

    .page-title {
        padding: 10px 0 0 10px;
        float: left;
        font-size: 18pt;
        color: #444B4F;
    }

    .title-dealer {
        color: black;
        margin-top: 18px;
        float: left
    }

    .dealer-select {
        padding: 10px 0 0 10px;
        float: left
    }

    hr {
        border-top: 1px solid #1F8BD5;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    a {
        color: #1F8BD5;
    }

    hr:not(.margin-top) {
        margin-top: 10;
    }

    .expire-date {
        color: red;
    }

    .bold {
        font-weight: bold;
    }

    .padding-bottom {
        padding-bottom: 10px;
    }

    .error {
        color: Red !important;
    }

    .edit-box {
        border: 1px solid #ccc;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        height: 20px;
        font-size: 12pt;
        padding: 2px 8px;
        outline: 0;
        -webkit-appearance: none;
    }

    .edit-box-error {
        border: 1px solid red;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        height: 20px;
        font-size: 12pt;
        padding: 2px 8px;
        outline: 0;
        -webkit-appearance: none;
    }

    .validate-error {
        border: 1px solid red;
    }

    .k-grid td {
        /*font-size: 12pt;*/
        font-size: 14px;
        font-family: $kendoGridFontFamily;
        padding-top: 2px;
        padding-bottom: 2px;
        padding-right: 5px;
        padding-left: 5px;
    }

    .k-grid-header th.k-header {
        /*font-size: 12pt;*/
        font-size: 14px;
        font-weight: 500;
    }

    .wish-list-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        background-color: $frame-background-color;
    }

    .divider {
        border-top: 1px solid #1F8BD5;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    .tab-padding {
        margin: 20px 0 0 0;
    }

    .wish-list-padding {
        padding: 0 30px 0px 30px;
        min-height: 900px;
    }

    .header-background {
        background-color: #172751;
    }

    .btn-label {
        color: white;
        text-align: right;
        padding: 10px;
    }

    .small-margin {
        margin: 3px;
    }

    .k-grid table {
        width: 100% !important;
    }

    .svg-icon {
        &.xsm {
            width: 16px;
            height: 16px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }
    }

    .k-grid .k-grid-header .k-header .k-link {
        height: auto;
    }

    .k-grid .k-grid-header .k-header {
        white-space: normal;
    }

    #gridWishList th.k-header.align-center {
        text-align: center;
    }

    #gridWishList th.k-grid-content.align-center {
        text-align: center;
    }
}

.replacement-popup {
    margin: 0px;

    .replacement-popup-list {
        overflow-y: auto;
        height: 180px;
    }
}
