@import 'variables';

$tab-text-color-active: white;

// This is the element that uib-tabset has at its top level
ul.nav.nav-tabs {
    // BEGIN - UI-BOOTSTRAP OVERRIDES //
    // Keep the tabs away from the edge
    padding-left: 30px;
    padding-right: 30px;
    // uib adds the border below the tabs, but we use .tab-content's top edge
    // as the border.
    border-bottom: none !important;
    // END - UI-BOOTSTRAP OVERRIDES //
    // A single tab
    > li.nav-item {
        // uib adds a -1 bottom margin that overlaps tabs with the .tab-content
        // that we don't need.
        margin-bottom: 0 !important;

        > a {
            background-color: $tab-background-color;
            color: $tab-text-color;
            margin-right: 7px;
        }
        // the active tab
        &.active {
            > a {
                background-color: $tab-active-background-color;
                color: $tab-active-text-color;
                margin-right: 7px;
            }
        }
    }
    // The tab's content is not nested inside any element
    + .tab-content {
        // Draw a 5px gradient at the top of each tab
        $background-height: $border-radius-large + 5px;
        border-radius: $border-radius-large;
        background-image: linear-gradient(to bottom, transparent $background-height, white $background-height, white), linear-gradient(to right, $gradient-dark-color, $gradient-light-color);

        > .tab-pane {
            padding: ($background-height + 11px) 10px 10px;
            // The body of the active tab
            &.active {
            }
        }
    }
}
