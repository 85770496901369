﻿@import "../../styles/aa-variables";

search-menu {

    .search-menu-container {
        clear: both;
        background: none;
        top: 10px;
        width: 100%;
        min-width: 270px;

        .search-menu {
            background: none;
            margin-right: 20px;
            margin-left: 10px;
            margin-bottom: 0;

            ul {
                -webkit-padding-start: 0px;
                padding-left: 0px;
            }

            .menu-item {
                border-bottom: 1px solid #005691;
            }
        }
    }



    .menu-category {
        cursor: pointer;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        line-height: 45px;
        padding-left: 7px;

        h5 {
            display: inline-block;
        }

        icon {
            margin-top: 13px;
            margin-bottom: 13px;
            font-size: 15px;
        }
    }

    ul.search-menu {
        padding: 0 0 0 0;

        li {
            list-style: none;
            display: table;
            width: 100%;

            a {
                color: #fff;
                font-family: Roboto, sans-serif;
                font-size: 14px;
                font-weight: 300;
                display: table-cell;
                width: 190px;
                height: 35px;
                vertical-align: middle;
                padding-left: 17px;

                &.active-menu-item {
                    border-bottom: transparent 5px solid;
                    //width: 100%;
                }
            }

            .optionLinks {
                padding-top: 25px;
                padding-left: 7px;
                text-align: left;
                cursor: pointer;
                a {
                    font-size: 12px;
                    padding: 0;
                    display: inline;
                    width: auto;
                }
            }
        }
    }

    .menu-item {
        border-bottom: 1px solid #005691;
    }

    .menu-row:hover {
        color: #c1c2c4 !important;
        background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
        background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
        background: linear-gradient($navyBlue, $light-blue);
        border-bottom: #2d89c9 5px solid;
        cursor: pointer;
    }

    .no-search {
        font-size: 14px;
        color: #fff;
        width: 200px;
        height: 35px;
        padding-top: 7px;
        padding-left: 17px;
    }

    .icon-trash {
        position: absolute;
        right: 28px;
        padding-top: 4px;
    }

    .icon-share {
        position: absolute;
        right: 50px;
        padding-top: 4px;
    }
}