//@import '~src/app/scripts/redactor.css';

#redactor_modal,
.redactor_dropdown {
    // The modal backdrop has a z-index of 1050, so we have to surpass that.
    // This z-index arms race is ridiculous.
    z-index: 2000 !important;
}

// Styles below copied from RedactorCustomCss

.redactor_editor,
.redactor_editor div,
.redactor_editor p,
.redactor_editor ul,
.redactor_editor ol,
.redactor_editor table,
.redactor_editor dl,
.redactor_editor blockquote,
.redactor_editor pre,
.redactor_editor h1,
.redactor_editor h2,
.redactor_editor h3,
.redactor_editor h4,
.redactor_editor h5,
.redactor_editor h6,
#template-body {
    font-family:'Times New Roman', serif;
}

.redactor_editor,
.redactor_editor div,
.redactor_editor p,
.redactor_editor ul,
.redactor_editor ol,
.redactor_editor table,
.redactor_editor dl,
.redactor_editor blockquote,
.redactor_editor pre,
#template-body,
#template-body table {
    font-size: 18px;
    line-height: 1.4em;
}

.redactor_toolbar li a.re-mergeField {
    background-image: url('/content/images/icons/16/insert-merge-field.png');
}
.redactor_toolbar li a.re-mergeField:hover {
    background-image: url('/content/images/icons/16/insert-merge-field-hover.png');
}
.redactor_toolbar li a.re-layout {
    background-image: url('/content/images/icons/16/insert-layout.png');
}
.redactor_toolbar li a.re-layout:hover {
    background-image: url('/content/images/icons/16/insert-layout-hover.png');
}

.redactor_editor .redactor-layout-table,
.redactor_editor .redactor-layout-table td {
    border-style:dashed;border-color:#ddd;border-width:1px
}
.redactor_editor table.redactor-layout-table td,
#template-body table.redactor-layout-table td {
    padding:5px
}
.redactor_editor .redactor-layout-table .col-1,
#template-body .redactor-layout-table .col-1 {
    width:626px
}
.redactor_editor .redactor-layout-table .col-2,
#template-body .redactor-layout-table .col-2 {
    width:313px
}
.redactor_editor .redactor-layout-table .col-3,
#template-body .redactor-layout-table .col-3 {
    width:208px
}

.redactor-mergefield-tooltip {
    position: absolute;z-index: 49999;padding: 10px;line-height: 1;display: inline-block;background-color: #000;color: #555 !important;
}
.redactor-mergefield-tooltip,
.redactor-mergefield-tooltip a {
    font-size: 12px;font-family: Arial, Helvetica, Verdana, Tahoma, sans-serif;
}
.redactor-mergefield-tooltip a {
    color: #ccc;margin: 0 5px;text-decoration: none;
}
.redactor-mergefield-tooltip a:hover {
    color: #fff;
}

.redactor_editor img {
    max-width:none
}
#template-body img, #redactor-image-box img {
    width:auto
}

#template-body {
    margin-top: 100px;
}
.has-letterhead #template-body {
    margin-top: 0px;
}
#template-body table td {vertical-align:top}

.redactor_editor .redactor-button {
    margin-bottom:0 !important
}
.redactor-button table {
    margin:0 auto !important
}
.redactor-button a {
    font-size:16px; font-weight: bold; font-family:sans-serif; text-decoration: none !important; line-height:1em; width:100%; display:inline-block;color:#ffffff !important
}
.redactor_editor .redactor-button td, 
#template-body .redactor-button td,  
#email-wrapper .redactor-button td,
.redactor_editor table.redactor-layout-table .redactor-button td,
#template-body table.redactor-layout-table .redactor-button td {
    padding: 15px !important;
    display: block;
    background-color:#5bb75b;
    border:none;
}

#email-wrapper #template-body {
    margin-top: 0;
}
.email-wrapper-section {
    padding:18px
}

.email-wrapper-section.email-only-content {
    background-color: #f2f2f2;
}
.email-only-content {
    font-size:14px;
    text-align: center;
}
#unsubscribe {
    margin-top:10px
}
#dealer-info {
    margin-top:10px; color: #999
}
