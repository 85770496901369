
$frame-background-color: #E7E7E7;
$panel-height: 590px;

.do-not-call-email-wrapper {
        
    background-color: $frame-background-color;
    padding-left: 15px;
    padding-right: 15px;    
    padding-bottom: 5px;


    .do-not-call-email {
        margin: 15px 15px 10px 15px;
        background-color: white;
        border-radius: 10px 10px;     
        position: relative;
        max-width: calc(100vw - 60px);
    }

    .do-not-call-panel {
        padding-bottom: 0px;
        margin-bottom: 0px;
        
        button {
            margin-right: 5px;            
        }

        > .panel-footer {
            height: 55px;
        }

        label {
            color: #1f8bd5;
            font-size: 16px;
        }

        input[type="radio"] {
            
            margin-right: 5px;
        }

        .type-selection-radio {
            padding-left: 45px;
        }

        .type-selection-title {
            margin-bottom: 5px;
            color: gray;
        }

        .btn-file {
            position: relative;
            overflow: hidden;
            color: white;
        }

        .error-message {
            color: red;

        }

        .status-row {
            bottom: 0;
            position: absolute;
        }
        

        .margin-bottom-15 {
            margin-bottom: 15px;
        }

        .margin-left-15 {
            margin-left: 15px;
        }
        
        .step {
            position: relative;
            height: 100%;

            .table-col {
                overflow: auto;
            }
        }

        .do-not-call-panel-body {
            min-height: $panel-height;
            height: 1px;
            overflow: auto;      
            width: 100%      
        }


table {
    
    .bold {
        font-weight: bold;
    }
  
  th {
      text-align: center;
      padding-right: 5px;
      padding-left: 5px;
  }

  td {
      padding-right: 10px;
  }

  tfoot {
    font-style: italic;
  }

  td, th {
cursor: pointer;
font-size: 15px;
  }

  .hover{
    background-color: #FFFFA3;    
   }

   .selected {
    background-color: #B0DE78;    
   }


tr:nth-child(even)  {background: #e5f1f9 }
th {background: #d0e5f5;border-bottom: 1px solid #b1d6de;color: #808080;white-space:nowrap}
        
    }
}
}