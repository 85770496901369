@import "../../styles/aa-variables";
@import '../../styles/variables';

modal.presetSelector .modal-body {
    padding-top: 0 !important;
}

.preset-select .modal-dialog {
    width: 500px;
}

search-manage-presets {
    font-size: 14px;

    // #gridManageSearches > table // Header and data table.
    // {
    //     table-layout: fixed;
    // }

    .panel-heading {
        font-size: 16px;
    }

    .panel {
        margin-bottom: 5px;
    }

    .row {

        .dealerCol {
            display: flex;

            .dealerLabel {
                padding-right: 10px;
                margin-top: 4px;
                vertical-align: middle;
            }

            .dealerSelect {

                .form-control {
                    width: 300px;
                    font-size: 14px;
                    padding: 2px 12px;
                    height: 26px;
                }
            }
        }

        .expired {
            color: red;
        }
    }

    .data-grid {
        //margin: 15px;
        font-family: $kendoGridFontFamily;
        font-size: 12px;
    }

    .margin-left {
        margin-left: 10px;
    }
}

.preset-grid-container {
    font-size: 12px;
    font-family: $kendoGridFontFamily;

    .k-chart {
        height: 280px;
    }

    .k-grid td {
        white-space: nowrap;
    }

    tbody .k-button {
        min-width: 32px;
    }

    .k-grid th.k-header {
        font-weight: bold;
        vertical-align: middle;
    }

    .k-grid-update .k-icon:before {
        content: "";
    }

    .k-grid-cancel .k-icon:before {
        content: "";
    }

    .k-primary, .k-primary:hover {
        background-color: #337ab7;
        background-image: none;
    }

    #grid-toolbar {
        height: 30px;
    }

    .info {
        padding-left: 4px !important;
        vertical-align: middle !important;
        font-size: 10px !important;
    }

    .svg-icon {
        width: 32px;
        height: 32px;
        padding: 2px;
        margin-bottom: 1px;
        margin-top: 1px;

        &.sm {
            width: 22px;
            height: 22px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }

        &.md {
            width: 36px;
            height: 36px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }

        &.xsm {
            width: 16px;
            height: 16px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }
    }

    span.k-icon.k-i-seek-e, span.k-icon.k-i-arrow-e, span.k-icon.k-i-arrow-w, span.k-icon.k-i-seek-w {
        height: 1.5em;
    }
}

