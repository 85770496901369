﻿.search-save-modal {

    .modal-body {
        width: 750px;
    }

    .searchSaveSearchSaveAs {
        font-size: 20px;
        font-weight: bold;
    }

    .searchAddConfigureSavedSearchForOpportunitySearches, .pandoXOptions {
        padding: 25px 0 0 55px
    }

    .searchAddSavedSearchToTop {
        padding: 8px 0 0 70px
    }

    .searchAddSavedSearchToBottom {
        padding: 8px 0 0px 70px
    }

    .searchSelectRundDatesSection {
        padding: 12px 0 0px 70px
    }

    .searchSelectRundDates {
        padding: 8px 0 8px 70px
    }


    .searchErrorMessages {
        padding: 0px 10px
    }

    .assignPandoXRule {
        padding-bottom: 5px;
    }

    .pandoXOption {
        padding-left: 20px;
        padding-bottom: 5px;

        select {
            padding-bottom: 5px;
        }
    }

    select.form-control, .form-control {
        font-size: 14px;
        height: 30px;
        padding: 0px 12px;
        cursor: pointer;
    }

    label {
        font-size: 16px;
        color: #444a50;
    }

    .pandoXOptionSpacing {
        margin-left: -25px;
        padding-left: 0px;
    }

    .pandoXOptionLabel {
        padding-right: 10px;
        margin-bottom: -5px;
        height: 30px;
        vertical-align: sub;
    }

    .executeImmediately {
        padding-left: 35px;
    }

    .pandoXCategory {
        width: 200px;
    }

    .pandoXRuleQuantity {
        width: 100px;
    }

    .form-control {
        &[disabled] {
            background-color: #eeeeee;
            opacity: 1; // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655
        }
    }

    .datePickerFrom {
        float: left;
        margin: 5px 5px 0 0;
    }

    .datePickerTo {
        float: left;
        margin: 5px 5px 0 10px;
    }

    .btn.calandar {
        top: 0px;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 0px;

        icon.icon {
            color: #fff;
            top: 0;
        }
    }
}
