﻿@import "../../styles/aa-variables";

ul.euro-lite-grid-layout {

        list-style-type: none;

        li {
            padding-left: 10px;
        }

    .bold {
	    font-weight: bold;
    }
}

.euro-lite-grid-layout-title {
    font-size: 16px;
    font-weight: bold;
}


.activity-tooltip-icon {
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size:large;
}

.activity-tooltip-notes {
    font-size: 14px;
    font-weight:100;          
    padding-top: 0px;
    padding-bottom: 4px;
    vertical-align:top;
}

.activity-tooltip-title {
    font-size: 14px;
    font-weight: bold;
    padding-top: 0px;
    padding-bottom: 4px;
    vertical-align:top;
}


.euro-lite-opportunities {
    background-color: #e6e6e6;
    margin: 0px;
    padding: 10px;
    font-size: 14px;
    min-height: 900px;

    .custom-search-input {
        margin-top: 6px;
    }

    .k-detail-row {
        background-color: #a6d1ee;
    }

    .fa-sm {
        display: inline-block;
        font: normal normal normal 18px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 4px;
    }
    /* https://docs.telerik.com/kendo-ui/third-party/using-kendo-with-twitter-bootstrap */
    .kendo-angularjs {
        /* reset everything to the default box model */
        *, :before, :after {
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }
        /* set a border-box model only to elements that need it */
        .form-control, /* if this class is applied to a Kendo UI widget, its layout may change */
        .container,
        .container-fluid,
        .row,
        .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1,
        .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2,
        .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3,
        .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4,
        .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5,
        .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6,
        .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7,
        .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8,
        .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9,
        .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10,
        .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11,
        .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
        }
        /* reset everything to the default box model */
    }

    .icon-o2o-offer {
        margin-top: 7px;

        a.o2o-offer {
            cursor: pointer;
        }
    }

    .nounderline {
        text-decoration: none;
    }

    .noRecordsAvailable {
        font-size: small;
    }

    .k-grid-norecords {
        margin-top: 20px;
    }

    #grid th.k-header.align-right {
        text-align: center;
    }

    .tab-header {
        margin-left: 15px;
    }

    .row {
        padding-bottom: 10px;
    }

    .make-dropdown, .opportunity-dropdown {
        margin-left: 15px;
        display: inline-block;

        .btn {
            background-color: #fff;
            width: 150px;
            text-align: left;

            .caret {
                position: absolute;
                top: 15px;
                right: 5px;
            }
        }

        .option {
            height: 24px;

            .checkboxInput {
                margin-top: 2px;
                vertical-align: middle;
            }

            div {
                margin: 0px;
            }
        }
    }

    .opp-block-horizontal {
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        /*input {
            vertical-align: middle;
        }*/
        &.verticalBar {
            border-right: 1px solid black;
            vertical-align: middle;
            margin-bottom: 4px;
            width: 1px;
        }

        .horizontal-alert-type-label {
            display: inline;
            color: #000000;
            margin-left: 75px;

            label {
                color: #000000;
            }
        }

        .filterlabel {
            color: #000000;
            vertical-align: middle;
            font-weight: 400;
        }

        .btn {
            background-color: #fff;
            width: 190px;
            text-align: left;
            font-size: 14px;
        }

        span {
            font-size: 14px;
            color: #000000;
            /*vertical-align: middle;*/
        }

        opportunity-block-horizontal {
            span {
                color: #337ab7;
            }
        }
    }

    .alert-letter {
        color: #fff;
        font-family: 'Roboto', verdana, arial, sans-serif;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        margin: 0px;
    }

    .grid-layout-links {
        display: inline-block;
        margin-right: 15px;
    }

    .grid-alert {
        float: left;
        margin-right: 5px;
        margin-bottom: -2px;
    }

    .grid-faalert {
        border-radius: 3px;
        background: #f53142;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-faalert:before {
        content: "A";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-faservice {
        border-radius: 3px;
        background: #42b93f;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-faservice:before {
        content: "S";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-facontractend {
        border-radius: 3px;
        background: #226be6;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-facontractend:before {
        content: "C";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-famileage {
        border-radius: 3px;
        background: #fe6e1a;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-famileage:before {
        content: "M";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-fawarranty {
        border-radius: 3px;
        background: #7a5ec8;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-fawarranty:before {
        content: "W";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-faflexalert {
        border-radius: 3px;
        background: #824119;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-faflexalert:before {
        content: "F";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-faappointment {
        border-radius: 3px;
        background: #257F31;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-faappointment:before {
        content: "P";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-fainmarket {
        border-radius: 3px;
        background: #CC8800;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-fainmarket:before {
        content: "I";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-fainmarketengaged {
        border-radius: 3px;
        background: #FCAF26;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

    .grid-fainmarketengaged:before {
        content: "E";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }

    .grid-faalert {
        float: left;
        margin-right: 5px;
        margin-bottom: -2px;
    }

    .payment-change-down {
        background: transparent url("~/images/icons/GreenArrowDown16ng.gif") center right no-repeat;
        padding-right: 20px;
        padding-top: 1px;
        float: right;
        margin-right: 25px;
    }

    .payment-change-up {
        background: transparent url("~/images/icons/GreenArrowUp16ng.gif") center right no-repeat;
        padding-right: 20px;
        padding-top: 1px;
        float: right;
        margin-right: 25px;
    }

    .score {
        font-size: 12pt;
        font-weight: 500;
    }

    .grid-action-menu {
        text-anchor: end;
        text-align: right;
        color: black;


        ul.dropdown-menu {
            min-width: 80px;
            width: 110px;
            top: auto;
            left: auto;

            icon {
                font-size: 10pt;
            }

            li > a {
                margin: auto; /*5px 5px 5px 5px;*/
                margin-left: 8px;
                margin-right: 2px;
                margin-bottom: 2px;
                font-size: 10pt;
                cursor: pointer;
                padding: 0px;
                /*line-height:1.6em;*/
            }
        }

        user-select-action {
            /*label.light {
            color: #000;
            font-size:10pt;
            cursor: pointer;
            margin-left:8px;
        }

        label.hover {
            background-color: #f0f0f0;
        }*/
        }
    }

    .grid-activity-list {
        background: $body-bg;
        border-bottom: 0px #1f8bd5 solid;
        border-top: 0px #1f8bd5 solid;
        padding: 0px;
        margin: 30px;
        margin-top: 0px;

        h5 {
            margin-left: 0px;
        }

        .notes-bar {
            background-color: #1f8bd5;
            color: #ffffff;
            font-weight: 500;
            vertical-align: bottom;
            font-size: 12pt;
            height: 20px;
            padding-top: 5px;
            text-align: center;
        }

        activity-list {
            display: block !important;
            padding: 10px;
            color: $body-base-color;
            font-size: 16px;
            line-height: 1.5em;

            label {
                color: $brightBlue;
                font-size: 12px;
            }
        }
    }

    .grid-customer-connect {
        background: $body-bg;
        border-bottom: 0px #1f8bd5 solid;
        border-top: 0px #1f8bd5 solid;
        padding: 0px;
        margin: 30px;
        margin-top: 0px;
        color: $body-base-color;
        min-width: 500px;
        /*div {
            border: 2px solid red;
        }*/
        .label-top-buffer {
            padding-top: 6px;
            padding-bottom: 5px;
            vertical-align: middle;
            height: 30px;
        }

        customer-connect {

            table {
                padding: 5px;
                width: 100%;
                table-layout: unset;
                min-width: 200px;

                th {
                    padding: 5px;

                    .btn-sm {
                        text-align: center;
                        padding: 2px;
                    }
                }

                tr {
                    text-align: left;
                }

                td {
                    border: 0px;
                    text-align: left;

                    .btn-sm {
                        text-align: center;
                        padding: 2px;
                    }
                }

                tbody {
                    border: none;
                }

                tr:hover {
                    background: transparent;
                }

                tr.k-alt:hover {
                    background: #f1f1f1;
                }

                td.uib-separator {
                    padding: 5px;
                }
            }

            type-of-call {
                font-size: 12pt;
            }
        }

        customer-connect-sub-detail {
            background: $body-bg;
        }

        customer-connect-log-action {
            background: $body-bg;
        }

        customer-connect-schedule-action {
            background: $body-bg;
        }

        span {
            color: gray;

            &.error {
                color: red;
            }
        }

        label {
            color: $brightBlue;
            font-size: 16px;
        }

        div.panel-body {
        }

        div.panel-group {
            border-top: 10px red solid;
            padding: 0px;
            border: 0px;
            margin: 0px;
        }

        div.row {
            margin: 0;
            padding: 0;
        }

        .panel {
            padding-bottom: 0px;
            border: 0px;
        }

        .panel-heading {
            padding: 0px;
            border: 0px;
            margin: 0px;
        }

        .panel-body {
            padding: 5px;
            border: 0px;
            margin: 0px;
            border-top: 2px $brightBlue solid;
        }

        .action-bar {
            background-color: #1f8bd5;
            color: #ffffff;
            font-weight: 500;
            vertical-align: bottom;
            font-size: 12pt;
            height: 20px;
            padding-top: 5px;
        }

        .action-menu {
            border-bottom: 1px $gray-lighter solid;
            padding: 10px;
            padding-bottom: 0px;
        }

        .selectedbar {
            border-bottom: 4px solid #008be8;
        }

        .timeTicker {
            margin-top: 35px;
        }

        .bottom-column {
            margin-top: 50px;
            /*vertical-align: bottom;*/
        }

        .bottom-column-schedule {
            margin-top: 100px;
            /*vertical-align: bottom;*/
        }

        .top-buffer {
            padding-top: 6px;
        }

        .comm-tools {
            display: inline-block;
            margin-left: 15px;

            icon.icon {
                top: 0;
            }

            &:hover {
                .caption {
                    text-decoration: none;
                }
            }

            .wide-tools {
                padding-left: 50px;
                padding-right: 0px;
                color: $brightBlue;

                label {
                    color: $brightBlue;
                }
            }
        }
    }


    .k-grid td {
        font-size: 10pt;
        font-weight: normal;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .k-grid-header th.k-header {
        font-size: 10pt;
        font-weight: 500;
    }

    .k-grid tr.k-state-selected:hover {
        background-color: #a6d1ee;
    }

    .k-grid tr.k-state-selected {
        background-color: #a6d1ee;
        color: $body-base-color;
    }

    .k-grid .k-hierarchy-col, .k-grid .k-hierarchy-cell k-header {
        width: 0px;
        border: none;
    }

    .k-grid .k-detail table {
    }

    .k-grid tr:hover {
        background-color: #e6e6e6;
    }

    /* allow grid header text wrap*/
    .k-grid .k-grid-header .k-header .k-link {
        height: auto;
    }

    .k-grid .k-grid-header .k-header {
        white-space: normal;
        vertical-align: text-top;
    }
}
