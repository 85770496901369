@import "variables";

// Import bootstrap
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/"; // Forces the import path of Bootstrap URLs
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,700i,900,900i");
@import 'angular2-toaster/src/toaster.scss';

* {
    box-sizing: border-box;
}

body {
    background: -webkit-gradient(linear, left top, right bottom, from($navyBlue), to(#1f8ad5)) fixed;
    background: gradient(linear, left top, right bottom, from($navyBlue), to(#1f8ad5)) fixed;
    background: linear-gradient(to top,#1f8ad5, $navyBlue) fixed;
    font-family: 'Roboto', sans-serif;
}

main-menu {
    padding-left: 0;
    margin: 0;
    display: inline-block;
}

@mixin bold {
    font-weight: $bold-font-weight;
}

$header-min-height: 100px;
$header-responsive-breakpoint: 1200px;
$header-context-font-size: 14px;
$header-context-height: 30px;
$header-context-line-height: 30px;
$header-context-avatar-diameter: 28px;
$header-logo-width: 280px;
$header-logo-padding: 40px;
$header-skew-angle: -40deg;
$header-tab-padding: 20px;
$header-background-color: #002d4b;
$header-widget-border-radius: 5px;

@mixin header-background-gradient {
    background: linear-gradient(to bottom, #005691 0%,#002d4b 100%);
    background: linear-gradient(to bottom, #005691, #002d4b);
}

///////////////////////////////////////////
//              STRUCTURE                //
// DISPLAY, POSITIONING, MARGIN, PADDING //
//         PAGE RESPONSIVENESS           //
///////////////////////////////////////////

#aa-header {
    // Per Levko's direction, we are to have a
    // min-width of 800 until we can work on mobile
    min-width: 768px !important;
    width: 100%;
    position: relative;
    // The user context, pinned to the upper-left
    &-context {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        text-align: right;
        height: $header-context-height;
        padding-right: 20px; // IE11: The scrollbar covers up part of the block if we don't indent it
        z-index: 1; // Lift this element above the &-menu element
        > * {
            display: inline-block;
            margin-left: 35px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    // The AutoAlert logo.
    &-logo {
        height: $header-min-height;
        width: $header-logo-width;
        padding-left: 5px;

        @media screen and (min-width: $header-responsive-breakpoint) {
            float: left;
        }
    }

    &-skewcontainer {
        display: none; // Hide when the screen is too narrow
        @media screen and (min-width: $header-responsive-breakpoint) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            left: $header-logo-width + $header-logo-padding;
            height: $header-min-height;
            overflow: hidden; // This cuts off the right corner so it doesn't extend too far
            &-skew {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                transform: skewX($header-skew-angle);
                transform-origin: bottom;
            }
        }
    }
    // The main application tabs
    &-menu {
        @media screen and (min-width: $header-responsive-breakpoint) {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            padding-top: $header-context-height;
            margin-left: $header-logo-width + $header-logo-padding;
            height: $header-min-height;
        }

        &-container {
            @media screen and (min-width: $header-responsive-breakpoint) {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                padding: 0 $header-tab-padding;
            }

            & > main-menu {
                // Prevents the gutter from tucking too close
                // underneath the corner of the last tab
                margin-right: 1.1 * $header-tab-padding;
            }
        }

        .nav-angle {
            display: inline-block;
            position: relative;
            transform: skewX($header-skew-angle);
            transform-origin: bottom;

            .angle-text {
                transform: skewX(-$header-skew-angle);
            }
        }
    }
    // The application-defined sub-menu
    &-submenu {
        clear: both;
    }
}

////////////////////////////
//         STYLES         //
// COLORS, FONTS, BORDERS //
////////////////////////////

#aa-header {
    background-color: $header-background-color;
    color: white;

    &-context {
        // The user context, pinned to the upper-left
        font-size: $header-context-font-size;
        font-weight: $bold-font-weight;

        .small-icon {
            font-size: 10px;
        }

        .pando-inbox {
           // background-color:red;
            a {
                padding-right:10px;
            }
        }
    }

    &-logo {
        // The AutoAlert logo.
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            flex-shrink: 0;
            width: 100%;
        }
    }
    // No content, creates the skewed background
    &-skewcontainer {
        &-skew {
            @include header-background-gradient;
        }
    }
    // The main application tabs
    &-menu {
        @include header-background-gradient;

        @media screen and (min-width: $header-responsive-breakpoint) {
            background: transparent;
        }

        .nav-angle {
            cursor: pointer;
            text-decoration: none;
            padding: 15px 20px 10px;
            font-family: 'Roboto', sans-serif;
            font-weight: bold;
            font-size: 16px;
            display: inline-block;
            color: #fff;
            background: transparent;
            position: relative;
            border-bottom: transparent 5px solid;

            .angle-text {
            }

            &:hover {
                color: #c1c2c4;
                background: #182752;
                border-bottom: #2d89c9 5px solid;

                .aa-icon-top-nav {
                    color: #fff;
                }
            }

            &:focus {
                color: #c1c2c4;
                background: #182752;
                border-bottom: #2d89c9 5px solid;

                .aa-icon-top-nav {
                    color: #fff;
                }
            }


            &.opportunities.active-header, &.reports.active-header {
                border-color: #34699e;
            }

            &.opportunities.active-header:focus, &.reports.active-header:focus {
                color: #c1c2c4;
                background: #182752;
                border-bottom: #2d89c9 5px solid;
            }

            &.opportunities {
                &:hover {
                    border-color: #34699e;
                }
            }

            &.social {
                &:hover {
                    border-color: #1f8bd6;
                }
            }

            &.social.active-header {
                border-color: #1f8bd6;
            }

            &.campaign {
                &:hover {
                    border-color: #6f60aa;
                }
            }


            &.campaign.active-header {
                border-color: #6f60aa;
            }

            &.communication {
                &:hover {
                    border-color: #1fc2e6;
                }
            }

            &.communication.active-header {
                border-color: #1fc2e6;
            }

            &.active-header {
                color: #c1c2c4;
                background: #182752;
                border-bottom: #2d89c9 5px solid;

                .aa-icon-top-nav {
                    color: #fff;
                }
            }
        }
    }
    // The application-defined sub-menu
    &-submenu {
    }
}

#aa-header-live-support {
    .chat {
        display: block;
        position: relative;
        top: -3px;

        &.disabled {
            display: none;
        }
    }

    a {
        @include bold;
        background-color: $gradient-light-color;
        color: $reverse-text-color;
        padding: 3px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 3px 10px;

        &:hover {
            background: #34699e;
            color: $reverse-text-color;
            text-decoration: none;
        }
    }
}

#aa-header-user {
    position: relative;

    &-name {
        text-align: center;
        vertical-align: middle;
        padding: 1px 10px;
        border-radius: 0 0 $header-widget-border-radius $header-widget-border-radius;

        &:active, &:hover, &.active {
            background-color: $header-hover-background-color;
            color: $header-hover-text-color;
        }

        * {
            // The baselines of all the text inside <header-user-context> are aligning.
            // I think this is because they're inline-blocks.
            // Since we expect a single row of text at most, we can align them to the middle
            // of their blocks and it should look reasonable.
            line-height: 30px;
            vertical-align: middle;
        }
    }

    &-avatar {
        $diameter: $header-context-avatar-diameter;
        display: inline-block;
        width: $diameter;
        height: $diameter;
        margin-right: 5px;
        border-radius: $diameter / 2;
        overflow: hidden;
        background-color: transparent;
        background-size: 100% 100%;
        background-image: url('/images/avatar.png');
    }

    &-dropdown {
        position: absolute;
        top: 30px;
        right: 0;
        z-index: 3;
        color: $main-text-color;
        padding: 0.25em 0.5em;
        background: $main-background-color;
        text-align: left;
        box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
        border-radius: $header-widget-border-radius;

        ul {
            list-style-type: none;
            padding: 5px 10px;
        }

        li {
            margin-top: 5px;
            font-weight: $bold-font-weight;
            white-space: nowrap;

            &:first-child {
                margin-top: inherit;
            }

            a {
                color: $body-base-color;
                padding-right: 3em; // This padding is where the icon lives
            }

            hr {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
}


.icon-class,
icon {
    // POSITIONING

    // Fontastic icons
    &:not([icon]) {
        // Icons should be aligned in the middle of the text, not with text-top
        vertical-align: middle;
        line-height: 1;
        }

    // SVG icons
    &[icon] {
        vertical-align: sub;
    }

    // SIZES

    @mixin size-rect($pixel-size-width, $pixel-size-height: $pixel-size-width, $font-size: $pixel-size-height + 4px) {
        font-size: $font-size;
        svg {
            width: $pixel-size-width;
            height: $pixel-size-height;
        }
    }

    @mixin size($pixel-size, $font-size: $pixel-size + 4px) {
        @include size-rect($pixel-size, $pixel-size, $font-size);
    }


    // The default size
    @include size($font-size-base);

    &.-large {
        @include size(35px, 30px);
    }

    &.-medium {
        @include size(25px, 25px);
    }

    &.-smaller {
        @include size(10px);
    }

    &.-small {
        @include size(15px);
    }

    &.-size-18 {
        @include size(18px);
    }
    &.-size-18-svg {
        @include size(18px,0px);
    }

    &.-size-20 {
        @include size(20px);
    }
    &.-size-20-svg {
        @include size(20px,0px);
    }

    &.-size-22 {
        @include size(22px);
    }
    &.-size-22-svg {
        @include size(22px,0px);
    }

    &.-size-24 {
        @include size(24px);
    }
    &.-size-24-svg {
        @include size(24px,0px);
    }

    &.-size-25 {
        @include size(25px);
    }
    &.-size-25-svg {
        @include size(25px,0px);
    }


    &.-size-27 {
        @include size(27px);
    }
    &.-size-27-svg {
        @include size(27px,0px);
    }

    &.-size-34 {
        font-size: 34px;
        &[icon] {
            font-size: 28px;
        }
        svg {
            width: 40px;
            height: 30px;
        }
    }

    &.-size-wish-list {
        font-size: 35px;
        padding: 0px 4px;
        vertical-align: middle;
        line-height: 1;

        &[icon] {
            font-size: 35px;
        }

        svg {
            width: 27px;
            height: 35px;
        }
    }

    &.-size-33-30 {
        @include size-rect(33px,30px);
    }

    &.-activity-rating-stars {
        svg {
            width: 80px;
            height: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }


    &.-size-rating40-svg {
        font-size: 4px;
        svg {
            width: 40px;
            height: 18px;
        }
    }

    // COLORS

    @mixin color($name, $color) {
        &.#{$name} {
            & svg .cls-1,
            &:not([icon]) {
                fill: $color;
                color: $color;
            }
        }
        &.-hover#{$name}:hover {
            & svg .cls-1,
            &:not([icon]) {
                fill: $color;
                color: $color;
            }
        }
    }

    @include color('-white', #fff);
    @include color('-brightblue', $brightBlue);
    @include color('-pandoblue', $pandoBlue);
}

#toast-container > .toast {
    background-image: none !important;
    background-color: white;
    color: black;
    opacity: 1.0;
    padding: 10px;
    border-left-style: solid;
    border-left-width: 35px;
    border-radius: 8px;
    font-size: 12px;

    .toast-close-button {
        right: 0em;
        top: -0.09em;
        margin-right: 0px;
        opacity: 0.6;
    }

    .icon-info, .icon-error, .icon-success, .icon-warning {
        background-image: none !important;
    }
    /*.icon-error {
        background-image: none !important;
    }

    .icon-success {
        background-image: none !important;
    }

    .icon-warning {
        background-image: none !important;
    }*/
}






/*need this overwrite to overwrite the bootstrap margins*/
.row.sub-nav {
    margin: 0 !important;
}

.nav > li {
    display: inline-block;
}

.nav-sub {
    background: -webkit-linear-gradient(to right, #182752, #1fc2e6);
    background: -o-linear-gradient(to left, #182752, #1fc2e6);
    background: -moz-linear-gradient(to left, #182752, #1fc2e6);
    background: linear-gradient(to right, #182752, #1fc2e6);
    margin: 0;
    display: table;
    width: 100%;

    @media screen and (max-width: 1130px) {
        width: 100%;
        text-align: left;
        display: block;
        height: 100px;
    }

    .navbar {
        border: transparent 0px;
        margin: 0;
    }

    .navbar-default {
        background: transparent;
        height: 49px;
    }

    .nav-tools {
        display: table-cell;
        vertical-align: middle;

        @media screen and (max-width: 1130px) {
            width: 100%;
            display: block;
            text-align: left;
        }

        .nav-tools-wrapper {
            display: table;
            float: right;

            @media screen and (max-width: 1130px) {
                float: left;
                padding-left: 15px;
            }
        }

        .stock-number-search {
            display: table-cell;

            a.stock-info {
                width: 400px;

                .stock-data {
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    -ms-text-overflow: ellipsis;
                    -o-text-overflow: ellipsis;
                    text-overflow: ellipsis;

                    &.stock-no {
                        width: 100px;
                    }

                    &.stock-full-trim {
                        width: 200px;
                    }

                    &.stock-new-used-cpo {
                        width: 50px;
                    }

                    .ng-scope {
                        width: 100%;
                    }
                }
            }

            icon.glyphicon.glyphicon-refresh {
                position: absolute;
                right: 8px;
                top: 10px;
                color: #555555;
            }
        }
    }

    .sub-menu {
        display: table-cell;

        @media screen and (max-width: 1130px) {
            width: 100%;
            min-width: 768px;
        }

        .dropdown {
            a {
                text-decoration: none;
                display: inline-block;
                vertical-align: middle;
                line-height: 44px;
                padding: 0 20px;
                color: #fff !important;
                border-bottom: transparent 5px solid;
                width: 100%;

                &:focus {
                    color: #c1c2c4 !important;
                    background: -webkit-linear-gradient(to bottom, #192c54 0%,#00548f 100%);
                    background: linear-gradient(to bottom, #192c54 0%,#192c54 100%);
                    background: linear-gradient(#192c54, #00548f);
                    border-bottom: #2d89c9 5px solid;
                }

                &:hover {
                    color: #c1c2c4 !important;
                    background: -webkit-linear-gradient(to bottom, #192c54 0%,#00548f 100%);
                    background: linear-gradient(to bottom, #192c54 0%,#192c54 100%);
                    background: linear-gradient(#192c54, #00548f);
                    border-bottom: #2d89c9 5px solid;
                }
            }
        }

        li.dropdown:hover > ul.dropdown-menu {
            display: block;
        }

        li.dropdown .dropdown-menu.clicked {
            display: none;
        }

        .dropdown-menu {
            background: #182752;
            font-size: 14px;
        }
    }

    .navbar-nav > li > .dropdown-menu a {
        line-height: 24px;
    }

    .navbar-default .navbar-nav > .open > a,
    .navbar-default .navbar-nav > .open > a:focus,
    .navbar-default .navbar-nav > .open > a:hover {
        // remove Bootstrap's greyed-out style on .open dropdowns
        background: inherit;
    }

    .navbar {
        height: 25px;
    }

    .btn-primary {
        background: none;
        border: transparent;
    }
}

.error {
    color: red;
}

.toast-warning {
    border-left-color: #ffc702;

    .toast-title {
        color: #ffc702;
    }
}

.toast-error {
    border-left-color:red;

    .toast-title {
        color: crimson;
    }
}

.toast-success {
    border-left-color: #339922;

    .toast-title {
        color: #339922;
    }
}

.toast-close-button {
    color: #000;
    opacity: 0.6;
    margin-right: 5px;
}

.clickable {
    cursor: pointer;
}

.header-modal-style {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    background-color: $main-background-color;
    color: $main-text-color;
}

.header-modal-body {
    padding: 15px;
    width: 100%;
}

.header-modal-h5 {
    background: #34699e;
    color: #fff;
    border-radius: 5px;
    text-align: left;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5px 10px;
}

h5 {
    font-size: 18px;
    line-height: 1.1;
}

.btn {
    border: none;
    padding: 6px 15px;
}

// ui-select
.ui-select-multiple.ui-select-bootstrap > div {
    // overflow-y: hidden;
}
.ui-select-container {
    .ui-select-match-item {
        // display: inline-block;
        //border: solid 1px red;

        &:hover {
            // background-color: #e6e6e6;
        }
    }

    .ui-select-match-close {
        // display: inline-block;
        // float: right;
    }
}

.impersonation-status {
    display: block;
    clear: both;
    text-align: center;
    background-color: $main-background-color;
    color: $gradient-light-color;
    span {
        line-height: 35px;
        &.icon {
            vertical-align: sub;
            margin-right: 10px;
        }
    }
    a {
        color: $gradient-light-color;
    }
    .separator {
        margin: 0 10px;
    }
}

.aa-header-modal-mask {
    background-color: $modal-background-color;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3; // Stack above <aa-header>, but below modal windows
}

dealer-list {
    position: relative;
    border-radius: 0 0 5px 5px;
    padding: 3px 10px;
    top: -3px;

    .ui-select-container-item {
        span {
            font-size: 15px;
        }
        div {
            font-size: 14px;
        }
    }

    // Contains the entire ui-select element
    .ui-select-container {
        width: 350px !important;
        display: inline-block;
        vertical-align: bottom;
    }

    // The input box
    .ui-select-search {
        width: 100%;
    }

    .select-button {
        display: block;
        text-align: left;
    }

    &:hover {
        background-color: $header-hover-background-color;
        color: $header-hover-text-color;
    }

    .user-dropdowns {
        .selection-buttons {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }
    }

    .spinner {
        height: 30px;
    }

    .button-span {
        text-align: right;
        margin-left: 20px;
        margin-right: 20px;
    }

    a.dealer-name-link {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }

        .glyphicon {
            padding-left: 5px;
        }
    }

    .dealer-name-window {
        background-color: $main-background-color;

        width: 550px;
        position: absolute !important;
        top: 25px;
        right: 0;
        z-index: 4;
        text-align: left !important;

        .header-modal-h5 {
            background-color: #34699e;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            font-size: 18px;
        }

        .dealer-group-name {
            font-weight: bold;
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        ul {
            padding-left: 10px;

            li {
                list-style: none;
            }
        }
    }
}

@mixin no-btn-outline() {
    &:active,
    &:focus,
    &.active,
    &.focus {
        outline: none !important;
    }
}

filter-button {
    $selected-bg-color: #1f8bd5;
    text-align: center;

    > .btn {
        display: inline-block;
        padding: 6px 15px;
        border: none;
        border-radius: 0;
        background-color: transparent;
        font-size: $font-size-small;
        @include no-btn-outline();
    }

    > .btn:active,
    > .btn:focus,
    > .btn:hover {
        border-radius: 0;
        color: inherit;
    }

    > .btn.active {
        background-color: $selected-bg-color;
        color: #fff;
    }

    > .btn.active:active {
        background-color: darken($selected-bg-color, 15%);
    }
}

// Pulls the badge slightly left so it overlays the item it's
// associated with
.badge.badge-overlay {
    background-color: red;
    margin-left: -8px;
    margin-top: 6px;
    font-size: 10px;
}

customer-engagement-widget {
    > .toggle {
        cursor: pointer;
    }
}

//customer engagement modal


@media only screen and (min-width: 500px)  {
    .modal-mdsm {
        width: 590px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modal-mdsm {
        width: 590px;
        outline: none;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
    .modal-mdsm {
        width: 590px;
        outline: none;
    }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
    .modal-mdsm {
        width: 590px;
        right: -100px;
        outline: none;
    }
}

@media only screen and (min-width: 1600px) and (max-width: 1700px) {
    .modal-mdsm {
        width: 590px;
        right: -150px;
        outline: none;
    }
}

@media only screen and (min-width: 1700px) and (max-width: 1800px) {
    .modal-mdsm {
        width: 590px;
        right: -200px;
        outline: none;
    }
}

@media only screen and (min-width: 1800px) {
    .modal-mdsm {
        width: 590px;
        right: -300px;
        outline: none;
    }
}


customer-engagement-widget-window {
    // make the scrollbar flush to right side
    modal > .modal-body {
        padding: 5px 0 0 12px !important;
    }

    modal > .modal-title-bar > .modal-title-bar-container > .modal-header-extras {
        text-align: right;
    }

    text-align: left;

    .badge.badge-overlay {
        background-color: transparent;
        margin-left: -8px;
        margin-top: 8px;
        font-size: 10px;
        font-weight: 200;
        color: inherit;
    }

    .filterbuttons {
        color: $brightBlue;
        margin-bottom: 8px;
        margin-right: 12px;
        border-bottom: 1px solid $brightBlue;
    }

    .hour-selector {
        display: inline-block;
        // This would normally just be from .form-control, but that
        // class adds a black drop-down arrow and black font-color
        // that we don't want.
        > select {
            color: $brightBlue;
            border-radius: 8px;
            padding: 3px;
        }
    }

    .itemhead {
        margin: 0 0 0 -15px;
        font-size: 11px;
        color: gray;
        white-space: nowrap;
        vertical-align: bottom;

        .hotfiltercheckbox {
            text-align: right;

            > label {
                font-size: 12px;
            }
        }

        .sortradio {
            > label {
                font-size: 12px;
            }
        }
    }

    .itemlist {
        font-size: 14px;
        max-height: 400px;
        min-height: 65px;
        display: block;
        overflow-y: auto;
        overflow-x: hidden;

        > :hover {
            background-color: #e0e0e0;
        }

        .customerengagementitem {
            border-top: 1px solid #ddd;
            font-size: smaller;
            padding: 3px 10px 3px 15px;

            > .row > .customerblock {
                margin-bottom: -7px;
                line-height: 18px
            }

            > .row > .text > .customername {
                font-weight: bold;
                font-size: 14px;
                color: $brightBlue;
                pointer-events: none;
            }

            > .row .engagementdetail .lastengagement {
                font-size: 11px;
            }

            > .row .engagementdetail .itemdate {
                padding-top: 16px
            }

            > .row .engagementdetail .iconsalignment {
                margin-bottom: -6px;
            }

            > .new {
                font-weight: bold;
            }

            > .icon {
                text-align: center;
            }
        }
    }
}

customer-engagements-by-entity-modal {
    modal > .modal-body {
        padding: 10px 15px 10px 15px !important;
        font-size: 12px;
        color: inherit;
    }
    .customername {
        font-size: 14px; 
        font-weight: bold;
        margin-bottom: 5px;
        color: $brightBlue;
    }
}
