﻿@import '../../styles/variables';

$font-size: 12px;
$frame-background-color: #E7E7E7;

.wish-list-matches-modal {

    .k-grid {
        font-size: 12px;
    }

    .grid-alert {
        float: left;
        margin-right: 5px;
        margin-top: 2px;
        margin-bottom: -4px;
    }

    .wish-list-matches-padding {
        padding: 0 30px 0px 30px;
        //min-height: 900px;
    }

    .wish-list-matches-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        /*        background-color: $frame-background-color;*/
    }


    .bold {
        font-weight: bold
    }

    .svg-icon {
        &.xsm {
            width: 16px;
            height: 16px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }
    }

    .deal-sheet-link {
        text-decoration: underline;
        font-weight: bold;
    }

    .faalert {
        border-radius: 3px;
        background: #f53142;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .faalert:before {
        content: "A";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
*/
    .faservice {
        border-radius: 3px;
        background: #42b93f;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .faservice:before {
        content: "S";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
*/
    .facontractend {
        border-radius: 3px;
        background: #226be6;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .facontractend:before {
        content: "C";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
*/
    .famileage {
        border-radius: 3px;
        background: #fe6e1a;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .famileage:before {
        content: "M";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }
*/
    .fawarranty {
        border-radius: 3px;
        background: #7a5ec8;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .fawarranty:before {
        content: "W";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }*/

    .fafflexalert {
        border-radius: 3px;
        background: #824119;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .faflexalert:before {
        content: "F";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }*/

    .faappointment {
        border-radius: 3px;
        background: #257F31;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .faappointment:before {
        content: "P";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }*/

    .fainmarket {
        border-radius: 3px;
        background: #CC8800;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .fainmarket:before {
        content: "I";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }*/

    .fainmarketengaged {
        border-radius: 3px;
        background: #FCAF26;
        width: 18px;
        height: 18px;
        line-height: 18px !important;
        text-align: center;
        margin-top: 0px;
    }

/*    .fainmarketengaged:before {
        content: "E";
        color: #fff;
        font-family: verdana, arial, sans-serif;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
    }*/
}
