@import "../../styles/aa-variables";

.search-export-epsilon-result-modal .modal-dialog {
    width: 1000px;
}

.search-export-epsilon-result-modal .row .tablescroll {
    max-height: 300px;
    overflow-y: auto;
}

.search-export-epsilon-result-modal .row .toppad {
    padding-top: 20px;
}