@import "../../styles/aa-variables";

body {
    /* prevent ie11 scrollbar from covering up content */
    -ms-overflow-style: scrollbar;
}

search-form {
    .cg-busy-widget-container .cg-busy-default-sign {
        border-radius: 10px;
        top: 40%;
    }

    .cg-busy-backdrop {
        left: -10px;
        right: -10px;
        height: 100%;
    }

    .search-form {
        /* remove extra arrow in IE11 */
        select::-ms-expand {
            display: none;
        }

        min-width: 1350px;
        background-color: #FFF !important;
        /*background-color: #ffffff;*/
        margin: 0;
        padding: 10px 0 10px 0;
        font-size: 14px;

        .row {
            height: 35px;

            .dealerCol {
                display: flex;

                .dealerLabel {
                    padding-right: 10px;
                    margin-top: 4px;
                }

                .dealerSelect {

                    .form-control {
                        width: 235px;
                    }
                }
            }
        }

        .dealerRow {
            padding-left: 15px;
        }

        .fa-sm {
            display: inline-block;
            font: normal normal normal 18px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 4px;
        }

        a.o2o-offer {
            cursor: pointer;
        }

        .nounderline {
            text-decoration: none;
        }

        .noRecordsAvailable {
            font-size: small;
        }

        .k-grid-norecords {
            margin-top: 20px;
        }

        #grid th.k-header.align-right {
            text-align: center;
        }

        .tab-header {
            margin-left: 15px;
        }

        .row {
            padding-bottom: 10px;
        }



        .search-multiselect {
            cursor: pointer;
            display: inline-block;

            .btn {
                border: solid 1px #ccc;
                background-color: #fff;
                min-width: 150px;
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: left;
                height: 28px;

                .caret {
                    position: absolute;
                    top: 15px;
                    right: 5px;
                }
            }

            .option {
                height: 24px;

                .checkboxInput {
                    margin-top: 2px;
                    vertical-align: middle;
                    color: #000;
                }

                div {
                    margin: 0px;
                }
            }
        }

        .input-sm {
            border: solid 1px #ccc;
        }

        .input-sm.textbox {
            height: 25px;
            font-size: 12px;
            width: 150px;
        }

        .input-sm.textboxRange {
            height: 25px;
            font-size: 12px;
            width: 65px;
            margin-top: 1px;
        }

        .textboxRangeSeparator {
            float: left;
            margin: 5px 5px 0 5px
        }

        .textboxRangeRightCharacter {
            float: left;
            margin: 2px 0 0 2px
        }

        .btn {
            font-size: 14px;
        }

        .btn-search {
            font-size: 20px;
            padding: 5px 10px;
        }

        .faalert, .faservice, .facontractend, .famileage, .fawarranty, .faflexalert, .faappointment, .fainmarket, .fainmarketengaged {
            width: 22px;
            height: 22px;
        }

        .sectionTitle {
            color: #1F8BD5;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .form-control {
            font-size: 14px;
            height: 26px;
            padding: 2px 12px;
            cursor: pointer;
            width: 150px;
        }

        icon.icon.sm {
            font-size: 14px;
        }

        label {
            color: #333 !important;
        }

        icon {
            font-size: 20px;
        }

        .invalid {
            border-color: red;
        }

        .textboxRangeSelect {
            width: 65px;
            margin-top: 1px;
        }

        .popover {
            width: 500px;
        }

        .scrollable-dropdown-menu .dropdown-menu {
            max-height: 200px;
            overflow-y: auto;
        }

        .collapseWidget {
            color: #1F8BD5;
        }

        .collapseWidgetContainer icon {
            margin: 3px 0 0 0;
            cursor: pointer;
        }

        .searchDisplayNameNarrow {
            margin-top: 3px;
            float: left;
            width: 80px;
        }

        .searchDisplayNameMedium {
            margin-top: 3px;
            float: left;
            width: 100px;
        }

        .searchDisplayNameWide {
            margin-top: 3px;
            float: left;
            width: 125px;
        }

        .searchDisplayNameWide2 {
            margin-top: 3px;
            float: left;
            width: 135px;
        }

        .fieldWidthSmall {
            width: 305px;
        }

        .fieldHeightSmall {
            height: 10px;
        }

        .fieldWidthSmall2 {
            width: 325px;
        }

        .searchCheckBoxListCheckBox {
            margin: 2px 0 0 4px;
        }

        .searchCheckBoxListContainer {
            float: left;
            margin: 2px 10px 0 0;
        }

        .floatl {
            float: left;
        }

        .separatorLine {
            border-top: solid 1px #CCC;
            margin-bottom: 5px;
        }

        .emptyLine {
            height: 15px;
        }

        .pointer {
            cursor: pointer;
        }

        .datePickerFrom {
            float: left;
            margin: 5px 5px 0 0;
        }

        .datePickerTo {
            float: left;
            margin: 5px 5px 0 10px;
        }

        .searchDateInput {
            height: 26px !important;
        }
        /* MultiSelects are still clickable even when disabled in IE11.  This is to fix that issue. */
        .disablePointerEvent {
            pointer-events: none;
        }

        .showBlocksOnly {
            margin-top: -10px;
        }
    }

    .searchButtonContainer {
        float: left;
        margin: 10px 0 10px 0;
    }

    .clearAllLinkContainer {
        float: left;
        margin: 15px 0 0 20px
    }

    .centerButtonsContainer {
        margin: 10px 0;
    }

    .rightButtonsContainer {
        margin: 10px 0 0 0;
    }

    .content-body {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .moreButton {
        min-width: 160px;
        margin-left: -70px;
        cursor: pointer;
    }

    .btn-one2one {
        height: 34px !important;
        width: 120px !important;
        background-image: url("~/images/one-to-one-btn-bg.png") !important;
        background-color: #337ab7 !important;
        background-size: 80% 60% !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
    }

    .btn-epsilon {
        height: 34px !important;
        width: 221px !important;
        background-image: url("~/images/Epsilon_IndAuto_White_2023.png") !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
        background-size: contain !important;
    }

    .dropdown-menu {
        font-size: 14px;
        height: auto !important;
        max-height: 300px !important;
    }

    .ifrmSearchResultsContainer {
        padding-left: 0;
        padding-right: 0;
    }

    .ifrmSearchResults {
        width: 100%;
        height: 850px
    }
}
