﻿@import "../../styles/aa-variables";

reports .quick-links {
    height: 100%;
}



reports-container ul li a.active-menu-item {
    color: $activeMenuItems !important;
    background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
    background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
    background: linear-gradient($navyBlue, $light-blue);
    border-bottom: #2d89c9 5px solid;
}

.reports-header {
    background: linear-gradient(to right, $navyBlue, $brightBlue);
    color: #fff;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 42px;


}

.reports-menu {
    padding: 10px 0 0 0;
    background: linear-gradient(to top, $navyBlue, $brightBlue);
    h3 {
        color: #fff;
        font-size: 18px;
        margin-top: 0;
    }
    &.fixed {
        min-width: 200px;
    }
}

.iframe-container {
    //height: calc(100vh - 140px);
    height: 100%;
}

.page-body {
    display: table;
    height: calc(100vh - 140px); //  header height: 100px   footer height: 40px
    width: 100%;

    h5, h6, label {
        color: white;
    }

    .icon {
        color: white;
        position: absolute;
    }

    > .row {
        height: 100%;
        display: table-row;
    }

    > .row .no-float {
        display: table-cell;
        float: none;
    }

    > .row .reports-background {
        padding-top: 10px !important;
        vertical-align: top;
    }

    .icon {
        position: relative !important;
    }
}

.reports-background {
    background: $reportsBackground;

    .report-error-message {
       color: #444b4f;
       font-size: 20px;
       margin-top: 20px;
       margin-left: 20px;
    }
}

.reports-container {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /*margin-top: 15px;*/
    .no-float {
        vertical-align: top;
    }

    h3 {
        font-size: 1.25em;
        margin: 0;
        padding-left: 15px;
    }

    .dash {
        margin: 0;

        .row {
            margin-left: 0;
            margin-right: 0;
        }

        .filters {
            padding-top: 5px;



            .display-type {
                /*margin-left: 5px;*/
                label {
                    color: #444b4f;
                    font-size: 0.66em;
                    margin-bottom: 0;
                }
            }

            .date-picker-container {
                display: table;

                .date-type {
                    padding: 0 5px 0 5px;
                    display: table-cell;
                    vertical-align: middle;
                    /* TODO implement globally */
                    background-color: white;

                    .icon {
                        font-size: 24px;
                    }
                    /* END implement globally */
                    .refresh {
                        color: #3faf49;
                        cursor: pointer;
                    }

                    &.dates-to {
                        padding: 13px 0 0 0;
                    }
                }
            }

            .fancy-button {
                background: white;
                color: #2e9feb;
                border: none;
                border-bottom: 4px solid #f0f0f0;
                font-size: 14px;
                margin: 0 1px;
                min-width: 38px;

                &.selected:enabled, &:enabled:hover {
                    outline: 0;
                    font-weight: 300;
                    color: #7a8084;
                    border-bottom: 4px solid #008be8;
                }
            }
        }
    }

    .title {
        padding: 10px;
    }

    .report-filters {
        display: block;
        width: 100%;

        .report-child-cells {
            display: table-cell;
            vertical-align: middle;
            line-height: 38px;

            label {
                padding: 0 0 0 10px;
            }
        }
    }

    .cell-label {
        max-width: 80px;
    }

    .panel {
        margin-bottom: 12px;
    }

    table {
        margin-bottom: 0;
    }

    .footer {
        padding: 10px;
        margin-top: 10px;

        span {
            font-size: 11px;
            color: #000;
        }
    }

    select.custom-dropdown option {
        color: #1F8BD5;
    }

    select.custom-dropdown option:checked {
        color: #444b4f;
    }

    .custom-dropdown {
        display: inline-block;
        max-width: 100%;
        /*height: calc(2.25rem + 2px);*/
        padding: .375rem 1.75rem .375rem .75rem;
        line-height: 1.5;
        color: #444b4f;
        font-size: 16px;
        vertical-align: middle;
        background: #fff url(https://assets.autoalert.com/img/autoalert-icons/icon-chevron-down-lake.svg) no-repeat right .75rem center;
        background-size: 15px 20px;
        border: 0;
        border-bottom: 3px solid #1F8BD5;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        padding-right: 30px;

        .right-align {
            padding: .375rem 2.25rem .375rem .75rem;
            text-align: right;
        }
    }

    select.custom-dropdown::-ms-expand {
        display: none;
    }

    .custom-dropdown:focus {
        border-bottom: 3px solid #1F8BD5;
        outline: 0;
    }
}

reports-container {
    font-family: Roboto, sans-serif;

    #grid-toolbar {
        height: auto;
    }

    label.toolbar-label {
        font-size: 13px;
        margin-bottom: .2em;
    }

    .data-grid {
        margin: 15px;
        /*font-family: Arial;*/
        font-size: 12px;
    }

    .light {
        color: #000 !important;
    }
    /*#reportArea {
        .title label {
            color: #000;
            padding-left:15px;
        }

        .footer span {
            color: #000;
        }
    }*/
    ul.reports-menu {
        padding: 0 0 0 0;

        li {
            list-style: none;
            display: table;
            width: 100%;

            div.performance-menu {
                font-size: 13px;
                color: white !important;
                padding: 0 4px 5px 0;
                font-weight: 600;
                text-align: right;
                border-right: 8px solid transparent;

                &:hover {
                    border-right: 8px solid $pond-blue;
                }

                &.active-menu-item {
                    border-right: 8px solid $navyBlue;
                }
            }

            div.performance-menu-inner {

                &:hover {
                    color: #c1c2c4 !important;
                    background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
                    background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
                    background: linear-gradient($navyBlue, $light-blue);
                    border-bottom: none;
                }

                a {
                    text-decoration: none;

                    &:hover {
                        color: #fff !important;
                        border-bottom: none;
                    }
                }
            }

            a {
                color: #fff;
                font-family: Roboto, sans-serif;
                font-size: 14px;
                font-weight: 300;
                display: table-cell;
                width: 200px;
                height: 35px;
                vertical-align: middle;
                padding-left: 17px;

                &.no-category-menu {
                    font-size: 18px;
                    font-weight: bold;
                    color: white !important;
                    padding-left: 7px;
                    font-weight: 500;
                }

                &.performance-menu {
                    font-size: 13px;
                    color: white !important;
                    padding-left: 0;
                    width: auto;
                    display: inline;
                }

                &.active-menu-item {
                    border-bottom: transparent 5px solid;
                }

                &:hover {
                    color: #c1c2c4 !important;
                    background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
                    background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
                    background: linear-gradient($navyBlue, $light-blue);
                    border-bottom: #2d89c9 5px solid;
                }

                span {
                }
            }
        }
    }
}

reports-container {
    .iframe-autoalert {
        /*width: 98% !important;*/
        padding-top: 15px;
        position: relative !important;
        height: calc(100vh - 60px) !important;
    }
}

report-viewer {
    display: block;
}

.report-settings-mask {
    z-index: 5;
    /*background-color: red;
    opacity: 0.5;*/
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.report-settings-button {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 12px;

    &.active {
        span.ng-scope {
            color: white;
            font-size: 0.66em;
            vertical-align: text-top;
        }
        icon {
            color: lawngreen;
        }
    }
}

.report-settings-panel {
    background-color: #fff;
    position: absolute;
    right: 0;
    z-index: 6;
    width: 240px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
}

.report-settings-panel-body {
    padding: 15px;
    line-height: 25px;
    a {
        color: $brightBlue;
    }
}

.fancy-report-link {
    cursor: pointer;
    border-bottom: 4px solid #f0f0f0;
    display: inline;
    &:hover {
        border-bottom: 4px solid #008be8;
    }
}

.icon-stack {
    position: relative;
    /*width: 20px;*/
    height: 20px;
    display: block;
    icon.icon.aa-icon-benchmark.disabled {
        position: absolute !important;
        top: 0;
        right: 0;
        color: #aaa;
    }
    icon.icon.aa-icon-ban {
        position: absolute !important;
        left: 0;
        right: 0;
        display: none;
    }
}

#report-wrapper {
    padding: 1px;
    .table th {
        text-align: center;
    }
}
.date-range-nav {
    padding-top: 15px;
    text-align: right;
}

// This is the button on the date control above the Dashboard report
.btn.calandar {
    top: 0px;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;

    icon.icon {
        color: #fff;
        top: 0;
    }
}


.no-user-avatar {
    background: url("~/images/avatar.png")
}

.opps-icon {
    width: 30px;
    height: 20px;
    background: url("~/images/Opportunities-icon.png") no-repeat;
}

.mytable {
    td {
        text-align: center;
    }
}

.report-version-select {
    margin-top: 10px;
    font-size: 14px;
    padding: 5px;
    text-align: right;
    width: 100%;
    color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background-color: transparent;
    outline: none;
    &:focus {
        border-top: 1px solid white;
        border-bottom: 1px solid white;
    }
    span.view-legacy-description {
        display: block;
        font-size: 11px;
    }
    icon {
        font-size: 14px;
    }
}

.reports-menu-toggle {
    margin: 0 5px;
    cursor: pointer;
    display: inline-block;
    padding-bottom: 7px;
}

.reports-menu-container {
    clear: both;
    .reports-menu {
        background: none;
        margin-right: 10px;
        ul {
            -webkit-padding-start: 0px;
            padding-left: 0px;
        }
        .menu-item {
            border-bottom: 1px solid #005691;
        }
    }
}

.report-subscription-modal .modal-dialog {
    width: 768px;
    @media only screen and (min-width : $md-breakpoint) {
        width: 850px;
    }
}

.alert-danger p {
    margin: 0;
}

reports-header dealer-make-select label {
    color: #444b4f !important;
    font-size: .66em;
    margin-bottom: 0;
}

reports-header pando-survey-select label {
    color: #444b4f !important;
    font-size: .66em;
    margin-bottom: 0;
}

label {
    font-weight: normal;
}

report-subscription-list {
    .btn.transparent:focus {
        outline: none;
    }
    .table tbody tr td {
        vertical-align: middle;
        padding: 6px;
    }
    .aa-icon-toggle-on {
        color: $service-color;
    }
    .aa-icon-toggle-off {
        color: $labelColor;
    }
    .aa-icon-core-edit-icon-000000 {
        color: $brightBlue;
        font-size: 24px;
    }
    .aa-icon-icon-trash {
        color: $brightBlue;
        font-size: 16px;
    }
    .dealer-name-cell {
        width: 255px;
    }
}

.delivery-schedule {
    color: $brightBlue;
}

#subscription-monthly-schedule-date-ranges {
    color: $brightBlue;
    font-size: 12px;
    height: 26px;
    padding-top: 3px;
    padding-bottom: 3px;
}

#subscription-weekly-schedule-date-ranges {
    color: $brightBlue;
    font-size: 12px;
    height: 26px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.alert-warning {
    color: #7a8084;
}

.menu-category {
    cursor: pointer;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    line-height: 45px;
    padding-left: 7px;

    h5 {
        display: inline-block;
    }

    icon {
        margin-top: 13px;
        margin-bottom: 13px;
        font-size: 15px;
    }
}

.beta-sup {
    color: #03C0E6;
    font-size: 65%;
}

reports-menu {
    display: block;
}

.pilot-label {
    color:#fff;
    font-size:12px;
}

.delivery-schedule-row-padding {
    padding-bottom:3px;
}


.k-button,
.k-button:hover,
.k-button.k-state-hover,
.k-button.k-state-focused,
.k-button:focus,
.k-button:focus:not(.k-state-disabled):not([disabled]),
.k-button:focus:active:not(.k-state-disabled):not([disabled]) {
    box-shadow: none;
}

.k-chart-shared-tooltip {
    margin-left: -45px;
}


