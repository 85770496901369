@import "../../styles/aa-variables";

.search-export-epsilon-modal .modal-dialog {
    width: 1000px;
    .input-row {
        height: 25px;
    }
    input {
        width: 200px;
        vertical-align: top;
        font-size: small;
    }
}

.search-export-epsilon-modal .row .tablescroll {
    max-height: 300px;
    overflow-y: auto;
}

.search-export-epsilon-modal .row .toppad {
    padding-top: 20px;
}