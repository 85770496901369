@import "../../../styles/aa-variables";

hot-trade-details-modal {
    .table {
        margin-bottom: 5px;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .red {
        color: red;
    }

    .header-section {
        border-bottom: solid 1px $SectionBorder;
    }

    .footer-section {
        border-top: solid 1px $SectionBorder;
    }

    .row .padded {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .star-note {
        font-size: small;
    }
}