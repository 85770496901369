﻿
edit-upgrade-downgrade-modal {
    trim-select {
        max-width: 800px;
    }

    .form-control {
        display: inline;
        width: auto;
    }

    .toleranceRow {
        margin-left:20px;
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .toleranceField {
        display: inline-block;
        margin-left: 20px;
    }
    /*input[type=number] {
        -moz-appearance: initial !important;
    }

    input[type=number]:not(.show-spinner)::-webkit-inner-spin-button,
    input[type=number]:not(.show-spinner)::-webkit-outer-spin-button {
        -webkit-appearance: initial !important;
        margin: auto;
    }*/
}
