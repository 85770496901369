﻿
x-data-entry-replacement-offers {

    .replacements-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
    }

    .divider {
        border-top: 1px solid #1F8BD5;
        margin-bottom: 10px;
        margin-top: 10px !important;
    }

    .tab-padding {
        margin: 20px 0 0 0;
    }

    .replacements-padding {
        padding: 0 30px 0px 30px;
        min-height: 900px;
    }

    .header-background {
        background-color: #172751;
    }

    .btn-label {
        color: white;
        text-align: right;
        padding: 10px;
    }

    .small-margin {
        margin: 3px;
    }

    .k-grid table {
        width: 100% !important;
    }

    .svg-icon {
        &.xsm {
            width: 16px;
            height: 16px;
            padding: 1px;
            margin-bottom: 1px;
            margin-top: 1px;
        }
    }

    .k-grid .k-grid-header .k-header .k-link {
        height: auto;
    }

    .k-grid .k-grid-header .k-header {
        white-space: normal;
    }

    #gridDataEntryReplacementSpecifics th.k-header.align-center {
        text-align: center;
    }

    #gridDataEntryReplacementSpecifics th.k-grid-content.align-center {
        text-align: center;
    }
}

.replacement-popup {
    margin: 0px;

    .replacement-popup-list {
        overflow-y: auto;
        height: 180px;
    }
}
