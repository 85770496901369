﻿@import "../../styles/aa-variables";

search-container {
    .search-menu {
        padding: 10px 0 0 0;
        background: linear-gradient(to top, $navyBlue, $brightBlue);
        height: 100%;
        
        h3 {
            color: #fff;
            font-size: 18px;
            margin-top: 0;
            padding-left: 10px;
        }

        &.fixed {
            min-width: 260px;
        }
    }

    .search-form {
        background: $reportsBackground;
    }

    ul li a.active-menu-item {
        color: $activeMenuItems !important;
        background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
        background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
        background: linear-gradient($navyBlue, $light-blue);
        border-bottom: #2d89c9 5px solid;
    }

    .active-menu-row {
        color: $activeMenuItems !important;
        background: -webkit-linear-gradient(to bottom, $navyBlue 0%, $light-blue 100%);
        background: linear-gradient(to bottom, $navyBlue 0%, $navyBlue 100%);
        background: linear-gradient($navyBlue, $light-blue);
        border-bottom: transparent 5px solid;
    }

    .search-menu-toggle {
        margin: 0 5px;
        cursor: pointer;
        display: inline-block;
        padding-top: 7px;
        color: white;
    }

    .search-menu-icon {
        vertical-align: top;
        background: linear-gradient(to top, $navyBlue, $brightBlue);
    }

    .search-container {
        width: 100%;

        h5, h6, label {
            color: white;
        }

        .icon {
            color: white;
            position: absolute;
        }

        > .row {
            height: 100%;
            display: table-row;
        }

        > .row .no-float {
            display: table-cell;
            float: none;
        }

        .icon {
            position: relative !important;
        }
    }
}