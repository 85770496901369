﻿
.edit-wish-list-modal {
    /*    trim-select {
        max-width: 800px;
    }*/

    .form-control {
        font-size: 14px;
        /*display: inline;
        width: auto;*/
    }

    .checkbox {
        padding: 0;
        min-height: auto;

        label {
            min-height: auto;
        }
    }

    .dropdown-menu {
        font-size: 14px;
    }
    /*.toleranceRow {
        margin-left: 20px;
        margin-top: -5px;
        margin-bottom: 10px;
    }

    .toleranceField {
        display: inline-block;
        margin-left: 20px;
    }*/

    span {
        font-size: 14px;
    }

    .error {
        color: red;
        padding-left: 6px;
    }

    .formRow {
        padding-bottom: 10px;
    }

    .search-multiselect {
        cursor: pointer;
        display: inline-block;

        .btn {
            border: solid 1px #ccc;
            background-color: #fff;
            min-width: 400px;
            max-width: 400px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-align: left;
            height: 28px;

            .caret {
                position: absolute;
                top: 15px;
                right: 5px;
            }
        }

        .dropdown-toggle {
            font-size: 14px;
        }

        .option {
            height: 24px;

            .checkboxInput {
                margin-top: 2px;
                vertical-align: middle;
                color: #000;
            }

            div {
                margin: 0px;
            }
        }
    }

    .disablePointerEvent {
        pointer-events: none;
    }

    .disableCursor {
        cursor: not-allowed;
    }
}
